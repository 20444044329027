import { useState, useRef, useEffect } from "react";
import syngentaLogo from "../assests/Styles/assets/logo.png";
import mobileLogo from "../assests/Styles/assets/mobileLogo.png";
import logoutIcon from "../assests/Styles/assets/logoutIcon.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Bookmark, CircleFill, BookmarkFill } from "react-bootstrap-icons";
import MapYearandSearch from "./MapYearandSearch";
import "../assests/Styles/mobileTopBar.css";
import "../assests/Styles/mapviewer.css";
import propTypes from "prop-types";
import LandingPageToggleSwitch from "./LandingPageToggleSwitch";
import { customTabletDimensions } from "../services/Common";
import { isDesktop } from "react-device-detect";
import vectorNewIcon from "../assests/Styles/assets/vectorNewIcon.svg";
import shapeNewIcon from "../assests/Styles/assets/ShapeNewIcon.svg";
import FeedbackForm from "./FeedbackForm";
import { X } from "react-bootstrap-icons";

export default function MapDesktopBar({
  setYearLoader,
  setSelectedResult,
  setTrialsList,
  setShowWelcomeText,
  setRefinedSearch,
  setMyTrials,
  myTrials,
  setYearSelection,
  isOn,
  setIsOn,
  setTopBookmarkTrail,
  topBookmarkTrail,
  selectedOption,
  setSelectedOption,
  yearList,
  searchText,
  setSearchText,
  showAutoSuggestionPopup,
  trialsByYear,
  setShowAutoSuggestionPopup,
  popMarker,
  myTrialToggleButton,
  setRefinedSearchTrialslist,
  setSelectedItems,
  setRefinedSearchText,
  refinedAccordions,
  setIsFilterByDroneAssessment,
  setIsFilterByOrthoMosaic,
  accounts,
  instance,
  state,
  submitForm,
  isMobile,
  isMobileLandscape,
  isTablet,
}) {
  const name = sessionStorage.getItem("userName");
  const pro = name?.split(".");
  const [profileClick, setProfileClick] = useState(false);
  const dropdownRef = useRef(null);
  const profileIconRef = useRef(null);
  let navigate = useNavigate();
  const [isShowFeedback, setIsShowFeedback] = useState(false);

  const handleOpenFeedback = () => {
    setIsShowFeedback(true);
  };

  const handleCloseFeedback = () => {
    setIsShowFeedback(false);
  };
  const handleprofileIconClose = () => setProfileClick(false);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !profileIconRef?.current?.contains(event.target)
      ) {
        setProfileClick(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setShowWelcomeText(setShowWelcomeText);
  }, [setShowWelcomeText]);

  const routeChange = () => {
    let path = "/home/logout";
    navigate(path);
  };

  const routeDroneFlight = () => {
    let path = "/droneflight";
    navigate(path);
  };

  const handleButtonClick = () => {
    setProfileClick(!profileClick);
  };

  const myTrialChange = () => {
    setIsOn(!isOn);
    setMyTrials(!myTrials);
  };

  const onBookmarkClick = () => {
    setTopBookmarkTrail(!topBookmarkTrail);
    setSelectedResult("");
  };

  return (
    <div className="row" style={{ zIndex: 9999 }}>
      <div
        className="col-1 col-md-1 col-sm-2"
        style={{
          borderRight: "1px solid white",
          padding: customTabletDimensions() ? "10px" : "5px 0px 0px 10px"
        }}>
        <a className="logo" href="/app" id="viewer-link">
          <div className="align-items-center" style={{ textAlign: "center" }}>
            <img
              src={customTabletDimensions() ? mobileLogo : syngentaLogo}
              href="/app"
              alt="syngenta"
              style={{
                marginLeft: customTabletDimensions() ? "10px" : "0px",
                cursor: "pointer"
              }}
            />
          </div>
        </a>
      </div>

      <div className="col-3">
        {myTrialToggleButton && (
          <div style={{ padding: "10px", marginTop: "5px" }}>
            <span
              style={{
                marginTop: "0px",
                color: "white",
                paddingRight: "5px",
                fontSize: "14px",
                fontWeight: "400"
              }}>
              My trials
            </span>
            <Button
              className={`${customTabletDimensions() ? "toggle-switch-new-landing-tablet" : "toggle-switch-new-landing"
                }`}
              variant={isOn ? "primary" : "secondary"}
              onClick={myTrialChange}>
              {isOn ? (
                <>
                  <span
                    style={{
                      paddingRight: customTabletDimensions() ? "5px" : "10px",
                      fontWeight: "600",
                      fontSize: "12px"
                    }}>
                    ON
                  </span>
                  <span className="circle">
                    <CircleFill width={18} height={18} />
                  </span>
                </>
              ) : (
                <>
                  <span className="circle">
                    <CircleFill width={18} height={18} />
                  </span>
                  <span
                    className="circle"
                    style={{
                      paddingLeft: customTabletDimensions() ? "5px" : "10px",
                      fontWeight: "600",
                      fontSize: "12px"
                    }}>
                    OFF
                  </span>
                </>
              )}
            </Button>
          </div>
        )}
      </div>

      <div
        className={customTabletDimensions() ? "col-7" : "col-4"}
        style={{
          padding: customTabletDimensions() ? "0 10px" : 0,
          marginLeft: "-10px",
          right: myTrialToggleButton && customTabletDimensions() ? "5%" : customTabletDimensions() ? "10%" : null
        }}>
        <div>
          <MapYearandSearch
            setYearLoader={setYearLoader}
            setSelectedResult={setSelectedResult}
            setTrialsList={setTrialsList}
            setShowWelcomeText={setShowWelcomeText}
            setYearSelection={setYearSelection}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            yearList={yearList}
            searchText={searchText}
            setSearchText={setSearchText}
            showAutoSuggestionPopup={showAutoSuggestionPopup}
            trialsByYear={trialsByYear}
            setShowAutoSuggestionPopup={setShowAutoSuggestionPopup}
            setTopBookmarkTrail={setTopBookmarkTrail}
            setRefinedSearch={setRefinedSearch}
            popMarker={popMarker}
            setRefinedSearchTrialslist={setRefinedSearchTrialslist}
            setIsOn={setIsOn}
            setSelectedItems={setSelectedItems}
            setRefinedSearchText={setRefinedSearchText}
            refinedAccordions={refinedAccordions}
            setIsFilterByDroneAssessment={setIsFilterByDroneAssessment}
            setIsFilterByOrthoMosaic={setIsFilterByOrthoMosaic}
            setMyTrials={setMyTrials}
            isMobile={isMobile}
            isMobileLandscape={isMobileLandscape}
            isTablet={isTablet}
          />
        </div>
      </div>
      <div
        className={
          customTabletDimensions()
            ? "col-1 justify-content-end d-flex align-items-center"
            : "col-4 justify-content-end d-flex align-items-center"
        }
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginLeft: "12px"
        }}>
        {!customTabletDimensions() && isDesktop && (
          <a href="/app/droneflight" >
            <button
              onClick={routeDroneFlight}
              style={{
                width: "136px",
                height: "30px",
                top: "16px",
                borderRadius: "24px",
                border: "1px solid white",
                backgroundColor: "transparent",
                color: "white",
                marginRight: "18px",
                paddingBottom: "3px"
              }}>
              <span style={{ fontSize: "12px", fontWeight: "700", zIndex: "10000" }}>Drone Flight Sites</span>
            </button>
          </a>
        )}
        {!(customTabletDimensions()) && <LandingPageToggleSwitch isNewLandingPage />}
        <div
          style={{
            padding: customTabletDimensions() ? "5px" : "10px 5px 10px 7px",
            backgroundColor: "transparent",
            border: "none",
            marginRight: "10px"
          }}
          tabIndex="0"
          role="button"
          onClick={onBookmarkClick}
          onKeyDown="">
          {topBookmarkTrail ? (
            <BookmarkFill height={24} width={24} color="white" />
          ) : (
            <Bookmark height={24} width={24} color="white" />
          )}
        </div>
        <div style={{ padding: customTabletDimensions() ? "5px 10px 5px 5px" : "0px 10px 0px 5px" }}>
          <button
            className="ml-auto map-profile-icon"
            ref={profileIconRef}
            onClick={handleButtonClick}>
            {pro && pro.length > 1
              ? pro[0].charAt(0).toUpperCase() + pro[1].charAt(0).toUpperCase()
              : pro[0].charAt(0).toUpperCase()}
          </button>
          {profileClick && (
            <div
              ref={dropdownRef}
              style={{
                position: "absolute",
                right: "4%",
                top: "60px",
                backgroundColor: "rgb(13, 26, 64)",
                zIndex: "9999",
                minWidth: (customTabletDimensions())? "388px" : "300px"
              }}>
              {(customTabletDimensions()) &&(
                  <div style={{ position: "absolute", zIndex: 9, right: "24px", marginTop: "4px" }}>
                    <X color="white" height={24} width={24} onClick={handleprofileIconClose} />
                  </div>
              )}
              <ul className="list-group">
                <li className="list-group-item" style={{ height:(customTabletDimensions())?"85px":""}}>
                  <div style={{ display: "flex", gap: "10px", marginTop:(customTabletDimensions())?"31px":"0px",alignItems:"center" }}>
                    <span>
                      <img
                        src={shapeNewIcon}
                        alt="shapeNewIcon"
                        height={24}
                        width={24}
                        // style={{ width: "20px", height:"20px", margin:"2px", }}
                      />
                    </span>
                    <span> {name}</span>
                  </div>
                </li>
                <li className="list-group-item">
                  <button
                    className="MapD-feedback"
                    onClick={handleOpenFeedback}
                  >
                    <div className="map-desktopPro-vector">
                      <span>
                        <img
                          src={vectorNewIcon}
                          alt="vectorNewIcon"
                          style={{ width: "20px", height: "20px",margin:"2px" }}
                        />
                      </span>
                      <span style={{fontWeight:400,fontSize:"14px"}}> Feedback</span>
                    </div>
                  </button>
                </li>
                {(customTabletDimensions()) && (
                  <li className="list-group-item">
                    <LandingPageToggleSwitch isNewLandingPage />
                  </li>
                )}
                <li
                  className="list-group-item"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#0D1A40"
                  }}>
                  <button className="logout-Mobile" onClick={routeChange}>
                    <span>
                      <img
                        className="logout-icon"
                        alt=""
                        src={logoutIcon}
                        height={16}
                        width={16}
                        style={{ marginBottom: "5px" }}
                      />{" "}
                      LOGOUT
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div>
        <FeedbackForm
          handleFeedbackClose={handleCloseFeedback}
          isShowFeedback={isShowFeedback}
          handleCloseFeedback={handleCloseFeedback}
          isDesktop={true}
          accounts={accounts}
          instance={instance}
          state={state}
          submitForm={submitForm}
        />
      </div>
    </div>
  );
}
MapDesktopBar.propTypes = {
  setYearLoader: propTypes.any,
  setSelectedResult: propTypes.any,
  setTrialsList: propTypes.any,
  setShowWelcomeText: propTypes.any,
  setMyTrials: propTypes.bool,
  myTrials: propTypes.bool,
  setYearSelection: propTypes.any,
  isOn: propTypes.bool,
  setIsOn: propTypes.bool,
  setTopBookmarkTrail: propTypes.any,
  topBookmarkTrail: propTypes.any,
  selectedOption: propTypes.any,
  setSelectedOption: propTypes.any,
  yearList: propTypes.any,
  searchText: propTypes.any,
  setSearchText: propTypes.any,
  showAutoSuggestionPopup: propTypes.any,
  trialsByYear: propTypes.any,
  setShowAutoSuggestionPopup: propTypes.any,
  popMarker: propTypes.func,
  myTrialToggleButton: propTypes.any,
  setRefinedSearch: propTypes.any,
  setRefinedSearchTrialslist: propTypes.any,
  setSelectedItems: propTypes.any,
  setRefinedSearchText: propTypes.any,
  refinedAccordions: propTypes.any,
  setIsFilterByDroneAssessment: propTypes.any,
  setIsFilterByOrthoMosaic: propTypes.any,
  accounts : propTypes.any,
  instance : propTypes.any,
  state : propTypes.any,
  submitForm : propTypes.any,
  isMobile:propTypes.any,
  isMobileLandscape:propTypes.any,
  isTablet:propTypes.any,
  
};
