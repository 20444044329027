/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from "react";
import {
  loginSyt,
  viewAPI,
  trialAutoComplete,
  getPlotInfo,
  getAllFilterData,
  edcGetAccessToken,
  saveLayoutAPI,
  getLayoutDetailsAPI,
  getLayoutsList,
  callMsGraph,
  getRasterLayersAPICall,
  deleteLayoutAPI,
  saveSiteData
} from "../services/API";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import {
  Button,
  Dropdown,
  Form,
  FormControl,
  Container,
  Modal,
  Row,
  Col,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";
import {
  ChatLeftText,
  CheckCircleFill,
  ChevronDown,
  ExclamationTriangleFill,
  InfoCircleFill,
  Paperclip,
  Search,
  X,
  TrashFill,
  XCircleFill,
  Trash
} from "react-bootstrap-icons";
import "../assests/Styles/newviewer.css";
import "../assests/Styles/editviewer.css";
import "../assests/Styles/color.css";
import "../assests/Styles/common.css";
import * as L from "leaflet";
import "leaflet-draw-drag";
import "leaflet-draw/dist/leaflet.draw-src.css";
import "leaflet-path-transform";
import "leaflet-geometryutil";
import "leaflet-path-transform/dist/L.Path.Transform.js";
import "leaflet-path-transform/dist/L.Path.Transform-src.js";
import "leaflet-rotatedmarker";
import { useMediaQuery } from "react-responsive";
import DesktopTopBar from "./DesktopTopBar";
import MobileTopBar from "./MobileTopBar";
import uploadIcon from "../assests/Styles/assets/uploadIcon.svg";
import downloadIcon from "../assests/Styles/assets/downloadIcon.svg";
import startNewIcon from "../assests/Styles/assets/startNewIcon.svg";
import banNewIcon from "../assests/Styles/assets/banNewIcon.svg";
import undoIcon from "../assests/Styles/assets/undo.svg";
import redoIcon from "../assests/Styles/assets/redo.svg";
import * as XLSX from "xlsx";
import { useMsal } from "@azure/msal-react";
import measurementIcon from "../assests/Styles/assets/measurementIcon.svg";
import "../assests/Styles/leaflet-measure.css";
import "../assests/Scripts/leaflet-measure";
import * as turf from "@turf/turf";
import { dateConverter, Spinner, formatFlightDateForLayouts } from "../services/Common";
import DesktopFlightDropdown from "./DesktopFlightDropdown";
import { useNavigate } from "react-router";
import infoNewIcon from "../assests/Styles/assets/infoNewIcon.svg";
import crossIcon from "../assests/Styles/assets/crossIcon.svg"; 

L.drawLocal.edit.handlers.edit.tooltip.subtext = "";
L.drawLocal.edit.handlers.edit.tooltip.text = "";

let mapLayout = null;
let drawnItemsConst = null;
let parentLayerId = null;
let layerConst = [];
let plotNoConst = 0;
let plotDetailsConst = [];
let checkBoxes = [];
let selectedPlotsConst = [];
let popupLayersConst = [];
let trmtBearingConst = 0;
let repBearingConst = 0;
let trmtDistanceAddConst = 0;
let noOfTreatmentsConst = 0;
let noOfRepsConst = 0;
let repDistanceAddConst = 0;
let newArray = [];
let repDistanceConst = 0;
let trtmtDistanceConst = 0;
let transformListConst = [];
let layerRotationConst = 0;
let isSelectPlotConst = false;
let selectedBoxesConst = [];
let manualDistanceApplied = false;
let xDistanceConst = 0;
let yDistanceConst = 0;
let manualPlotWidthConst = 0;
let manualPlotLengthConst = 0;
let repeatBearingTop = 0;
let repeatBearingBottom = 0;
let repeatBearingRight = 0;
let repeatBearingLeft = 0;
let mapEditing = "false";
let oldZoom = null;
let plotDetailsFromAPIConst = [];
const drawnPlots = [];
let drawnPlotsTemp = [];
let drawnPlotsCopy = [];
let drawnPlotsEdit = [];
let editCurrentIndexConst = -1;

const measureTool = L.control.measure({
  position: "bottomright",
  primaryLengthUnit: "meters",
  secondaryLengthUnit: "feet",
  primaryAreaUnit: "sqmeters",
  secondaryAreaUnit: "acres",
  captureZIndex: 1111,
  popupOptions: {
    // standard leaflet popup options http://leafletjs.com/reference-1.3.0.html#popup-option
    className: "leaflet-measure-resultpopup",
    autoPanPadding: [10, 10]
  }
});
let collectionDataConst = {};
let trialSearchText = "";
let modifiedPlotWidth = 0;
let modifiedPlotHeight = 0;
let basePlotWidth = 0;
let basePlotHeight = 0; 
let rotationValues = [];
let rotationItems = []; 

export default function EditViewer() {
  const [dataLoader, setDataLoader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteTrialDetails, setDeleteTrialDetails] = useState(false);
  const [suggestionLoader, setSuggestionLoader] = useState(false);
  const [profileClick, setProfileClick] = useState(false);
  const [viewAPIData, setViewAPIData] = useState();
  const [loading, setLoading] = useState(true);
  const [mapInitiated, setMapInitiated] = useState(false);
  const [trialListData, setTrialListData] = useState();
  const [collectionData, setCollectionData] = useState({});
  const [rasterLayers, setRasterLayers] = useState([]);
  const [layerState, setLayerState] = useState([]);
  const [layerRotation, setLayerRotation] = useState(0);
  const [trmtBearing, setTrmtBearing] = useState(0);
  const [repBearing, setRepBearing] = useState(0);
  const [trmtDistanceAdd, setTrmtDistanceAdd] = useState(0);
  const [repDistanceAdd, setRepDistanceAdd] = useState(0);
  const [notes, setNotes] = useState("");
  const [mapState, setMapState] = useState(null);
  const [selectedFlightDate, setSelectedFlightDate] = useState();
  const [transformList, setTransformList] = useState([]);
  const [isLayoutSavedAs, setIsLayoutSavedAs] = useState(false);
  const [inputValues, setInputValues] = useState({
    plotWidth: null,
    plotLength: null,
    noOfTreatments: null,
    noOfReps: null,
    trmtDistance: null,
    repDistance: null,
    plotDirection: null
  });
  const [plotDetailsFromAPI, setPlotDetailsFromAPI] = useState();
  const [plotDetails, setPlotDetails] = useState([]);
  const [year, setYear] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [addTrial, setAddTrial] = useState("");
  const [selectedTrial, setSelectedTrial] = useState("");
  const [trial, setTrial] = useState("");
  const [showNoTrial, setShowNoTrial] = useState(false);
  const [newTrial, setNewTrial] = useState("");
  const [filteredTrials, setFilteredTrials] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [fileName, setFileName] = useState("");
  const [showSupplyPlotDetails, setShowSupplyPlotDetails] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [popupLayers, setPopupLayers] = useState([]);
  const [selectedPlots, setSelectedPlots] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSelectPlot, setIsSelectPlot] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [submitType, setSubmitType] = useState("save");
  const [savedLayoutName, setSavedLayoutName] = useState("");
  const [saveError, setSaveError] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [layoutType, setLayoutType] = useState("new");
  const [selectedLayoutId, setSelectedLayoutId] = useState();
  const [layoutList, setLayoutList] = useState([]);
  const [selectedLayoutDetails, setSelectedLayoutDetails] = useState({});
  const [selectedBoxes, setSelectedBoxes] = useState(["Plot ID", "Plot #", "Treatment #"]);
  const latestInputValues = useRef(inputValues);
  const [draftNotes, setDraftNotes] = useState(null);
  const [submittedNotes, setSubmittedNotes] = useState(null);
  const [layoutDetailsOpen, setLayoutDetailsOpen] = useState(false);
  const [enableSelectedPlots, setEnableSelectedPlots] = useState(false);
  const [measurementToolVisible, setMeasurementToolVisible] = useState(false);
  const [xDistance, setXDistance] = useState();
  const [yDistance, setYDistance] = useState();
  const [manualPlotWidth, setManualPlotWidth] = useState();
  const [manualPlotLength, setManualPlotLength] = useState();
  const [disableButtons, setDisableButtons] = useState(false);
  const [saveDisable, setSaveDisable] = useState(true);
  const [submitDisable, setSubmitDisable] = useState(true);
  const [yearOpen, setYearOpen] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [allData, setAllData] = useState();
  const [flightContainerVisible, setFlightContainerVisible] = useState(false);
  const [flightDates, setFlightDates] = useState();
  const [distanceByManual, setDistanceByManual] = useState(0);
  const [angleValue, setAngleValue] = useState(0);

  const idToken = sessionStorage.getItem("idToken");
  const { instance, accounts } = useMsal();
  const autoCompleteRef = useRef(null);
  const inputRef = useRef(null);
  const selectPlotsRef = useRef(null);
  const plotsRef = useRef(null);
  const params = new URLSearchParams(window.location.search);
  const trail = params.get("trial") !== null && params.get("trial");
  const collectionId = params.get("collection_id");
  const dropdownRef = useRef(null);
  const draftItems = layoutList?.filter((item) => item?.status === "Draft");
  const submittedItems = layoutList?.filter((item) => item.status === "Submitted");
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const isTablet = useMediaQuery({ minWidth: 577, maxWidth: 869 });
  const isMobileLandscape = useMediaQuery({ maxHeight: 430 });
  const isSmallDevice = useMediaQuery({ maxHeight: 430 });
  const selectedPlotsRef = useRef(selectedPlots);
  const latestPlotDetails = useRef([]);
  let filteredTrialsArray = new Set();
  const years = ["All Years", (Number(year) - 1).toString(), year, (Number(year) + 1).toString()];
  const flightDateRef = useRef(null);
  const flightDatesContainerRef = useRef(null);
  const [draftedItemsList, setDraftedItemsList] = useState(draftItems);
  const [sumbittedItemsList, setSumbittedItemsList] = useState(submittedItems);
  // eslint-disable-next-line no-unused-vars
  const [filtereddraftItemsList, setFiltereddraftItemsList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [filteredsubmittedItemsList, setFilteredsubmittedItemsList] = useState([]);
  const [selectedLayoutBtn, setSelectedLayoutBtn] = useState("drafted");
  const [editSavetooltip, setEditSavetooltip] = useState(false);
  const [selectedLayoutNameForCount, setSelectedLayoutNameForCount] = useState("");
  const [enableItems, setEnableItems] = useState(false);
  const enableselecteddraftitems =
    (selectedLayoutBtn === "new" && !selectedTrial && !addTrial) ||
    (selectedLayoutBtn === "drafted" && !enableItems);
  let navigate = useNavigate();
  const [draftSearchResult, setDraftSearchResult] = useState("");
  const [cloneSearchResult, setCloneSearchResult] = useState("");
  const [editCurrentIndex, setEditCurrentIndex] = useState(0);
  const [disableRedo, setDisableRedo] = useState(false);
  const [disableUndo, setDisableUndo] = useState(false);

  useEffect(() => {
    collectionDataConst = collectionData;
  }, [collectionData]);

  useEffect(() => {
    repDistanceConst = inputValues.repDistance;
    trtmtDistanceConst = inputValues.trmtDistance;
    latestInputValues.current = inputValues;
  }, [inputValues, trtmtDistanceConst, repDistanceConst]);

  useEffect(() => {
    xDistanceConst = xDistance;
    yDistanceConst = yDistance;
  }, [xDistance, yDistance]);

  useEffect(() => {
    manualPlotWidthConst = manualPlotWidth;
    manualPlotLengthConst = manualPlotLength;
  }, [manualPlotWidth, manualPlotLength]);

  useEffect(() => {
    latestPlotDetails.current = plotDetails;
  }, [plotDetails]);

  useEffect(() => {
    transformListConst = transformList;
  }, [transformList]); 

  useEffect(() => {
    layerRotationConst = layerRotation;
  }, [layerRotation]);

  useEffect(() => {
    selectedPlotsConst = selectedPlots;
    selectedPlotsRef.current = selectedPlots;
    getRotationValues();
  }, [selectedPlots]);

  useEffect(() => {
    popupLayersConst = popupLayers;
  }, [popupLayers]);

  useEffect(() => {
    plotDetailsConst = plotDetails;
    tooltipGenerated();    
    getRotationValues();
  }, [plotDetails]);

  useEffect(() => {
    trmtBearingConst = trmtBearing;
  }, [trmtBearing]);

  useEffect(() => {
    repBearingConst = repBearing;
  }, [repBearing]);

  useEffect(() => {
    trmtDistanceAddConst = trmtDistanceAdd;
  }, [trmtDistanceAdd]);

  useEffect(() => {
    noOfTreatmentsConst = inputValues.noOfTreatments;
  }, [inputValues.noOfTreatments]);

  useEffect(() => {
    noOfRepsConst = inputValues.noOfReps;
  }, [inputValues.noOfReps]);

  useEffect(() => {
    repDistanceAddConst = repDistanceAdd;
  }, [repDistanceAdd]);
 
  // api call for token if first name is not available in session
  useEffect(() => {
    const firstName = sessionStorage.getItem("firstName");
    if (
      firstName === undefined ||
      firstName === null ||
      firstName === "undefined" ||
      firstName === "null"
    ) {
      const request = {
        scopes: ["User.Read"],
        account: accounts[0]
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance
        .acquireTokenSilent(request)
        .then(async (response) => {
          sessionStorage.setItem("idToken", response.idToken);
          sessionStorage.setItem("accessToken", response.accessToken);
          callMsGraph(response.accessToken).then((response1) => {
            sessionStorage.setItem("firstName", response1?.givenName);
            sessionStorage.setItem("lastName", response1?.surname);
          });
        })
        .catch(() => {
          instance.acquireTokenRedirect(request).then((response) => {
            sessionStorage.setItem("idToken", response.idToken);
          });
        });
      sessionStorage.setItem("userName", accounts?.[0]?.username);
    }
  }, []);

  // close profile popup on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setProfileClick(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    selectedBoxesConst = selectedBoxes;
    tooltipGenerated();
  }, [selectedBoxes]);

  useEffect(() => {
    isSelectPlotConst = isSelectPlot;
  }, [isSelectPlot]);

  useEffect(() => {
    setLayoutType("new");
  }, [selectedTrial]);
 
  // close select plots popup on outside click
  useEffect(() => {
    const handleClickPlotOutside = (event) => {
      if (
        selectPlotsRef.current &&
        !selectPlotsRef.current.contains(event.target) &&
        !plotsRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    window.addEventListener("mousedown", handleClickPlotOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickPlotOutside);
    };
  }, []);

  useEffect(() => {
    setMapState(new Map(null));
    mapLayout = new Map(null);
  }, []);

  // call collection api
  useEffect(() => {
    loginSyt(
      idToken,
      (res) => {
        if (res === 200) {
          viewAPI(collectionId, (data) => {
            const displayName = data?.name?.split(" ");
            displayName?.pop();
            setViewAPIData(data);
            setCollectionData(data);
            setLoading(false);
            setYear(data?.date?.date?.split("-")[0]);
            setFlightDates(data?.collection_ids);
            setSelectedYear(data?.date?.date?.split("-")[0]);
            if (data?.block_id !== null) {
              getLayoutsList(data?.block_id, null, (data) => {
                setLayoutList(data?.layouts_list);

                setFiltereddraftItemsList(
                  data?.layouts_list?.filter(
                    (item) => item?.status === "Draft" && item?.is_deleted === false
                  )
                );
                setDraftedItemsList(
                  data?.layouts_list?.filter(
                    (item) => item?.status === "Draft" && item?.is_deleted === false
                  )
                );
                setSumbittedItemsList(
                  data?.layouts_list?.filter(
                    (item) => item.status === "Submitted" && item?.is_deleted === false
                  )
                );
                setFilteredsubmittedItemsList(
                  data?.layouts_list?.filter(
                    (item) => item.status === "Submitted" && item?.is_deleted === false
                  )
                );
                if (
                  data?.layouts_list?.filter((item) => item?.is_deleted === false)?.length === 0
                ) {
                  setSelectedLayoutBtn("new");
                  setSaveDisable(true);
                  setSubmitDisable(true);
                }
              });
            }
            const datesLength = Object.keys(data?.collection_ids)?.length;
            const savedSiteData = {
              site_id: data?.site_id,
              site_year:
                Object.keys(data?.collection_ids).length > 0
                  ? Object.keys(data?.collection_ids)?.[datesLength - 1]?.split("-")?.[0]
                  : data?.date?.date?.split("-")[0],
              user_email: sessionStorage.getItem("userName")
            };
            if (params?.size == 1) {
              saveSiteData(savedSiteData, (err) => {
                console.log("saveRecentSiteAPI err", err);
              });
            }
          });
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, [collectionId, profileClick, idToken]);

  // call api to get trial by year
  useEffect(() => {
    loginSyt(
      idToken,
      (res) => {
        if (res === 200) {
          if (selectedYear) {
            trialAutoComplete(
              selectedYear,
              (data) => {
                setTrialListData(data);
              },
              (err) => {
                console.log("err", err);
              }
            );
          }
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, [selectedYear]);

  // call initial functions after we get collection api result
  useEffect(() => {
    if (Object.keys(collectionData).length > 0 && !mapInitiated) {
      generateRasterLayers();
      initMap();
      bindCallbacks();
      setMapInitiated(true);
      const date = collectionData?.name.split(" ");
      setSelectedFlightDate(date[date.length - 1]);
    }
    drawRasterLayer("Visible");
  }, [collectionData, rasterLayers]);

  // close search popup on outside click
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideAutoSelect);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideAutoSelect);
    };
  }, []);

  // handle measurement icon and popup position
  useEffect(() => {
    const temp = document.querySelector(".measurement-icon");
    const measIcon = document.querySelector(".leaflet-control-measure.leaflet-bar.leaflet-control");
    const position = temp?.getBoundingClientRect();
    if (temp && measIcon) {
      measIcon.style.right = "68px";
      measIcon.style.top = `${position.top}px`;
      measIcon.style.bottom = "unset";
      measIcon.style.width = "fit-content";
    }
  });

  useEffect(() => {
    editCurrentIndexConst = editCurrentIndex;
  }, [editCurrentIndex])
 
  // toggle measurement functionality on click
  const switchMeasurementTool = (isTrue) => {
    setMeasurementToolVisible(isTrue);
    if (isTrue) {
      measureTool.addTo(mapLayout);
    } else {
      measureTool.remove();
    }
  };

  const handleCheckBoxes = (checkboxId) => {
    if (selectedBoxes.includes(checkboxId)) {
      setSelectedBoxes(selectedBoxes.filter((id) => id !== checkboxId));
    } else {
      setSelectedBoxes([...selectedBoxes, checkboxId]);
    }
  };

  const dropDownShow = () => {
    setIsOpen(!isOpen);
    rotationItems.length = 0;
  };

  // handle click on layouts list
  const handleLayoutSelection = (value, type, name) => {
    setSelectedLayoutNameForCount(name);
    if (selectedLayoutBtn === "new") {
      setSaveDisable(true);
      setSubmitDisable(true);
    } else {
      setSaveDisable(false);
      setSubmitDisable(false);
    }
    setDisableButtons(false);
    setEditSavetooltip(true);
    setSuggestionLoader(false);
    setSelectedLayoutId(value);
    setLayoutType(type);
    getLayoutDetails(type, value);
    setEnableItems(true);
  };

  // toggle layouts list popup
  const handleLayoutToggle = (isOpen) => {
    setLayoutDetailsOpen(isOpen);
    if (!isOpen) {
      setDraftNotes(null);
      setSubmittedNotes(null);
    }
  };

  // on chat icon click open notes for layout
  const handleDraftNotes = (id) => {
    setDraftNotes(id === draftNotes ? null : id);
    setSubmittedNotes(null);
  };

  // on chat icon click open notes for layout
  const handleSubmittedNotes = (id) => {
    setSubmittedNotes(id === submittedNotes ? null : id);
    setDraftNotes(null);
  };

  // api call to get details for selected layout
  const getLayoutDetails = (type, layoutId) => {
    // only call details api if layout is not new
    if (type !== "new") {
      setSuggestionLoader(true);
      getLayoutDetailsAPI(
        layoutId,
        (data) => {
          const details = data?.layout_details;
          setSelectedLayoutDetails(details);
          setSuggestionLoader(false);
          setInputValues({
            ...inputValues,
            plotLength: details?.plot_length || "",
            plotWidth: details?.plot_width || "",
            noOfTreatments: details.no_of_treatments || "",
            noOfReps: details.no_of_reps || "",
            trmtDistance: details.x_dist || "",
            repDistance: details.y_dist || ""
          });
          setNotes(details?.notes);
          // eslint-disable-next-line no-unsafe-optional-chaining
          setPlotDetails(details?.plot_details || []);
          setLayoutType(details.status);
          setSelectedLayoutId(details.id);
          generateSavedPlots(details?.plot_details || []);
        },
        (err) => {
          console.log("err", err);
        }
      );
    } else {
      setSuggestionLoader(true);
      startOver();
      setLayerState([]);
      setSaveDisable(true);
      setSubmitDisable(true);
      setNotes("");
      setLayoutType("new");
      setSelectedLayoutDetails({});
      getDefaultValues(selectedTrial || addTrial || selectedLayoutNameForCount?.split("_")?.[0]);
    }
  };

  const cloneLayout = (layoutId) => {
    // only call details api if layout is not new
    setSuggestionLoader(true);
    getLayoutDetailsAPI(
      layoutId,
      (data) => {
        const details = data?.layout_details;
        setSuggestionLoader(false);
        setInputValues({
          ...inputValues,
          plotLength: details?.plot_length || "",
          plotWidth: details?.plot_width || "",
          noOfTreatments: details.no_of_treatments || "",
          noOfReps: details.no_of_reps || "",
          trmtDistance: details.x_dist || "",
          repDistance: details.y_dist || ""
        });
        setNotes(details?.notes);
        // eslint-disable-next-line no-unsafe-optional-chaining
        setPlotDetails(details?.plot_details || []);
        generateSavedPlots(details?.plot_details || []);
        setSaveDisable(false);
        setSubmitDisable(false);
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  // Render the plots based on the saved plot details
  const generateSavedPlots = (plots) => {
    clearAllDrawnLayers();
    manualDistanceApplied = false;
    transformListConst?.forEach((item) => item.transform?.removeHooks());

    mapLayout.eachLayer((layer) => {
      if (layer instanceof L.Popup) {
        mapLayout.removeLayer(layer);
      }
    });
    setPopupLayers([]);
    setTransformList([]);
    setSelectAll(false);
    setSelectedCheckboxes([]);
    setEnableSelectedPlots(false);
    tooltipGenerated();
    setSelectedPlots([]);

    plots.length > 0 &&
      plots.forEach((plot) => {
        let latlngs12 = [plot.plotCoordinates];
        let polygon3 = null;
        polygon3 = L.polygon(latlngs12, { fillColor: "transparent" });

        polygon3.addTo(drawnItemsConst);
        if (plot?.plotId === 1) {
          parentLayerId = polygon3._leaflet_id;
        }
        polygon3.on("edit", function (event) {
          plotResizeEvent(event, false);
          updateDrawnPlots();
        });

        // show transform and rotation icons if map is editing
        if (mapEditing === "true") {
          const transform = new L.Handler.PathTransform(polygon3);
          transform
            .setOptions({
              rotation: false,
              scaling: false
            })
            .enable();
          setTransformList((prevArray) => [
            ...prevArray,
            {
              plotId: plot.plotId,
              transform: transform
            }
          ]);
          transform.rotate(layerRotationConst);
          polygon3.on("rotateend", onStopRotateFeature, this);
        }

        // if first plot then save it as a base plot and save bearings
        if (plot.plotId == 1) {
          parentLayerId = polygon3._leaflet_id;

          setLayerRotation(0);
          setLayerState(polygon3);
          // Do not remove this commented code

          let latLngs = polygon3.getLatLngs()[0];
          let maxX = Infinity;
          let minY = Infinity;
          for (let i = 0; i <= latLngs.length - 1; i++) {
            minY = Math.min(minY, latLngs[i].lat);
          }

          for (let i = 0; i <= latLngs.length - 1; i++) {
            if (minY == latLngs[i].lat) {
              maxX = Math.min(maxX, latLngs[i].lng);
            }
          }

          let minIndex = 0;
          for (let i = 0; i <= latLngs.length - 1; i++) {
            if (maxX == latLngs[i].lng && minY == latLngs[i].lat) {
              minIndex = i;
            }
          }

          if (minIndex === 0) {
            repeatBearingRight = calcAngle(
              polygon3.getLatLngs()[0][1],
              polygon3.getLatLngs()[0][2],
              "outbound"
            );
            repeatBearingLeft = calcAngle(
              polygon3.getLatLngs()[0][1],
              polygon3.getLatLngs()[0][2],
              "inbound"
            );
            repeatBearingBottom = calcAngle(
              polygon3.getLatLngs()[0][0],
              polygon3.getLatLngs()[0][1],
              "outbound"
            );
            repeatBearingTop = calcAngle(
              polygon3.getLatLngs()[0][0],
              polygon3.getLatLngs()[0][1],
              "inbound"
            );
          } else {
            repeatBearingRight = calcAngle(
              polygon3.getLatLngs()[0][polygon3.getLatLngs()[0].length - 1],
              polygon3.getLatLngs()[0][0],
              "inbound"
            );
            repeatBearingLeft = calcAngle(
              polygon3.getLatLngs()[0][polygon3.getLatLngs()[0].length - 1],
              polygon3.getLatLngs()[0][0],
              "outbound"
            );
            repeatBearingBottom = calcAngle(
              polygon3.getLatLngs()[0][polygon3.getLatLngs()[0].length - 1],
              polygon3.getLatLngs()[0][polygon3.getLatLngs()[0].length - 2],
              "outbound"
            );
            repeatBearingTop = calcAngle(
              polygon3.getLatLngs()[0][polygon3.getLatLngs()[0].length - 1],
              polygon3.getLatLngs()[0][polygon3.getLatLngs()[0].length - 2],
              "inbound"
            );
          }

          repBearingConst = repeatBearingBottom;
          trmtBearingConst = repeatBearingRight;
          setTrmtBearing(repeatBearingRight);
          setRepBearing(repeatBearingBottom);
        }

        setPlotDetails((prevArray) => {
          const updatePlotDetails = [
            ...prevArray,
            {
              plotId: plot?.plotId,
              plotCoordinates: polygon3.getLatLngs()[0],
              leafletId: polygon3._leaflet_id,
              treatmentNumber: plot?.treatmentNumber,
              plotNumber: plot?.plotNumber,
              rotation: plot?.rotation || 0,
              Row: plot?.Row,
              Col: plot?.Col
            }
          ];
          return updatePlotDetails;
        });
      });

    tooltipGenerated();
  };

  // Generate tooltips on plots
  const tooltipGenerated = () => {
    if (drawnItemsConst !== null) {
      const treatmentAvailable = plotDetails.some((item) => item.treatmentNumber);
      const plotNumberAvailable = plotDetails.some((item) => item.plotNumber);
      drawnItemsConst.eachLayer((layer) => {
        layer.unbindTooltip();
        layer.closeTooltip();
        let filteredData = plotDetailsConst.filter((item) => {
          return item.leafletId.toString() == layer._leaflet_id.toString();
        });
        if (filteredData.length > 0) {
          let finalString = "";
          if (selectedBoxesConst.includes("Plot #") || selectedBoxesConst.includes("Treatment #")) {
            finalString += "<tr><td>";
          }
          if (
            selectedBoxesConst.includes("Plot #") &&
            filteredData[0]?.plotNumber != undefined &&
            plotNumberAvailable
          ) {
            finalString +=
              "<span class='tooltip-shadow'>" + filteredData[0]?.plotNumber?.toString() + "</span>";
          }
          if (
            selectedBoxesConst.includes("Treatment #") &&
            filteredData[0]?.treatmentNumber != undefined &&
            treatmentAvailable
          ) {
            finalString +=
              "<span class='tooltip-shadow'>" +
              ` [${filteredData[0].treatmentNumber?.toString()}]` +
              "</span>";
          }
          if (selectedBoxesConst.includes("Plot #") || selectedBoxesConst.includes("Treatment #")) {
            finalString += "</td></tr>";
          }

          if (selectedBoxesConst.includes("Plot ID")) {
            finalString +=
              "<tr><td>" +
              "<span class='tooltip-shadow'>Plot ID  " +
              `${filteredData[0].plotId?.toString()}` +
              "</span></td></tr>";
          }
          if (finalString !== "") {
            finalString = "<table>" + finalString + "</table>";

            let tooltip = L.tooltip({
              permanent: true,
              direction: "fast",
              className: "text",
              opacity: 1
            });
            tooltip.setContent(finalString);
            tooltip.setLatLng(layer.getCenter());
            layer.bindTooltip(tooltip).openTooltip();
          }
        }
      });
      updateTooltipViewOnZoom();
    }
  };

  // bind functions when event occurs on map
  const bindCallbacks = () => {
    mapLayout.on("moveend", () => {
      if (mapLayout.getZoom() === oldZoom) {
        updateTooltipViewOnPan();
      }
      oldZoom = mapLayout.getZoom();
    });

    mapLayout.on("movestart", () => {
      oldZoom = mapLayout.getZoom();
    });

    mapLayout.on("zoomend", () => {
      updateTooltipViewOnZoom();
    });
  };

  const bbox_intersects = (b1, b2) => {
    let has_l = b2.l <= b1.r && b2.l >= b1.l;
    let has_r = b2.r <= b1.r && b2.r >= b1.l;
    let has_t = b2.t >= b1.t && b2.t <= b1.b;
    let has_b = b2.b >= b1.t && b2.b <= b1.b;

    return (has_l || has_r) && (has_t || has_b);
  };

  // update tooltip view on zoom in or zoom out on map
  const updateTooltipViewOnZoom = () => {
    const chosenTooltipBounds = [];
    let exOffsetHeight, exOffsetWidth;
    drawnItemsConst.eachLayer((layer) => {
      const feature = layer;
      if (feature.getTooltip() !== undefined && feature.getTooltip() !== null) {
        const tooltip = layer.getTooltip();
        const tooltipHTMLelement = feature.getTooltip()._container;
        exOffsetWidth = tooltipHTMLelement.offsetWidth;
        exOffsetHeight = tooltipHTMLelement.offsetHeight;
        // this culls out-of-view tooltips
        // now we get center of feature as latlng, get the tooltip size in pixels (approx?),
        // project tooltip corners to latlng, make polygon, check intersection with each (?), only display those that are non intersecting
        const values = tooltipHTMLelement.style.transform.split(/\w+\(|\);?/);
        const transform = values[1].split(/,\s?/g).map((value) => {
          return parseInt(value.slice(0, -2));
        });
        const padding = 5;

        // tooltip position in pixels (top-left corner)
        const tooltipTopLeft = {
          x: transform[0] - padding,
          y: transform[1] - padding
        };

        const tooltipBottomRight = {
          x: tooltipTopLeft.x + exOffsetWidth + padding,
          y: tooltipTopLeft.y + exOffsetHeight + padding
        };

        const tooltipBBox = {
          t: tooltipTopLeft.y,
          l: tooltipTopLeft.x,
          b: tooltipBottomRight.y,
          r: tooltipBottomRight.x
        };

        let nonintersects = true;
        for (let i = 0; i < chosenTooltipBounds.length; i++) {
          if (bbox_intersects(tooltipBBox, chosenTooltipBounds[i])) {
            nonintersects = false;
            tooltipHTMLelement.style.visibility = "hidden";
            tooltipHTMLelement.classList.remove("should-show");
            tooltipHTMLelement.classList.add("dont-show");
            break;
          }
        }

        if (nonintersects) {
          if (feature.getBounds().intersects(mapLayout.getBounds())) {
            tooltip.setContent(tooltip._content);
            tooltipHTMLelement.style.visibility = "visible";
          } else {
            tooltipHTMLelement.style.visibility = "hidden";
          }

          tooltipHTMLelement.classList.remove("dont-show");
          tooltipHTMLelement.classList.add("should-show");
          tooltipHTMLelement.style.zIndex = "100";
          chosenTooltipBounds.push(tooltipBBox);
        }
      }
    });
  };

  // update tooltip view on drag and move event on map
  const updateTooltipViewOnPan = () => {
    drawnItemsConst.eachLayer((layer) => {
      const feature = layer;
      if (feature.getTooltip() !== undefined && feature.getTooltip() !== null) {
        const tooltipHTMLelement = feature.getTooltip()._container;
        const tooltip = layer.getTooltip();

        if (mapLayout.hasLayer(feature)) {
          if (
            feature.getBounds().intersects(mapLayout.getBounds()) &&
            (tooltipHTMLelement.classList.contains("should-show") ||
              tooltipHTMLelement.classList.contains("soloed"))
          ) {
            if (!tooltipHTMLelement.classList.contains("soloed")) {
              tooltip.setContent(tooltip._content);
            }
            tooltipHTMLelement.style.visibility = "visible";
          } else {
            tooltipHTMLelement.style.visibility = "hidden";
          }
        }
      }
    });
  };

  // save layout details on save or submit button click
  const saveLayout = (type, saveAs) => {
    setSaveLoading(true);
    setIsLayoutSavedAs(saveAs);
    const selectedType = type === "save" ? "Draft" : "Submitted";
    let newLayoutName = "";
    // new trial id if underscore not there then add as first value with underscore
    if (
      !selectedLayoutNameForCount?.includes("_") ||
      !addTrial?.includes("_") ||
      !selectedTrial?.includes("_")
    ) {
      newLayoutName = selectedLayoutNameForCount + "_1";
    }
    // create a new name for layout if trying to submit the layout
    if (selectedLayoutDetails?.status === "Draft" && !saveAs) {
      newLayoutName = selectedLayoutDetails?.layout;
    } else {
      if (layoutList.length === 0) {
        newLayoutName =
          (selectedTrial || addTrial || selectedLayoutNameForCount?.split("_")?.[0]) + "_1";
      } else if (layoutList.length > 0) {
        const splitedName = selectedLayoutNameForCount?.split("_");
        const layoutNumbers = layoutList
          ?.filter((item) => item?.layout?.split("_")[0] === splitedName[0])
          .map((item) => {
            const layoutName = item.layout.split("_");
            return layoutName[layoutName.length - 1];
          });

        if (layoutNumbers?.length === 0) {
          newLayoutName = (selectedTrial || addTrial || splitedName[0]) + "_1";
        } else {
          const maxNumber = Math.max(...layoutNumbers);
          newLayoutName =
            (selectedTrial || addTrial || splitedName[0]) + "_" + ((maxNumber || 0) + 1);
        }
      }
    }
    const payload = {
      trial_name: selectedTrial || addTrial || selectedLayoutNameForCount?.split("_")?.[0],
      collection_id: parseInt(collectionId),
      block_id: collectionDataConst?.block_id,
      layout: newLayoutName,
      plot_length: inputValues.plotLength || 0,
      plot_width: inputValues.plotWidth || 0,
      no_of_treatments: inputValues.noOfTreatments || 0,
      no_of_reps: inputValues.noOfReps || 0,
      x_dist: inputValues.trmtDistance || 0,
      y_dist: inputValues.repDistance || 0,
      plot_details: latestPlotDetails.current,
      created_by:
        selectedLayoutDetails?.created_by ||
        sessionStorage.getItem("firstName") + " " + sessionStorage.getItem("lastName"),
      last_modified_by:
        sessionStorage.getItem("firstName") + " " + sessionStorage.getItem("lastName"),
      created_by_email: `${sessionStorage.getItem("userName")}`,
      last_modified_by_email: `${sessionStorage.getItem("userName")}`,
      notes,
      status: selectedType
    };
    if (layoutType === "Draft" && !saveAs) {
      payload["layout_id"] = selectedLayoutId;
    }
    saveLayoutAPI(
      payload,
      (data) => {
        setSelectedLayoutNameForCount(newLayoutName);
        setSavedLayoutName(newLayoutName);
        setShowSaveModal(false);
        setShowSuccessModal(true);
        setSaveError(false);
        setSaveLoading(false);
        setNotes("");
        setSelectedLayoutBtn("drafted");
        getLayoutDetails("drafted", data?.data?.id);
        setSelectedLayoutId(data?.data?.id);
        startOver();
        setLayoutType(selectedLayoutBtn);
        setSelectedLayoutDetails({});
        // if (selectedLayoutBtn != "drafted") {
        //   getDefaultValues(
        //     selectedTrial || addTrial || selectedLayoutNameForCount?.split("_")?.[0],
        //     selectedLayoutBtn
        //   );
        // }
        setShowSupplyPlotDetails(false);
        getLayoutsList(collectionDataConst?.block_id, data?.data?.trial_name, (data1) => {
          setLayoutList(data1?.layouts_list);
          setDraftedItemsList(
            data1?.layouts_list?.filter(
              (item) => item?.status === "Draft" && item?.is_deleted === false
            )
          );
          setSumbittedItemsList(
            data1?.layouts_list?.filter(
              (item) => item.status === "Submitted" && item?.is_deleted === false
            )
          );
          setFilteredsubmittedItemsList(
            data1?.layouts_list?.filter(
              (item) => item.status === "Submitted" && item?.is_deleted === false
            )
          );
          setFiltereddraftItemsList(
            data1?.layouts_list?.filter(
              (item) => item?.status === "Draft" && item?.is_deleted === false
            )
          )
          setTimeout(() => {
            handleLayoutSelection(data?.data?.id, selectedType, newLayoutName);
          }, 1000);
          setTimeout(() => {
            setSaveDisable(false);
            setSubmitDisable(false);
          }, 1100);
        });
        setFileName("");
        setTimeout(() => {
          mapLayout?.invalidateSize();
        }, 100);
      },
      (err) => {
        console.log("err", err);
        setSaveError(true);
        setSaveLoading(false);
      }
    );
  };

  //generate excel file after click on file name. so it will have updated details from api
  const downloadUpdatedExcel = (plotData) => {
    const data = [];
    data.push(["Plot ID", "Plot #", "Treatment #"]);
    plotData
      .sort((a, b) => a.plotId - b.plotId)
      .forEach((plot) => {
        data.push([plot.plotId, plot?.plotNumber || "", plot?.treatmentNumber || ""]);
      });
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "sheet1");
    XLSX.writeFile(workbook, `${selectedTrial || addTrial || selectedLayoutNameForCount || "new"}.xlsx`);
  };

  // hanle input values with their validations
  const handleInputChange = (event) => {
    let regExp;
    if (event.target.name === "noOfTreatments" || event.target.name === "noOfReps") {
      regExp = /^\d{0,5}$/;
    } else {
      regExp = /^\d+(\.\d{1,2})?$/;
    }

    if (event.target.value === "" || regExp.test(event.target.value)) {
      setInputValues({ ...inputValues, [event.target.name]: event.target.value });
    } else {
      return;
    }
  };

  // handle manual checkbox selecion on plot checkbox click
  const checkBoxSelection = () => {
    const selectedPlotsRefValue = selectedPlotsRef.current;
    const tempNewArray = [...newArray];
    newArray.forEach((item, index) => {
      if (item.toString().includes("Row")) {
        const filteredRow = selectedPlotsRefValue.filter((row) => "Row" + row?.Row === item);
        if (selectedPlotsRefValue.length > 0 && filteredRow.length == 0) {
          tempNewArray.splice(index, 1);
          dropDownShow();
        }
      }
      if (item.toString().includes("Col")) {
        const filteredCol = selectedPlotsRefValue.filter((row) => "Col" + row?.Col === item);
        if (selectedPlotsRefValue.length > 0 && filteredCol.length == 0) {
          tempNewArray.splice(index, 1);
          dropDownShow();
        }
      }
      if (selectedPlotsRefValue == 0) {
        setSelectAll(false);
      }
    });
    newArray = tempNewArray;
    setSelectedCheckboxes([...tempNewArray]);
  };

  //handle click manual checkbox selecion on plot checkbox click
  const selectPlots = (e) => {
    const filteredData = plotDetailsConst.filter((plot) => {
      return plot.leafletId.toString() == e.target.value.toString();
    });
    const colId = "Col" + filteredData[0]?.Col;
    const rowId = "Row" + filteredData[0]?.Row;
    if (e.target.checked) {
      setSelectedPlots((selectedPlots) => [...selectedPlots.filter(x => x.plotId != filteredData[0].plotId), filteredData[0]]);
      updateRotations("add", rowId + colId);
    } else {
      setSelectedPlots((prevArray) =>
        prevArray.filter((plot) => plot.leafletId.toString() !== e.target.value.toString())
      );
      setTimeout(() => {
        checkBoxSelection();
      }, 1000);      
      let colIndex = newArray.findIndex(x => x.includes(colId));
      if (colIndex > -1) {
        newArray.splice(colIndex, 1);
        setSelectedCheckboxes([...newArray]);
      }
      let rowIndex = newArray.findIndex(x => x.includes(rowId));
      if (rowIndex > -1) {
        newArray.splice(rowIndex, 1);
        setSelectedCheckboxes([...newArray]);
      }
      updateRotations("remove", "Row" + filteredData[0].Row + "Col" + filteredData[0].Col);

    }
    let angle = rotationItems.length == 0 ? 0 : Number((rotationItems[0].value * 180 / Math.PI).toFixed(0));
    setAngleValue(angle == "NaN" ? 0 : angle);
    setTimeout(() => {
      let inputAngle = document.getElementById("inputAngle");
      if (inputAngle) {
        inputAngle.value = getProperAngle(angle);
      }
    }, 100);
  };

  // get zoom values from api response
  const extractZoomsFromLayer = (layer) => {
    return {
      min: Math.min.apply(null, layer.zooms),
      max: Math.max.apply(null, layer.zooms)
    };
  };

  // get bound values from api response
  const extractBounds = () => {
    return L.latLngBounds(
      L.latLng(collectionData?.nswe[1], collectionData?.nswe[2]),
      L.latLng(collectionData?.nswe[0], collectionData?.nswe[3])
    );
  };

  // get zoom values from api response
  const extractZooms = () => {
    return collectionData?.base_layer.zooms;
  };

  // get zoom values from api response
  const getZooms = () => {
    const zooms = extractZooms();
    return {
      min: Math.min.apply(null, zooms),
      max: Math.max.apply(null, zooms)
    };
  };

  // generate base image on map
  const generateRasterLayers = () => {
    const makeLayer = (rasterlayer) => {
      if (rasterlayer === undefined || rasterlayer === null) {
        return undefined;
      }

      let layer_template;
      let zIndex = 1;
      if (rasterlayer.meta_data.imgtype !== undefined && rasterlayer.meta_data.imgtype === "png") {
        layer_template = "/tiles/{baseName}/{z}/{x}/{y}.png";
        zIndex = 2;
      } else {
        layer_template = "/tiles/{baseName}/{z}/{x}/{y}.jpeg";
      }

      const tl = L.tileLayer(layer_template, {
        baseName: rasterlayer.s3_id,
        minZoom: collectionDataConst?.base_layer?.zooms?.min,
        maxZoom: collectionDataConst?.base_layer?.zooms?.max + 3,
        minNativeZoom: extractZoomsFromLayer(rasterlayer).min,
        maxNativeZoom: extractZoomsFromLayer(rasterlayer).max,
        bounds: extractBounds(),
        noWrap: true,
        zIndex: zIndex
      });

      tl.on("tileerror", async () => { });

      return tl;
    };

    const baseLayer = makeLayer(collectionDataConst?.base_layer);
    let innerRasterLayers = {
      Visible: baseLayer
    };

    setRasterLayers(innerRasterLayers);
  };

  // add checkbox popup on each plot
  const addCheckboxPopupOnPlot = (layer, checked, plotId, rowId, colId) => {
    const popup = L.popup({
      closeButton: false,
      autoClose: false,
      closeOnClick: false,
      direction: "center",
      className: "checkbox-popup",
      offset: [0, 0],
      autoPan: false
    })
      .setLatLng(layer.getBounds().getCenter())
      .setContent(
        "<input class='toggleSwitch' id='" +
        rowId +
        colId +
        "' type='checkbox' value=" +
        layer._leaflet_id +
        " " +
        (checked ? "checked" : "") +
        " />"
      )
      .openOn(mapLayout);

    setPopupLayers((prevArray) => [
      ...prevArray,
      { plotId: plotId, leafletId: layer._leaflet_id, popup: popup }
    ]);
  };

  // generate plot after resize, rotate and drag
  const plotCreation = (
    latLngs,
    bearing,
    distance,
    plotId,
    leafletId,
    rotation = 0,
    returnLayout = false
  ) => {
    //Remove popup - start
    const filterPopup = popupLayersConst.filter((plot) => {
      return plot.plotId.toString() === plotId.toString();
    });

    if (filterPopup.length > 0) {
      filterPopup[0].popup.removeFrom(mapLayout);

      setPopupLayers((prevArray) =>
        prevArray.filter((plot) => plot.plotId.toString() !== plotId.toString())
      );
    }

    //Remove popup - end

    drawnItemsConst.removeLayer(leafletId);

    const filterTransform = transformListConst.filter((plot) => {
      return plot.plotId.toString() === plotId.toString();
    });

    if (filterTransform.length > 0) {
      filterTransform[0]?.transform?.disable?.();
      filterTransform[0]?.transform?.reset();
      setTransformList((prevArray) =>
        prevArray.filter((plot) => plot.plotId.toString() !== plotId.toString())
      );
    }

    const points = [];
    for (let i = 0; i < latLngs.length; i++) {
      points.push(
        L.GeometryUtil.destination({ lat: latLngs[i].lat, lng: latLngs[i].lng }, bearing, distance)
      );
    }

    let rectangle = L.polygon(points, { fillColor: "transparent", color: "#3388ff" });

    rectangle.addTo(drawnItemsConst);

    //Remove popup - start
    if (plotId.toString() == "1") {
      parentLayerId = rectangle._leaflet_id;
    }

    rectangle.on("edit", (event) => {
      updateDrawnPlots();
      plotResizeEvent(event, false);
    });

    const transform = new L.Handler.PathTransform(rectangle);
    transform
      .setOptions({
        rotation: false,
        scaling: false
      })
      .enable();

    rectangle.on("rotateend", onStopRotateFeature, this);

    setTransformList((prevArray) => [
      ...prevArray,
      {
        plotId: plotId,
        transform: transform
      }
    ]);

    const plotDetailsFilter = plotDetailsConst.filter((plot) => {
      return plot.plotId.toString() === plotId.toString();
    });

    if (plotDetailsFilter.length > 0) {
      setPlotDetails((prevArray) =>
        prevArray.filter((plot) => plot.plotId.toString() !== plotId.toString())
      );
      setPlotDetails((prevArray) => [
        ...prevArray,
        {
          Row: plotDetailsFilter[0].Row,
          Col: plotDetailsFilter[0].Col,
          treatmentNumber: plotDetailsFilter[0]?.treatmentNumber,
          plotNumber: plotDetailsFilter[0]?.plotNumber,
          plotId: plotId,
          plotCoordinates: rectangle._latlngs[0],
          rotation: rotation,
          leafletId: rectangle._leaflet_id
        }
      ]);
    }

    const selectedPlotsFilter = selectedPlotsConst.filter((plot) => {
      return plot.plotId.toString() == plotId.toString();
    });

    if (selectedPlotsFilter.length > 0) {
      setSelectedPlots((prevArray) =>
        prevArray.filter((plot) => plot.plotId.toString() !== plotId.toString())
      );

      setSelectedPlots((prevArray) => [
        ...prevArray,
        {
          Row: plotDetailsFilter[0].Row,
          Col: plotDetailsFilter[0].Col,
          plotId: plotId,
          plotCoordinates: rectangle._latlngs[0],
          leafletId: rectangle._leaflet_id,
          rotation: rotation
        }
      ]);
    }

    addCheckboxPopupOnPlot(
      rectangle,
      true,
      plotId,
      "Row" + plotDetailsFilter[0].Row,
      "Col" + plotDetailsFilter[0].Col
    );
    checkBoxes = document.querySelectorAll(".toggleSwitch");
    checkBoxes.forEach((checkBox) => {
      checkBox.removeEventListener("change", (e) => {
        selectPlots(e);
      });
    });
    checkBoxes = document.querySelectorAll(".toggleSwitch");
    checkBoxes.forEach((checkBox) => {
      checkBox.addEventListener("change", (e) => {
        selectPlots(e);
      });
    });

    return returnLayout ? rectangle : rectangle._latlngs[0];
  };

  // initiate map container and add required callback on event
  const initMap = () => {
    let zooms = getZooms();
    let container = L.DomUtil.get("map");

    if (container != null) {
      container._leaflet_id = null;
    }
    let innerMap = mapState;
    innerMap = L.map("map", {
      mapMaxZoom: zooms.max + 3,
      mapMinZoom: zooms.min,
      crs: L.CRS.EPSG3857,
      zoomControl: false,
      rotate: true,
      rotateControl: {
        closeOnZeroBearing: false
      },
      touchRotate: true
    }).setView([0, 0], zooms.max);
    innerMap.setMinZoom(zooms.min);
    innerMap.setMaxZoom(zooms.max + 3);
    innerMap.fitBounds(extractBounds());
    innerMap.setView(innerMap.getCenter(), innerMap.getZoom());
    innerMap.touchRotate.disable();
    innerMap.touchZoom.disable();
    innerMap.compassBearing.disable();
    innerMap.touchGestures.enable();
    innerMap.rotateControl.setPosition("bottomright");
    innerMap.setMaxBounds([
      [-90, -180],
      [90, 180]
    ]);
    rasterLayers["Visible"]?.addTo(innerMap);

    let ZoomViewer = L.Control.extend({
      onAdd: function () {
        let container = L.DomUtil.create("div");
        let gauge = L.DomUtil.create("div");
        container.style.width = "5em";
        container.style.background = "rgba(255,255,255,0.5)";
        container.style.textAlign = "center";
        container.style.position = "fixed"; //new
        container.style.bottom = "5px"; //new
        container.style.right = "18px"; //new
        innerMap.on("zoomstart zoom zoomend", function () {
          gauge.innerHTML = "zoom: " + innerMap.getZoom();
        });
        innerMap.on("zoomend", function () {
          gauge.innerHTML = "zoom: " + innerMap.getZoom();
          if (isSelectPlotConst) {
            drawnItemsConst.eachLayer((layer) => {
              const filtered1 = plotDetailsConst.filter((plot) => {
                return plot.leafletId.toString() == layer._leaflet_id;
              });
              if (filtered1.length > 0) {
                const filterTransform = transformListConst.filter((plot) => {
                  return plot.plotId.toString() === filtered1[0].plotId.toString();
                });

                if (filterTransform.length > 0) {
                  filterTransform[0]?.transform?.disable?.();
                  filterTransform[0]?.transform?.reset();
                  setTransformList((prevArray) =>
                    prevArray.filter(
                      (plot) => plot.plotId.toString() !== filtered1[0].plotId.toString()
                    )
                  );
                }

                const transform = new L.Handler.PathTransform(layer);
                transform
                  .setOptions({
                    rotation: false,
                    scaling: false, 
                  })
                  .enable();

                setTransformList((prevArray) => [
                  ...prevArray,
                  {
                    plotId: filtered1[0]?.plotId,
                    transform: transform
                  }
                ]);
                layer.off("rotateend");
                layer.on("rotateend", onStopRotateFeature, this);
              }
            });
          }
        });
        gauge.innerHTML = "zoom: " + innerMap.getZoom();
        container.appendChild(gauge);

        return container;
      }
    });

    new ZoomViewer({ position: "bottomright" }).addTo(innerMap);

    L.control
      .zoom({
        position: "bottomright"
      })
      .addTo(innerMap);

    let drawnItems = new L.FeatureGroup();
    innerMap.addLayer(drawnItems);

    // Initialise the draw control and pass it the FeatureGroup of editable layers
    let drawControl = new L.Control.Draw({
      edit: {
        featureGroup: drawnItems,
        moveMarkers: false,
        poly: {
          allowIntersection: false
        }
      },
      position: "topright",
      draw: {
        polygon: false,
        rectangle: false,
        circle: false,
        marker: false,
        polyline: false,
        circlemarker: false,
        position: "topright"
      },
      style: { display: "none" }
    });
    drawnItemsConst = drawnItems;
    innerMap.addControl(drawControl);

    L.EditToolbar.include({
      getActions: function (handler) {
        let actions = [
          {
            title: L.drawLocal.edit.toolbar.actions.save.title,
            text: L.drawLocal.edit.toolbar.actions.save.text,
            callback: this._save,
            context: this
          },
          {
            title: L.drawLocal.edit.toolbar.actions.cancel.title,
            text: L.drawLocal.edit.toolbar.actions.cancel.text,
            //        callback: this.disable,  // --- original
            callback: handler.type === "edit" ? this._cancel : this.disable, // --- changed
            context: this
          }
        ];
        if (handler.removeAllLayers) {
          actions.push({
            title: L.drawLocal.edit.toolbar.actions.clearAll.title,
            text: L.drawLocal.edit.toolbar.actions.clearAll.text,
            callback: this._clearAllLayers,
            context: this
          });
        }
        return actions;
      },

      __clearAllLayers: function () {
        clearAllDrawnLayers();
        transformListConst?.forEach((item) => {
          item.transform?.removeHooks();
        });
        transformListConst = [];
        mapLayout.eachLayer((layer) => {
          if (layer instanceof L.Transformation) {
            mapLayout.removeLayer(layer);
          }
        });
        setTransformList([]);
        mapLayout.eachLayer((layer) => {
          if (layer instanceof L.Popup) {
            mapLayout.removeLayer(layer);
          }
        });
        setPopupLayers([]);
        manualDistanceApplied = false;
        setIsOpen(false);
        setIsSelectPlot(false);
        setSelectedCheckboxes([]);
        setEnableSelectedPlots(false);
        newArray = [];
        setSelectAll(false);
        setPlotDetails([]);
        setTransformList([]);
        setSelectedPlots([]);
        if (this._activeMode) {
          this._activeMode.handler.disable();
        }
        mapEditing = "false";
      },

      _save: function () {
        this._activeMode.handler.save();
        transformListConst?.forEach((item) => {
          item.transform?.removeHooks();
        });
        transformListConst = [];
        setTransformList([]);
        mapLayout.eachLayer((layer) => {
          if (layer instanceof L.Popup) {
            mapLayout.removeLayer(layer);
          }
        });
        setPopupLayers([]);
        manualDistanceApplied = false;
        setIsOpen(false);
        setIsSelectPlot(false);
        setSelectedCheckboxes([]);
        setEnableSelectedPlots(false);
        newArray = [];
        setSelectAll(false);

        setTransformList([]);
        setSelectedPlots([]);
        if (this._activeMode) {
          this._activeMode.handler.disable();
        }
        mapEditing = "false";
      },
      // --- new -----------------
      _cancel: function () {
        clearAllDrawnLayers();
        transformListConst?.forEach((item) => {
          item.transform?.removeHooks();
        });
        transformListConst = [];
        mapLayout.eachLayer((layer) => {
          if (layer instanceof L.Transformation) {
            mapLayout.removeLayer(layer);
          }
        });
        setTransformList([]);
        mapLayout.eachLayer((layer) => {
          if (layer instanceof L.Popup) {
            mapLayout.removeLayer(layer);
          }
        });
        setPopupLayers([]);
        manualDistanceApplied = false;
        setIsSelectPlot(false);
        setSelectedCheckboxes([]);
        setEnableSelectedPlots(false);
        newArray = [];
        setSelectAll(false);
        if (drawnPlots.length === 0) {
          setLayerState([]);
          setSaveDisable(true);
          setSubmitDisable(true);          
        }
        setTransformList([]);
        setSelectedPlots([]);
        setIsOpen(false);
        let sortedDrawnPlots = [...drawnPlots]?.sort((a, b) => {
          if (a["Col"] > b["Col"]) {
            return 1;
          } else if (b["Col"] > a["Col"]) {
            return -1;
          } else {
            return 0;
          }
        });

        // Sort the selected plots by column
        sortedDrawnPlots = [...sortedDrawnPlots]?.sort((a, b) => {
          if (a["Row"] > b["Row"]) {
            return 1;
          } else if (b["Row"] > a["Row"]) {
            return -1;
          } else {
            return 0;
          }
        });
        sortedDrawnPlots.forEach((plot) => {
          let polygon = null;
          polygon = L.polygon(plot.plotCoordinates, { fillColor: "transparent" });

          polygon.addTo(drawnItemsConst);

          polygon.on("edit", function (event) {
            updateDrawnPlots();
            plotResizeEvent(event, false);
          });

          const coordinates = polygon.getLatLngs()[0];
          const rotation = polygon.rotation || plot.rotation || 0;
          const leafletId = polygon._leaflet_id;

          setPlotDetails((prevArray) => {
            const updatePlotDetails = [
              ...prevArray,
              {
                plotId: prevArray.length + 1,
                plotCoordinates: coordinates,
                leafletId: leafletId,
                rotation,
                Row: plot.Row,
                Col: plot.Col,
                treatmentNumber: plot.treatmentNumber,
                plotNumber: plot.plotNumber
              }
            ];
            return updatePlotDetails;
          });

          if (plot.plotId.toString() === "1") {
            parentLayerId = polygon._leaflet_id;

            let latLngs = polygon.getLatLngs()[0];
            let maxX = Infinity;
            let minY = Infinity;
            for (let i = 0; i <= latLngs.length - 1; i++) {
              minY = Math.min(minY, latLngs[i].lat);
            }

            for (let i = 0; i <= latLngs.length - 1; i++) {
              if (minY == latLngs[i].lat) {
                maxX = Math.min(maxX, latLngs[i].lng);
              }
            }

            let minIndex = 0;
            for (let i = 0; i <= latLngs.length - 1; i++) {
              if (maxX == latLngs[i].lng && minY == latLngs[i].lat) {
                minIndex = i;
              }
            }

            if (minIndex === 0) {
              repeatBearingRight = calcAngle(
                polygon.getLatLngs()[0][1],
                polygon.getLatLngs()[0][2],
                "outbound"
              );
              repeatBearingLeft = calcAngle(
                polygon.getLatLngs()[0][1],
                polygon.getLatLngs()[0][2],
                "inbound"
              );
              repeatBearingBottom = calcAngle(
                polygon.getLatLngs()[0][0],
                polygon.getLatLngs()[0][1],
                "outbound"
              );
              repeatBearingTop = calcAngle(
                polygon.getLatLngs()[0][0],
                polygon.getLatLngs()[0][1],
                "inbound"
              );
            } else {
              repeatBearingRight = calcAngle(
                polygon.getLatLngs()[0][polygon.getLatLngs()[0].length - 1],
                polygon.getLatLngs()[0][0],
                "inbound"
              );
              repeatBearingLeft = calcAngle(
                polygon.getLatLngs()[0][polygon.getLatLngs()[0].length - 1],
                polygon.getLatLngs()[0][0],
                "outbound"
              );
              repeatBearingBottom = calcAngle(
                polygon.getLatLngs()[0][polygon.getLatLngs()[0].length - 1],
                polygon.getLatLngs()[0][polygon.getLatLngs()[0].length - 2],
                "outbound"
              );
              repeatBearingTop = calcAngle(
                polygon.getLatLngs()[0][polygon.getLatLngs()[0].length - 1],
                polygon.getLatLngs()[0][polygon.getLatLngs()[0].length - 2],
                "inbound"
              );
            }

            // Get the bounds of the polygon
            let bounds = polygon.getBounds();
            // Get the southwest and northwest points
            let sw = bounds.getSouthWest();
            let ne = bounds.getNorthEast();

            // Calculate the midpoint
            let center = L.latLng((sw.lat + ne.lat) / 2, (sw.lng + ne.lng) / 2);

            // Calculate the destination point 5 meters in the direction of the rotated bearing
            let point = turf.point([center.lng, center.lat]);
            let options = { units: "meters" };
            let destinationPoint = turf.destination(point, 300, repeatBearingBottom, options);
            let destination = L.latLng(
              destinationPoint.geometry.coordinates[1],
              destinationPoint.geometry.coordinates[0]
            );

            // Define the path for the polyline
            let path = [
              [center.lat, center.lng],
              [destination.lat, destination.lng]
            ];

            // Create the polyline
            let polyline = L.polyline(path, { color: "yellow" });

            // Calculate the destination point 5 meters in the direction of the rotated bearing
            let pointTop = turf.point([center.lng, center.lat]);
            let destinationPointTop = turf.destination(pointTop, 300, repeatBearingRight, options);
            let destinationTop = L.latLng(
              destinationPointTop.geometry.coordinates[1],
              destinationPointTop.geometry.coordinates[0]
            );

            // Define the path for the polyline
            let pathTop = [
              [center.lat, center.lng],
              [destinationTop.lat, destinationTop.lng]
            ];

            // Create the polyline
            let polylineTop = L.polyline(pathTop, { color: "red" });

            // Convert the polygon and polyline to GeoJSON
            let polygonGeoJSON = polygon.toGeoJSON();
            let polylineGeoJSON = polyline.toGeoJSON();

            // Convert the polygon to a line string
            let polygonLineString = turf.polygonToLineString(polygonGeoJSON);

            // Find the intersection points
            let intersectionPoints = turf.lineIntersect(polygonLineString, polylineGeoJSON);

            // Convert the polygon and polyline to GeoJSON
            let polygonGeoJSONTop = polygon.toGeoJSON();
            let polylineGeoJSONTop = polylineTop.toGeoJSON();

            // Convert the polygon to a line string
            let polygonLineStringTop = turf.polygonToLineString(polygonGeoJSONTop);

            // Find the intersection points
            let intersectionPointsTop = turf.lineIntersect(
              polygonLineStringTop,
              polylineGeoJSONTop
            );

            let repeatDistance = basePlotHeight;
            let treatmentDistance = basePlotWidth;
            //The below setstate taking time and before thta generate plot method is getting called. So directly assigning the values to the const variables
            repBearingConst = repeatBearingBottom;
            trmtBearingConst = repeatBearingRight;
            trmtDistanceAddConst = treatmentDistance;
            repDistanceAddConst = repeatDistance;

            setTrmtBearing(repeatBearingRight);
            setRepBearing(repeatBearingBottom);
            setTrmtDistanceAdd(treatmentDistance);
            setRepDistanceAdd(repeatDistance);
            setDistanceByManual(distanceByManual);
          }
        });
        mapEditing = "false";
        setAngleValue(0);
        setEditCurrentIndex(0);
        drawnPlotsEdit.length = 0;
        setDisableUndo(false);
        setDisableRedo(false);        
        if (this._activeMode) {
          this._activeMode.handler.disable();
        }
      }
    });

    innerMap.on(L.Draw.Event.EDITED, () => {
      setSelectedCheckboxes([]);
      newArray = [];
    });

    innerMap.on("draw:editstart", () => {
      setDisableButtons(true);
      setSaveDisable(true);
      setSubmitDisable(true);
      setEditCurrentIndex(0);
      mapEditing = "true";
      drawnPlots.length = 0;
      drawnPlotsTemp.length = 0;
      drawnPlotsCopy.length = 0;
      drawnPlotsEdit.length = 0;      
      plotDetailsConst.forEach(function (plot) {
        let obj = {};
        obj["leafletId"] = plot.leafletId;
        obj["plotId"] = plot.plotId;
        obj["Row"] = plot.Row;
        obj["Col"] = plot.Col;
        obj["treatmentNumber"] = plot.treatmentNumber || 0;
        obj["plotNumber"] = plot.plotNumber || 0;
        obj["rotation"] = plot.rotation;

        let latlng = [];
        for (let i = 0; i <= plot.plotCoordinates.length - 1; i++) {
          let latlngArray = [];
          latlngArray.push(plot.plotCoordinates[i].lat);
          latlngArray.push(plot.plotCoordinates[i].lng);
          latlng.push(L.latLng(latlngArray[0], latlngArray[1]));
        }

        obj["plotCoordinates"] = [...latlng];
        drawnPlots.push({ ...obj });
        drawnPlotsEdit.push({ ...obj });
        drawnPlotsTemp.push({ ...obj });
        drawnPlotsCopy.push({ ...obj });
      });
      setIsSelectPlot(true);
      setXDistance(trtmtDistanceConst);
      setYDistance(repDistanceConst);
      setManualPlotWidth(latestInputValues?.current?.plotWidth);
      setManualPlotLength(latestInputValues?.current?.plotLength);
      drawnItemsConst.eachLayer((layer) => {
        const filtered1 = plotDetailsConst.filter((plot) => {
          return plot.leafletId.toString() == layer._leaflet_id;
        });
        if (filtered1.length > 0) {
          const transform = new L.Handler.PathTransform(layer);
          transform
            .setOptions({
              rotation: false,
              scaling: false
            })
            .enable();

          setTransformList((prevArray) => [
            ...prevArray,
            {
              plotId: filtered1[0]?.plotId,
              transform: transform
            }
          ]);
          layer.off("rotateend");
          layer.on("rotateend", onStopRotateFeature, this);
        }
      }); 
      rotationItems.length = 0;
      setDisableUndo(true);
      setDisableRedo(true);      
    });

    innerMap.on("draw:deleted", (e) => {
      transformListConst?.forEach((item) => {
        item.transform?.removeHooks();
      });
      transformListConst = [];
      mapLayout.eachLayer((layer) => {
        if (layer instanceof L.Transformation) {
          mapLayout.removeLayer(layer);
        }
      });
      setTransformList([]);
      mapLayout.eachLayer((layer) => {
        if (layer instanceof L.Popup) {
          mapLayout.removeLayer(layer);
        }
      });
      setPopupLayers([]);
      setIsOpen(false);
      setIsSelectPlot(false);
      let temp = [...latestPlotDetails.current];
      e.layers.eachLayer((layer) => {
        temp = temp.filter((plot) => plot.leafletId !== layer._leaflet_id);
      });
      if (temp.length === 0) {
        layerConst = [];
        setLayerState(temp);
        setFileName("");
        setSelectedPlots([]);
        setSaveDisable(true);
        setSubmitDisable(true);
      }
      setPlotDetails(temp);
    });

    innerMap.on("draw:editstop", () => {
      if (mapEditing === "true") {
        transformListConst?.forEach((item) => {
          item.transform?.removeHooks();
        });
        mapLayout.eachLayer((layer) => {
          if (layer instanceof L.Transformation) {
            mapLayout.removeLayer(layer);
          }
        });
        setTransformList([]);
        mapLayout.eachLayer((layer) => {
          if (layer instanceof L.Popup) {
            mapLayout.removeLayer(layer);
          }
        });
        setPopupLayers([]);
        setIsSelectPlot(false);
        setSelectedPlots([]);
        setAngleValue(0);
      }
      mapEditing = "false";
      setDisableButtons(false);
      setSaveDisable(false);
      setSubmitDisable(false);
    });

    L.DomEvent.fakeStop = function () {
      return true;
    };
    mapLayout = innerMap;
    setMapState(innerMap);
  };

  // calculate the angle of plot
  const calcAngle = (p1, p2, direction) => {
    let brng = 0;
    let lat1 = (p1.lat / 180) * Math.PI;
    let lat2 = (p2.lat / 180) * Math.PI;
    let lng1 = (p1.lng / 180) * Math.PI;
    let lng2 = (p2.lng / 180) * Math.PI;
    let y = Math.sin(lng2 - lng1) * Math.cos(lat2);
    let x =
      Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1);
    if (direction === "inbound") {
      brng = ((Math.atan2(y, x) * 180) / Math.PI + 180).toFixed(0);
    } else {
      brng = ((Math.atan2(y, x) * 180) / Math.PI + 360).toFixed(0);
    }
    return brng % 360;
  };

  const getModifiedPlotSizes = (latLng, OriginalLatLng) => {
    modifiedPlotHeight = 0;
    modifiedPlotWidth = 0;
    let w1 = L.latLng(latLng[0]).distanceTo(latLng[3]);
    let h1 = L.latLng(latLng[0]).distanceTo(latLng[1]);
    let w2 = L.latLng(latLng[1]).distanceTo(latLng[2]);
    let h2 = L.latLng(latLng[2]).distanceTo(latLng[3]);
    let ow1 = L.latLng(OriginalLatLng[0]).distanceTo(OriginalLatLng[3]);
    let oh1 = L.latLng(OriginalLatLng[0]).distanceTo(OriginalLatLng[1]);
    let ow2 = L.latLng(OriginalLatLng[1]).distanceTo(OriginalLatLng[2]);
    let oh2 = L.latLng(OriginalLatLng[2]).distanceTo(OriginalLatLng[3]);
    let plotWidth1 = 0;
    let plotWidth2 = 0;
    let plotHeight1 = 0;
    let plotHeight2 = 0;   
    if (w1.toFixed(7).slice(0, -1) !== w2.toFixed(7).slice(0, -1) & h1.toFixed(7).slice(0, -1) !== h2.toFixed(7).slice(0, -1)) {  
      if (w1.toFixed(7) != ow1.toFixed(7)) {
        plotWidth1 = w1 - ow1;
      }
      if (w2.toFixed(7) != ow2.toFixed(7)) {
        plotWidth2 = w2 - ow2;
      }
      if (h1.toFixed(7) != oh1.toFixed(7)) {
        plotHeight1 = h1 - oh1;
      }
      if (h2.toFixed(7) != oh2.toFixed(7)) {
        plotHeight2 = h2 - oh2;
      }
      modifiedPlotHeight = plotHeight1 != 0 ? plotHeight1 : plotHeight2;
      modifiedPlotWidth = plotWidth1 != 0 ? plotWidth1 : plotWidth2;    
    }
  }
 
  // Get all bearing values based on input plot
  const getAllBearing = (latLng) =>{
    let latLngs = latLng;
    let maxX = Infinity;
    let minY = Infinity;
    for (let i = 0; i <= latLngs.length - 1; i++) {
      minY = Math.min(minY, latLngs[i].lat);
    }

    for (let i = 0; i <= latLngs.length - 1; i++) {
      if (minY == latLngs[i].lat) {
        maxX = Math.min(maxX, latLngs[i].lng);
      }
    }

    let minIndex = 0;
    for (let i = 0; i <= latLngs.length - 1; i++) {
      if (maxX == latLngs[i].lng && minY == latLngs[i].lat) {
        minIndex = i;
      }
    }
 
    if (minIndex === 0) {
      repeatBearingRight = calcAngle(
        latLngs[0],
        latLngs[latLngs.length - 1],
        "outbound"
      );
      repeatBearingLeft = calcAngle(
        latLngs[0],
        latLngs[latLngs.length - 1],
        "inbound"
      );
      repeatBearingBottom = calcAngle(
        latLngs[0],
        latLngs[1],
        "outbound"
      );
      repeatBearingTop = calcAngle(
        latLngs[0],
        latLngs[1],
        "inbound"
      );
    } else {
      repeatBearingRight = calcAngle(
        latLngs[latLngs.length - 1],
        latLngs[0],
        "inbound"
      );
      repeatBearingLeft = calcAngle(
        latLngs[latLngs.length - 1],
        latLngs[0],
        "outbound"
      );
      repeatBearingBottom = calcAngle(
        latLngs[latLngs.length - 1],
        latLngs[latLngs.length - 2],
        "outbound"
      );
      repeatBearingTop = calcAngle(
        latLngs[latLngs.length - 1],
        latLngs[latLngs.length - 2],  
        "inbound"
      );
    }
  }

 // when any plot is edited manually this function is called to get bearing and distance to generate another plots
  const plotResizeEvent = (e, eventType) => {
    let newLayer;
    let leafletId = 0;
    let rotation = 0;
    //Removing the layer and recreating it - Start
    if (eventType.toString() == "rotated") { 
      const sortSelectedPlots = [...selectedPlots].sort((a, b) => {
        if (a["Row"] > b["Row"]) {
          return 1;
        } else if (b["Row"] > a["Row"]) {
          return -1;
        } else {
          return 0;
        }
      });
      const modifiedPlot = plotDetailsConst.filter((plot) => {
        return plot.leafletId.toString() == sortSelectedPlots[0]?.leafletId;
      });

      if (modifiedPlot.length > 0) {
        leafletId = modifiedPlot[0].leafletId;
        const filterTransform = transformListConst.filter((plot) => {
          return plot.plotId.toString() === modifiedPlot[0].plotId.toString();
        });

        if (filterTransform.length > 0) {
          filterTransform[0]?.transform?.disable?.();
          filterTransform[0]?.transform?.reset();
          setTransformList((prevArray) =>
            prevArray.filter((plot) => plot.plotId.toString() !== modifiedPlot[0].plotId.toString())
          );
        }

        var polygon = new L.Polygon([[modifiedPlot[0].plotCoordinates]]),
          latLngsRotate = polygon.getLatLngs()[0][0],
          boundsRotate = polygon.getBounds(),
          centerRotate = boundsRotate.getCenter(),
          newLatLngs = []; 

        let plotLatlngs = newLatLngs;
        let actualAngle = 0; 
        let targetValue = Number(e.target.value);
        let plotAngle = 0;
        let _angleValue = 0;
        if (targetValue >= 0 & targetValue > 180) { 
          plotAngle = targetValue - angleValue - 360;
          actualAngle = plotAngle + angleValue;
          _angleValue = targetValue;  
        } else {
          actualAngle = targetValue;
          plotAngle = actualAngle - angleValue;   
          _angleValue = getProperAngle(actualAngle);                    
        }
        setAngleValue(actualAngle);
        rotation = actualAngle * Math.PI / 180;        
        let inputAngle = document.getElementById("inputAngle");
        inputAngle.value = _angleValue;

        latLngsRotate.forEach(function (latLng) {
            newLatLngs.push(L.GeometryUtil.rotatePoint(mapLayout, latLng, plotAngle, centerRotate));
          });        

        //drawnItemsConst.removeLayer(polygon.target);
        drawnItemsConst.removeLayer(drawnItemsConst.getLayer(modifiedPlot[0].leafletId));

        newLayer = L.polygon(plotLatlngs, {
          marker: false,
          allowIntersection: false,
          angle: plotAngle,
          fillColor: "transparent"
        });
        drawnItemsConst.addLayer(newLayer);

        const transform = new L.Handler.PathTransform(newLayer);
        transform
          .setOptions({
            scaling: false,
            rotation: false
          })
          .enable();

        newLayer.on("edit", function (event) {
          updateDrawnPlots();
          plotResizeEvent(event, "resized");
        });     
       
        newLayer.off("rotateend");
        newLayer.on("rotateend", onStopRotateFeature, this);

        setTransformList((prevArray) => [
          ...prevArray,
          {
            plotId: modifiedPlot[0].plotId,
            transform: transform
          }
        ]);

        if (modifiedPlot[0].plotId.toString() == "1") {
          parentLayerId = newLayer._leaflet_id;
        }
      }

      const selectedPlotsFilter = selectedPlotsConst.filter((plot) => {
        return plot.plotId.toString() == modifiedPlot[0].plotId.toString();
      });

      if (selectedPlotsFilter.length > 0) {
        setSelectedPlots((prevArray) =>
          prevArray.filter((plot) => plot.plotId.toString() !== modifiedPlot[0].plotId.toString())
        );

        setSelectedPlots((prevArray) => [
          ...prevArray,
          {
            Row: modifiedPlot[0].Row,
            Col: modifiedPlot[0].Col,
            plotId: modifiedPlot[0].plotId,
            plotCoordinates: newLayer._latlngs[0],
            leafletId: newLayer._leaflet_id,
            rotation: rotation
          }
        ]);
      }

      const filterPopup = popupLayersConst.filter((plot) => {
        return plot.leafletId.toString() == modifiedPlot[0].leafletId.toString();
      });

      if (filterPopup.length > 0) {
        filterPopup[0].popup.removeFrom(mapLayout);
        setPopupLayers((prevArray) =>
          prevArray.filter((plot) => plot.plotId.toString() !== filterPopup[0].plotId.toString())
        );
      }

      addCheckboxPopupOnPlot(
        newLayer,
        true,
        modifiedPlot[0].plotId,
        "Row" + modifiedPlot[0].Row,
        "Col" + modifiedPlot[0].Col
      );
    } else {
      e.target.unbindTooltip();
      e.target.closeTooltip();
      newLayer = e.target;   
      
      const modifiedPlot = plotDetailsConst.filter((plot) => {
        return plot.leafletId.toString() == e.target._leaflet_id.toString();
      });

      if (modifiedPlot.length > 0) {
        leafletId = modifiedPlot[0].leafletId;
        rotation = modifiedPlot[0].rotation;   
        const filterTransform = transformListConst.filter((plot) => {
          return plot.plotId.toString() === modifiedPlot[0].plotId.toString();
        });

        if (filterTransform.length > 0) {
          filterTransform[0]?.transform?.disable?.();
          filterTransform[0]?.transform?.reset();
          setTransformList((prevArray) =>
            prevArray.filter((plot) => plot.plotId.toString() !== modifiedPlot[0].plotId.toString())
          );
        }

        const transform = new L.Handler.PathTransform(newLayer);
        transform
          .setOptions({
            rotation: false,
            scaling: false
          })
          .enable();
        newLayer.off("rotateend");
        newLayer.on("rotateend", onStopRotateFeature, this);

        setTransformList((prevArray) => [
          ...prevArray,
          {
            plotId: modifiedPlot[0].plotId,
            transform: transform
          }
        ]);
      }

      const filterPopup = popupLayersConst.filter((plot) => {
        return plot.leafletId.toString() == e.target._leaflet_id.toString();
      });

      if (filterPopup.length > 0) {
        filterPopup[0].popup.removeFrom(mapLayout);
        setPopupLayers((prevArray) =>
          prevArray.filter((plot) => plot.plotId.toString() !== filterPopup[0].plotId.toString())
        );
        const selectedPlotsFilter = plotDetailsConst.filter((plot) => {
          return plot.leafletId.toString() == e.target._leaflet_id;
        });

        if (selectedPlotsFilter.length > 0) {
          addCheckboxPopupOnPlot(
            e.target,
            true,
            filterPopup[0].plotId,
            "Row" + selectedPlotsFilter[0].Row,
            "Col" + selectedPlotsFilter[0].Col
          );
        }
      }
    }
    
    const modifiedPlot = plotDetailsConst.filter((plot) => { 
      return plot.leafletId.toString() == leafletId.toString();
    });

    if (modifiedPlot.length > 0) {
      layerConst = newLayer;
      setLayerState(newLayer);
      setPlotDetails((prevArray) =>
        prevArray.filter((plot) => plot.plotId.toString() !== modifiedPlot[0].plotId.toString())
      );
      setPlotDetails((prevArray) => [
        ...prevArray,
        {
          Row: modifiedPlot[0].Row,
          Col: modifiedPlot[0].Col,
          treatmentNumber: modifiedPlot[0]?.treatmentNumber,
          plotNumber: modifiedPlot[0]?.plotNumber,
          plotId: modifiedPlot[0].plotId,
          plotCoordinates: newLayer._latlngs[0],
          rotation: rotation,
          leafletId: newLayer._leaflet_id
        }
      ]);
    }

    tooltipGenerated();    

    // start
    let latLngs = newLayer.getLatLngs()[0];

    let selectedPlotRow = 0;
    let selectedPlotCol = 0;
    let bearing = 0; 
    getAllBearing(latLngs);

    let plotResized = false;
    if (eventType.toString() != "rotated") {
      getModifiedPlotSizes(modifiedPlot[0].plotCoordinates, drawnPlotsCopy.find(x => x.plotId == modifiedPlot[0].plotId).plotCoordinates);
      if (modifiedPlotHeight != 0 && modifiedPlotWidth != 0) {
        plotResized = true;
      }
    }
    getModifiedPlotSizes(latLngs, drawnPlotsCopy.find(x => x.plotId == modifiedPlot[0].plotId).plotCoordinates);

    let distanceByManual = 0;
    //Get actaul distance between plots
    if (modifiedPlot?.length > 0 & eventType.toString() != "rotated") {
      distanceByManual = L.latLng(drawnPlotsCopy.find(x => x.plotId === modifiedPlot[0].plotId).plotCoordinates[0].lat, drawnPlotsCopy.find(x => x.plotId === modifiedPlot[0].plotId).plotCoordinates[0].lng).distanceTo(L.latLng(latLngs[0].lat, latLngs[0].lng));
      bearing = getBearing(L.latLng(drawnPlotsCopy.find(x => x.plotId == modifiedPlot[0].plotId).plotCoordinates[0].lat, drawnPlotsCopy.find(x => x.plotId == modifiedPlot[0].plotId).plotCoordinates[0].lng), latLngs[0]);
    }
    drawnPlotsTemp.find(x => x.plotId == modifiedPlot[0].plotId).plotCoordinates = [...latLngs];

    // Get the bounds of the polygon 
    let bounds = eventType.toString() == "rotated" ? boundsRotate : e.target.getBounds()

    // Get the southwest and northwest points
    let sw = bounds.getSouthWest();
    let ne = bounds.getNorthEast();

    // Calculate the midpoint
    let center = L.latLng((sw.lat + ne.lat) / 2, (sw.lng + ne.lng) / 2);

    // Calculate the destination point 5 meters in the direction of the rotated bearing
    let point = turf.point([center.lng, center.lat]);
    let options = { units: "meters" };
    let destinationPoint = turf.destination(point, 300, repeatBearingBottom, options);
    let destination = L.latLng(
      destinationPoint.geometry.coordinates[1],
      destinationPoint.geometry.coordinates[0]
    );

    // Define the path for the polyline
    let path = [
      [center.lat, center.lng],
      [destination.lat, destination.lng]
    ];

    // Create the polyline
    let polyline = L.polyline(path, { color: "yellow" });

    // Calculate the destination point 5 meters in the direction of the rotated bearing
    let pointTop = turf.point([center.lng, center.lat]);
    let destinationPointTop = turf.destination(pointTop, 300, repeatBearingRight, options);
    let destinationTop = L.latLng(
      destinationPointTop.geometry.coordinates[1],
      destinationPointTop.geometry.coordinates[0]
    );

    // Define the path for the polyline
    let pathTop = [
      [center.lat, center.lng],
      [destinationTop.lat, destinationTop.lng]
    ];

    // Create the polyline
    let polylineTop = L.polyline(pathTop, { color: "red" });

    // Convert the polygon and polyline to GeoJSON 
    let polygonGeoJSON = newLayer.toGeoJSON();
    let polylineGeoJSON = polyline.toGeoJSON();

    // Convert the polygon to a line string
    let polygonLineString = turf.polygonToLineString(polygonGeoJSON);

    // Find the intersection points
    let intersectionPoints = turf.lineIntersect(polygonLineString, polylineGeoJSON);

    // Convert the polygon and polyline to GeoJSON 
    let polygonGeoJSONTop = newLayer.toGeoJSON();
    let polylineGeoJSONTop = polylineTop.toGeoJSON();

    // Convert the polygon to a line string
    let polygonLineStringTop = turf.polygonToLineString(polygonGeoJSONTop);

    // Find the intersection points
    let intersectionPointsTop = turf.lineIntersect(polygonLineStringTop, polylineGeoJSONTop);

    let repeatDistance = (
      center.distanceTo([
        intersectionPoints.features[0].geometry.coordinates[1],
        intersectionPoints.features[0].geometry.coordinates[0]
      ]) * 2
    ).toFixed(2);
    let treatmentDistance = (
      center.distanceTo([
        intersectionPointsTop.features[0].geometry.coordinates[1],
        intersectionPointsTop.features[0].geometry.coordinates[0]
      ]) * 2
    ).toFixed(2);

    setTrmtBearing(repeatBearingRight);
    setRepBearing(repeatBearingBottom);
    setTrmtDistanceAdd(treatmentDistance);
    setRepDistanceAdd(repeatDistance);
    setDistanceByManual(distanceByManual);

    selectedPlotRow = plotDetailsConst.filter((plot) => { 
      return plot.leafletId.toString() == modifiedPlot[0].leafletId.toString();
    })[0].Row;

    selectedPlotCol = plotDetailsConst.filter((plot) => { 
      return plot.leafletId.toString() == modifiedPlot[0].leafletId.toString();
    })[0].Col;

    selectedPlotsConst = [...selectedPlotsConst].sort((a, b) => {
      if (a["Row"] > b["Row"]) {
        return 1;
      } else if (b["Row"] > a["Row"]) {
        return -1;
      } else {
        return 0;
      }
    });

    let currLatLngs = latLngs;
    let retLatLngs = null;
    let recentRotatedPlotId = 0;    

    for (let col = selectedPlotCol; col > 0; col--) {
      if (col != selectedPlotCol) {
        let filteredItem = selectedPlotsConst.filter((plot) => {
          return (
            plot.Row.toString() == selectedPlotRow.toString() &&
            plot.Col.toString() == col.toString()
          );
        });
        if (filteredItem.length > 0) {
          if (eventType.toString() == "rotated") {
            let distance = L.latLng(drawnPlotsCopy.find(x => x.Row == selectedPlotRow & x.Col == selectedPlotCol).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]));
            retLatLngs = plotCreation(
              currLatLngs,
              repeatBearingLeft,
              distance,
              filteredItem[0].plotId,
              filteredItem[0].leafletId,
              rotation || filteredItem[0].rotation
            );
            recentRotatedPlotId = filteredItem[0].plotId;
          }
          else {
            currLatLngs = plotResized ? latLngs : filteredItem[0].plotCoordinates;
            bearing = plotResized ? repeatBearingLeft : bearing;
            let resizeDistance = L.latLng(drawnPlotsCopy.find(x => x.Row == selectedPlotRow & x.Col == selectedPlotCol).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])) +
              (distanceByManual == 0 ? 0 : (modifiedPlotWidth) * (selectedPlotCol > col ? selectedPlotCol - col : col > selectedPlotCol ? col - selectedPlotCol : 1));
            let distance = plotResized ? resizeDistance : Number(distanceByManual);
            retLatLngs = plotCreation(
              currLatLngs,
              bearing,
              distance,
              filteredItem[0].plotId,
              filteredItem[0].leafletId,
              rotation || filteredItem[0].rotation
            );
            currLatLngs = retLatLngs;
          }
          drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs];
        }
      }
    }

    let currLatLngs1 = latLngs;
    let retLatLngs1 = null;

    for (let col = selectedPlotCol; col <= noOfTreatmentsConst; col++) {
      if (col != selectedPlotCol) {
        let filteredItem = selectedPlotsConst.filter((plot) => {
          return (
            plot.Row.toString() == selectedPlotRow.toString() &&
            plot.Col.toString() == col.toString()
          );
        });

        if (filteredItem.length > 0) {
          if (eventType.toString() == "rotated") {
            let distance = L.latLng(drawnPlotsCopy.find(x => x.Row == selectedPlotRow & x.Col == selectedPlotCol).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]));
            retLatLngs1 = plotCreation(
              currLatLngs1,
              repeatBearingRight,
              distance,
              filteredItem[0].plotId,
              filteredItem[0].leafletId,
              rotation || filteredItem[0].rotation
            );
            recentRotatedPlotId = filteredItem[0].plotId;
          }
          else {
            currLatLngs1 = plotResized ? latLngs : filteredItem[0].plotCoordinates;
            bearing = plotResized ? repeatBearingRight : bearing;
            let resizeDistance = L.latLng(drawnPlotsCopy.find(x => x.Row == selectedPlotRow & x.Col == selectedPlotCol).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])) +
              (distanceByManual == 0 ? 0 : (modifiedPlotWidth) * (selectedPlotCol > col ? selectedPlotCol - col : col > selectedPlotCol ? col - selectedPlotCol : 1));
            let distance = plotResized ? resizeDistance : Number(distanceByManual);
            retLatLngs1 = plotCreation(
              currLatLngs1,
              bearing,
              distance,
              filteredItem[0].plotId,
              filteredItem[0].leafletId,
              rotation || filteredItem[0].rotation
            );
            currLatLngs1 = retLatLngs1;
          }
          drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs1];
        }
      }
    }

    //Below Rows - Start
    let currLatLngs2 = latLngs;
    let retLatLngs2 = null;

    for (let row = selectedPlotRow; row > 0; row--) {
      let notFound1 = "false";
      let movedCol1 = 0;
      if (row != selectedPlotRow) {
        let filteredItem = selectedPlotsConst.filter((plot) => {
          return (
            plot.Row.toString() == row.toString() &&
            plot.Col.toString() == selectedPlotCol.toString()
          );
        });

        if (filteredItem.length > 0) {
          if (eventType.toString() == "rotated") {
            let distance = L.latLng(drawnPlotsCopy.find(x => x.Row == selectedPlotRow & x.Col == selectedPlotCol).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]));
            retLatLngs2 = plotCreation(
              currLatLngs2,
              repeatBearingTop,
              distance,
              filteredItem[0].plotId,
              filteredItem[0].leafletId,
              rotation || filteredItem[0].rotation
            );
            recentRotatedPlotId = filteredItem[0].plotId;
          }
          else {
            currLatLngs2 = plotResized ? latLngs : filteredItem[0].plotCoordinates;
            bearing = plotResized ? repeatBearingTop : bearing;
            let resizeDistance = L.latLng(drawnPlotsCopy.find(x => x.Row == selectedPlotRow & x.Col == selectedPlotCol).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])) +
              (distanceByManual == 0 ? 0 : modifiedPlotHeight * (row > selectedPlotRow ? row - selectedPlotRow : row < selectedPlotRow ? selectedPlotRow - row : 1));
            let distance = plotResized ? resizeDistance : Number(distanceByManual);
            retLatLngs2 = plotCreation(
              currLatLngs2,
              bearing,
              distance,
              filteredItem[0].plotId,
              filteredItem[0].leafletId,
              rotation || filteredItem[0].rotation
            );
            currLatLngs2 = retLatLngs2;
          }
          drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs2];
        } else {
          for (let col = selectedPlotCol; col > 0; col--) {
            if (col != selectedPlotCol) {
              let filteredItem = selectedPlotsConst.filter((plot) => {
                return (
                  plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString()
                );
              });
              if (filteredItem.length > 0) {
                if (eventType.toString() == "rotated") {                  
                  if (filteredItem[0].plotId < recentRotatedPlotId) {
                    let remainder = filteredItem[0].plotId % noOfTreatmentsConst;
                    if (remainder > 0) {
                      recentRotatedPlotId = selectedPlotsConst.find(x => x.plotId == filteredItem[0].plotId + Number(noOfTreatmentsConst)).plotId;
                    }
                  }
                  recentRotatedPlotId = recentRotatedPlotId == 0 ? selectedPlotsConst[0]?.plotId : recentRotatedPlotId;
                  let distance = L.latLng(drawnPlotsCopy.find(x => x.plotId == recentRotatedPlotId).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]));
                  currLatLngs2 = drawnPlotsTemp.find(x => x.plotId == recentRotatedPlotId).plotCoordinates;
                  retLatLngs2 = plotCreation(
                    currLatLngs2,
                    repeatBearingTop,
                    distance,
                    filteredItem[0].plotId,
                    filteredItem[0].leafletId
                  );
                  recentRotatedPlotId = filteredItem[0].plotId;
                }
                else {
                  currLatLngs2 = plotResized ? latLngs : filteredItem[0].plotCoordinates;
                  bearing = plotResized ? repeatBearingTop : bearing;
                  let plotId = 0;
                  if (plotResized) {
                    let remainder = filteredItem[0].plotId % Number(noOfTreatmentsConst);
                    if (selectedPlotsConst.some(x => x.Row == selectedPlotRow & x.Col == remainder)) {
                      plotId = selectedPlotsConst.find(x => x.Row == selectedPlotRow & x.Col == remainder).plotId;
                      currLatLngs2 = drawnPlotsTemp.find(x => x.plotId == plotId).plotCoordinates;
                    }
                    else {
                      plotId = drawnPlotsCopy.find(x => x.Row == selectedPlotRow & x.Col == selectedPlotCol).plotId;
                    }
                  }
                  let distance = plotResized ? L.latLng(drawnPlotsCopy.find(x => x.plotId == plotId).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])) : Number(distanceByManual);
                  retLatLngs2 = plotCreation(
                    currLatLngs2,
                    bearing,
                    distance,
                    filteredItem[0].plotId,
                    filteredItem[0].leafletId
                  );
                }
                drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs2];
                movedCol1 = filteredItem[0].Col;
                currLatLngs2 = retLatLngs2;
                notFound1 = "true";
                break;
              }
            }
          }

          if (notFound1.toString() === "false") {
            for (let col = selectedPlotCol; col <= noOfTreatmentsConst; col++) {
              if (col != selectedPlotCol) {
                let filteredItem = selectedPlotsConst.filter((plot) => {
                  return (
                    plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString()
                  );
                });
                if (filteredItem.length > 0) {
                  if (eventType.toString() == "rotated") {
                    if (filteredItem[0].plotId < recentRotatedPlotId) {
                      let remainder = filteredItem[0].plotId % noOfTreatmentsConst;
                      if (remainder > 0) {
                        let rowSel = selectedPlotsConst.find(x => x.plotId == recentRotatedPlotId).Row > row ? selectedPlotsConst.find(x => x.plotId == recentRotatedPlotId).Row :
                          selectedPlotsConst.find(x => x.plotId == recentRotatedPlotId).Row < row ? row : selectedPlotsConst.find(x => x.plotId == recentRotatedPlotId).Row;
                        if (Math.abs(selectedPlotsConst.find(x => x.plotId == recentRotatedPlotId).Row > row)) {
                          recentRotatedPlotId = selectedPlotsConst.some(x => x.Row == selectedPlotsConst.find(x => x.plotId == recentRotatedPlotId).Row & x.Col == remainder) ? selectedPlotsConst.find(x => x.Row == selectedPlotsConst.find(x => x.plotId == recentRotatedPlotId).Row & x.Col == remainder).plotId : recentRotatedPlotId;
                        }
                        else if (Math.abs(selectedPlotsConst.find(x => x.plotId == recentRotatedPlotId).Row - row) > 1) {
                          recentRotatedPlotId = selectedPlotsConst.some(x => x.Row == selectedPlotsConst.find(x => x.plotId == recentRotatedPlotId).Row & x.Col == remainder) ? selectedPlotsConst.find(x => x.Row == selectedPlotsConst.find(x => x.plotId == recentRotatedPlotId).Row & x.Col == remainder).plotId : recentRotatedPlotId;
                        }
                        else {
                          recentRotatedPlotId = selectedPlotsConst.some(x => x.Row == rowSel - 1 & x.Col == remainder) ? selectedPlotsConst.find(x => x.Row == rowSel - 1 & x.Col == remainder).plotId : recentRotatedPlotId;
                        }
                      }
                    }
                    recentRotatedPlotId = recentRotatedPlotId == 0 ? selectedPlotsConst[0]?.plotId : recentRotatedPlotId;
                    let distance = L.latLng(drawnPlotsCopy.find(x => x.plotId == recentRotatedPlotId).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]));
                    currLatLngs2 = drawnPlotsTemp.find(x => x.plotId == recentRotatedPlotId).plotCoordinates;
                    retLatLngs2 = plotCreation(
                      currLatLngs2,
                      repeatBearingTop,
                      distance,
                      filteredItem[0].plotId,
                      filteredItem[0].leafletId
                    );
                    recentRotatedPlotId = filteredItem[0].plotId;
                  }
                  else {
                    currLatLngs2 = plotResized ? currLatLngs2 : filteredItem[0].plotCoordinates;
                    bearing = plotResized ? repeatBearingTop : bearing;
                    let plotId = 0;
                    if (plotResized) {
                      let remainder = filteredItem[0].plotId % Number(noOfTreatmentsConst);
                      remainder = remainder == 0 & col == Number(noOfTreatmentsConst) ? col : selectedPlotCol > remainder ? selectedPlotCol : remainder;
                      if (selectedPlotsConst.some(x => x.Row == selectedPlotRow & x.Col == remainder)) {
                        plotId = selectedPlotsConst.find(x => x.Row == selectedPlotRow & x.Col == remainder).plotId;
                        currLatLngs2 = drawnPlotsTemp.find(x => x.plotId == plotId).plotCoordinates;
                      }
                      else {
                        plotId = drawnPlotsCopy.find(x => x.Row == selectedPlotRow & x.Col == selectedPlotCol).plotId;
                      }
                    }
                    let distance = plotResized ? L.latLng(drawnPlotsCopy.find(x => x.plotId == plotId).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])) : Number(distanceByManual);
                    retLatLngs2 = plotCreation(
                      currLatLngs2,
                      bearing,
                      distance,
                      filteredItem[0].plotId,
                      filteredItem[0].leafletId
                    );
                  }
                  drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs2];
                  movedCol1 = filteredItem[0].Col;
                  currLatLngs2 = retLatLngs2;
                  notFound1 = true;
                  break;
                }
              }
            }
          }
        }

        let currLatLngs = retLatLngs2;
        let retLatLngs = null;

        for (let col = selectedPlotCol; col > 0; col--) {
          if (col != selectedPlotCol && col != movedCol1) {
            let filteredItem = selectedPlotsConst.filter((plot) => {
              return plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString();
            });

            if (filteredItem.length > 0) {
              if (eventType.toString() == "rotated") {
                let distance = L.latLng(drawnPlotsCopy.find(x => x.plotId == recentRotatedPlotId).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]));
                currLatLngs = drawnPlotsTemp.find(x => x.plotId == recentRotatedPlotId).plotCoordinates;
                retLatLngs = plotCreation(
                  currLatLngs,
                  repeatBearingLeft,
                  distance,
                  filteredItem[0].plotId,
                  filteredItem[0].leafletId,
                  rotation || filteredItem[0].rotation
                );
                recentRotatedPlotId = filteredItem[0].plotId;
              }
              else {
                currLatLngs = plotResized ? retLatLngs2 : filteredItem[0].plotCoordinates;
                bearing = plotResized ? repeatBearingLeft : bearing;
                let resizeDistance = 0;
                if (movedCol1 > 0) {
                  resizeDistance = L.latLng(drawnPlotsCopy.find(x => x.Row == row & x.Col == movedCol1).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])) +
                    (distanceByManual == 0 ? 0 : (modifiedPlotWidth) * (movedCol1 > col ? movedCol1 - col : col > movedCol1 ? col - movedCol1 : 1));
                } else {
                  resizeDistance = L.latLng(drawnPlotsCopy.find(x => x.Row == row & x.Col == selectedPlotCol).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])) +
                    (distanceByManual == 0 ? 0 : (modifiedPlotWidth) * (selectedPlotCol > col ? selectedPlotCol - col : col > selectedPlotCol ? col - selectedPlotCol : 1));
                }
                let distance = plotResized ? resizeDistance : Number(distanceByManual);
                retLatLngs = plotCreation(
                  currLatLngs,
                  bearing,
                  distance,
                  filteredItem[0].plotId,
                  filteredItem[0].leafletId,
                  rotation || filteredItem[0].rotation
                );
                currLatLngs = retLatLngs;
              }
              drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs];
            }
          }
        }

        let currLatLngs1 = retLatLngs2;
        let retLatLngs1 = null;

        for (let col = selectedPlotCol; col <= noOfTreatmentsConst; col++) {
          if (col != selectedPlotCol && col != movedCol1) {
            let filteredItem = selectedPlotsConst.filter((plot) => {
              return plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString();
            });

            if (filteredItem.length > 0) {
              if (eventType.toString() == "rotated") {
                let distance = L.latLng(drawnPlotsCopy.find(x => x.plotId == recentRotatedPlotId).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]));
                currLatLngs1 = currLatLngs1 != retLatLngs1 ? drawnPlotsTemp.find(x => x.plotId == recentRotatedPlotId).plotCoordinates : currLatLngs1;
                retLatLngs1 = plotCreation(
                  currLatLngs1,
                  repeatBearingRight,
                  distance,
                  filteredItem[0].plotId,
                  filteredItem[0].leafletId,
                  rotation || filteredItem[0].rotation
                );
                recentRotatedPlotId = filteredItem[0].plotId;
              }
              else {
                currLatLngs1 = plotResized ? retLatLngs2 : filteredItem[0].plotCoordinates;
                bearing = plotResized ? repeatBearingRight : bearing;
                let resizeDistance = 0;
                if (movedCol1 > 0) {
                  resizeDistance = L.latLng(drawnPlotsCopy.find(x => x.Row == row & x.Col == movedCol1).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])) +
                    (distanceByManual == 0 ? 0 : (modifiedPlotWidth) * (movedCol1 > col ? movedCol1 - col : col > movedCol1 ? col - movedCol1 : 1));
                } else {
                  resizeDistance = L.latLng(drawnPlotsCopy.find(x => x.Row == row & x.Col == selectedPlotCol).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])) +
                    (distanceByManual == 0 ? 0 : (modifiedPlotWidth) * (selectedPlotCol > col ? selectedPlotCol - col : col > selectedPlotCol ? col - selectedPlotCol : 1));
                }
                let distance = plotResized ? resizeDistance : Number(distanceByManual);
                retLatLngs1 = plotCreation(
                  currLatLngs1,
                  bearing,
                  distance,
                  filteredItem[0].plotId,
                  filteredItem[0].leafletId,
                  rotation || filteredItem[0].rotation
                );
                currLatLngs1 = retLatLngs1;
              }
              drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs1];
            }
          }
        }
      }
    }

    //Above Rows - Start
    let currLatLngs3 = latLngs;

    let retLatLngs3 = null;

    for (let row = selectedPlotRow; row <= noOfRepsConst; row++) {
      let notFound = "false";
      let movedCol = 0;
      if (row != selectedPlotRow) {
        let filteredItem = selectedPlotsConst.filter((plot) => {
          return (
            plot.Row.toString() == row.toString() &&
            plot.Col.toString() == selectedPlotCol.toString()
          );
        });

        if (filteredItem.length > 0) {
          if (eventType.toString() == "rotated") {
            let distance = L.latLng(drawnPlotsCopy.find(x => x.Row == selectedPlotRow & x.Col == selectedPlotCol).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]));
            currLatLngs3 = currLatLngs3 != latLngs ? drawnPlotsTemp.find(x => x.Row == selectedPlotRow & x.Col == selectedPlotCol).plotCoordinates : currLatLngs3;
            retLatLngs3 = plotCreation(
              currLatLngs3,
              repeatBearingBottom,
              distance,
              filteredItem[0].plotId,
              filteredItem[0].leafletId,
              rotation || filteredItem[0].rotation
            );
            recentRotatedPlotId = filteredItem[0].plotId;
          }
          else {
            currLatLngs3 = plotResized ? latLngs : filteredItem[0].plotCoordinates;
            bearing = plotResized ? repeatBearingBottom : bearing;
            let distance = plotResized ? L.latLng(drawnPlotsCopy.find(x => x.Row == selectedPlotRow & x.Col == selectedPlotCol).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])) : Number(distanceByManual);
            retLatLngs3 = plotCreation(
              currLatLngs3,
              bearing,
              distance,
              filteredItem[0].plotId,
              filteredItem[0].leafletId,
              rotation || filteredItem[0].rotation
            );
            currLatLngs3 = retLatLngs3;
          }
          drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs3];
        } else {
          for (let col = selectedPlotCol; col > 0; col--) {
            if (col != selectedPlotCol) {
              let filteredItem = selectedPlotsConst.filter((plot) => {
                return (
                  plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString()
                );
              });
              if (filteredItem.length > 0) {
                if (eventType.toString() == "rotated") {
                  if (filteredItem[0].plotId > recentRotatedPlotId) {
                    let remainder = filteredItem[0].plotId % noOfTreatmentsConst;
                    if (remainder > 0) { recentRotatedPlotId = selectedPlotsConst.find(x => x.plotId == remainder).plotId; }
                  }
                  let distance = L.latLng(drawnPlotsCopy.find(x => x.plotId == recentRotatedPlotId).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]));
                  currLatLngs3 = drawnPlotsTemp.find(x => x.plotId == recentRotatedPlotId).plotCoordinates;
                  retLatLngs3 = plotCreation(
                    currLatLngs3,
                    repeatBearingBottom,
                    distance,
                    filteredItem[0].plotId,
                    filteredItem[0].leafletId
                  );
                  recentRotatedPlotId = filteredItem[0].plotId; 
                }
                else {
                  currLatLngs3 = plotResized ? currLatLngs3 : filteredItem[0].plotCoordinates;
                  bearing = plotResized ? repeatBearingBottom : bearing;
                  let plotId = 0;
                  if (plotResized) {
                    let remainder = filteredItem[0].plotId % Number(noOfTreatmentsConst);

                    if (selectedPlotsConst.some(x => x.Row == selectedPlotRow & x.Col == remainder)) {
                      plotId = selectedPlotsConst.find(x => x.Row == selectedPlotRow & x.Col == remainder).plotId;
                      currLatLngs3 = drawnPlotsTemp.find(x => x.plotId == plotId).plotCoordinates;
                    }
                    else {
                      plotId = drawnPlotsCopy.find(x => x.Row == selectedPlotRow & x.Col == selectedPlotCol).plotId;
                    }
                  }
                  let distance = plotResized ? L.latLng(drawnPlotsCopy.find(x => x.plotId == plotId).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])) : Number(distanceByManual);
                  retLatLngs3 = plotCreation(
                    currLatLngs3,
                    bearing,
                    distance,
                    filteredItem[0].plotId,
                    filteredItem[0].leafletId
                  );
                }
                drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs3];
                movedCol = filteredItem[0].Col;
                currLatLngs3 = retLatLngs3;
                notFound = "true";
                break;
              }
            }
          }

          if (notFound.toString() === "false") {
            for (let col = selectedPlotCol; col <= noOfTreatmentsConst; col++) {
              if (col != selectedPlotCol) {
                let filteredItem = selectedPlotsConst.filter((plot) => {
                  return (
                    plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString()
                  );
                });
                if (filteredItem.length > 0) {
                  if (eventType.toString() == "rotated") {
                    if (filteredItem[0].plotId > recentRotatedPlotId) {
                      let remainder = filteredItem[0].plotId % noOfTreatmentsConst;
                      if (remainder > 0) { recentRotatedPlotId = selectedPlotsConst.find(x => x.plotId == remainder).plotId; }                      
                    }
                    recentRotatedPlotId = recentRotatedPlotId == 0 ? selectedPlotsConst[0]?.plotId : recentRotatedPlotId;
                    let distance = L.latLng(drawnPlotsCopy.find(x => x.plotId == recentRotatedPlotId).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]));
                    currLatLngs3 = drawnPlotsTemp.find(x => x.plotId == recentRotatedPlotId).plotCoordinates;
                    retLatLngs3 = plotCreation(
                      currLatLngs3,
                      repeatBearingBottom,
                      distance,
                      filteredItem[0].plotId,
                      filteredItem[0].leafletId
                    );
                    recentRotatedPlotId = filteredItem[0].plotId;
                  }
                  else {
                    currLatLngs3 = plotResized ? currLatLngs3 : filteredItem[0].plotCoordinates;
                    bearing = plotResized ? repeatBearingBottom : bearing;
                    let plotId = 0;
                    if (plotResized) {
                      let remainder = filteredItem[0].plotId % Number(noOfTreatmentsConst);             
                      remainder = remainder == 0 & col == Number(noOfTreatmentsConst) ? col : remainder;
                      if (selectedPlotsConst.some(x => x.Row == selectedPlotRow & x.Col == remainder)) {
                        plotId = selectedPlotsConst.find(x => x.Row == selectedPlotRow & x.Col == remainder).plotId;
                        currLatLngs3 = drawnPlotsTemp.find(x => x.plotId == plotId).plotCoordinates;
                      }
                      else {
                        plotId = drawnPlotsCopy.find(x => x.Row == selectedPlotRow & x.Col == selectedPlotCol).plotId;
                      }
                    }
                    let distance = plotResized ? L.latLng(drawnPlotsCopy.find(x => x.plotId == plotId).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])) : Number(distanceByManual);
                    retLatLngs3 = plotCreation(
                      currLatLngs3,
                      bearing,
                      distance,
                      filteredItem[0].plotId,
                      filteredItem[0].leafletId
                    );
                  }
                  drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs3];
                  movedCol = filteredItem[0].Col;
                  currLatLngs3 = retLatLngs3;
                  notFound = true;
                  break;
                }
              }
            }
          }
        }
        let currLatLngs = retLatLngs3;
        let retLatLngs = null;

        for (let col = selectedPlotCol; col > 0; col--) {
          if (col != selectedPlotCol && col != movedCol) {
            let filteredItem = selectedPlotsConst.filter((plot) => {
              return plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString();
            });

            if (filteredItem.length > 0) {
              if (eventType.toString() == "rotated") {
                let distance = L.latLng(drawnPlotsCopy.find(x => x.plotId == recentRotatedPlotId).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]));
                retLatLngs = plotCreation(
                  currLatLngs,
                  repeatBearingLeft,
                  distance,
                  filteredItem[0].plotId,
                  filteredItem[0].leafletId
                );
                recentRotatedPlotId = filteredItem[0].plotId;
              }
              else {
                currLatLngs = plotResized ? retLatLngs3 : filteredItem[0].plotCoordinates;
                bearing = plotResized ? repeatBearingLeft : bearing;
                let resizeDistance = 0;
                if (movedCol > 0) {
                  resizeDistance = L.latLng(drawnPlotsCopy.find(x => x.Row == row & x.Col == movedCol).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])) +
                    (distanceByManual == 0 ? 0 : (modifiedPlotWidth) * (movedCol > col ? movedCol - col : col > movedCol ? col - movedCol : 1));
                } else {
                  resizeDistance = L.latLng(drawnPlotsCopy.find(x => x.Row == row & x.Col == selectedPlotCol).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])) +
                    (distanceByManual == 0 ? 0 : (modifiedPlotWidth) * (selectedPlotCol > col ? selectedPlotCol - col : col > selectedPlotCol ? col - selectedPlotCol : 1));
                }
                let distance = plotResized ? resizeDistance : Number(distanceByManual);
                retLatLngs = plotCreation(
                  currLatLngs,
                  bearing,
                  distance,
                  filteredItem[0].plotId,
                  filteredItem[0].leafletId
                );
              }
              drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs];
              currLatLngs = retLatLngs;
            }
          }
        }

        let currLatLngs1 = retLatLngs3;
        let retLatLngs1 = null;

        for (let col = selectedPlotCol; col <= noOfTreatmentsConst; col++) {
          if (col != selectedPlotCol && col != movedCol) {
            let filteredItem = selectedPlotsConst.filter((plot) => {
              return plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString();
            });

            if (filteredItem.length > 0) {
              if (eventType.toString() == "rotated") {
                let distance = L.latLng(drawnPlotsCopy.find(x => x.plotId == recentRotatedPlotId).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]));
                currLatLngs1 = currLatLngs1 != retLatLngs1 ? drawnPlotsTemp.find(x => x.plotId == recentRotatedPlotId).plotCoordinates : currLatLngs1;
                retLatLngs1 = plotCreation(
                  currLatLngs1,
                  repeatBearingRight,
                  distance,
                  filteredItem[0].plotId,
                  filteredItem[0].leafletId,
                  rotation || filteredItem[0].rotation
                );
                recentRotatedPlotId = filteredItem[0].plotId;                
              }
              else {
                currLatLngs1 = plotResized ? retLatLngs3 : filteredItem[0].plotCoordinates;
                bearing = plotResized ? repeatBearingRight : bearing;
                let resizeDistance = 0;
                if (movedCol > 0) {
                  resizeDistance = L.latLng(drawnPlotsCopy.find(x => x.Row == row & x.Col == movedCol).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])) +
                    (distanceByManual == 0 ? 0 : (modifiedPlotWidth) * (movedCol > col ? movedCol - col : col > movedCol ? col - movedCol : 1));
                } else {
                  resizeDistance = L.latLng(drawnPlotsCopy.find(x => x.Row == row & x.Col == selectedPlotCol).plotCoordinates[0]).distanceTo(L.latLng(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])) +
                    (distanceByManual == 0 ? 0 : (modifiedPlotWidth) * (selectedPlotCol > col ? selectedPlotCol - col : col > selectedPlotCol ? col - selectedPlotCol : 1));
                }
                let distance = plotResized ? resizeDistance : Number(distanceByManual);
                retLatLngs1 = plotCreation(
                  currLatLngs1,
                  bearing,
                  distance,
                  filteredItem[0].plotId,
                  filteredItem[0].leafletId,
                  rotation || filteredItem[0].rotation
                );
                currLatLngs1 = retLatLngs1;
              }
              drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs1];
            }
          }
        }
      }
    }
    // end
    //Above Rows - End
    drawnPlotsCopy = JSON.parse(JSON.stringify(drawnPlotsTemp));
    drawnPlotsCopy.map(item => {
      const exists = selectedPlotsConst.find(x => x.plotId == item.plotId);
      if (exists) {
        item.rotation = rotation;
        item.plotCoordinates = [...item.plotCoordinates.map(x => L.latLng(x))];
      }
    });    
    updateDrawnPlots();
    setDisableUndo(false);
    checkBoxes = document.querySelectorAll(".toggleSwitch");
    checkBoxes.forEach((checkBox) => {
      checkBox.removeEventListener("change", (e) => {
        selectPlots(e);
      });
    });

    checkBoxes.forEach((checkBox) => {
      checkBox.addEventListener("change", (e) => {
        selectPlots(e);
      });
    });    
  };  

  // on click off apply button this function is called to add distance between selcted plots only
  const applyManualDistance = () => { 
    let recentlyModifiedPlot = 0;
    manualDistanceApplied = true;
    let baseLayerFromSelectedPlots;
    drawnItemsConst.eachLayer((layer) => {
      if (layer._leaflet_id === selectedPlots[0]?.leafletId) {
        baseLayerFromSelectedPlots = layer;
      }
    });
    const newLayer = baseLayerFromSelectedPlots;
    // start
    let latLngs = newLayer.getLatLngs()[0];
    getAllBearing(latLngs);

    let selectedPlotRow = 0;
    let selectedPlotCol = 0;

    selectedPlotRow = plotDetailsConst.filter((plot) => {
      return plot.leafletId.toString() == newLayer._leaflet_id.toString();
    })[0].Row;

    selectedPlotCol = plotDetailsConst.filter((plot) => {
      return plot.leafletId.toString() == newLayer._leaflet_id.toString();
    })[0].Col;

    selectedPlotsConst = [...selectedPlotsConst].sort((a, b) => {
      if (a["Row"] > b["Row"]) {
        return 1;
      } else if (b["Row"] > a["Row"]) {
        return -1;
      } else {
        return 0;
      }
    });

    let currLatLngs = latLngs;
    let retLatLngs = null;
    drawnPlotsTemp.find(x => x.Col == selectedPlotCol & x.Row == selectedPlotRow).plotCoordinates = [...latLngs];

    for (let col = selectedPlotCol; col > 0; col--) {
      if (col != selectedPlotCol) {
        let filteredItem = selectedPlotsConst.filter((plot) => {
          return (
            plot.Row.toString() == selectedPlotRow.toString() &&
            plot.Col.toString() == col.toString()
          );
        });

        if (filteredItem.length > 0) {
          retLatLngs = plotCreation(
            currLatLngs,
            repeatBearingLeft,
            Number(xDistance) + Number(trmtDistanceAdd),
            filteredItem[0].plotId,
            filteredItem[0].leafletId,
            selectedPlots[0]?.rotation || filteredItem[0].rotation
          );
          currLatLngs = retLatLngs;
          drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs];
          recentlyModifiedPlot =  filteredItem[0].plotId;
        }
      }
    }

    let currLatLngs1 = latLngs;
    let retLatLngs1 = null;

    for (let col = selectedPlotCol; col <= noOfTreatmentsConst; col++) {
      if (col != selectedPlotCol) {
        let filteredItem = selectedPlotsConst.filter((plot) => {
          return (
            plot.Row.toString() == selectedPlotRow.toString() &&
            plot.Col.toString() == col.toString()
          );
        });

        if (filteredItem.length > 0) {
          retLatLngs1 = plotCreation(
            currLatLngs1,
            repeatBearingRight,
            Number(xDistance) + Number(trmtDistanceAdd),
            filteredItem[0].plotId,
            filteredItem[0].leafletId,
            selectedPlots[0]?.rotation || filteredItem[0].rotation
          );
          currLatLngs1 = retLatLngs1;
          drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs1];
          recentlyModifiedPlot =  filteredItem[0].plotId;
        }
      }
    }

    //Below Rows - Start
    let currLatLngs2 = latLngs;
    let retLatLngs2 = null;

    for (let row = selectedPlotRow; row > 0; row--) {
      let notFound1 = "false";
      let movedCol1 = 0;
      if (row != selectedPlotRow) {
        let filteredItem = selectedPlotsConst.filter((plot) => {
          return (
            plot.Row.toString() == row.toString() &&
            plot.Col.toString() == selectedPlotCol.toString()
          );
        });

        if (filteredItem.length > 0) {
          retLatLngs2 = plotCreation(
            currLatLngs2,
            repeatBearingTop,
            Number(yDistance) + Number(repDistanceAdd),
            filteredItem[0].plotId,
            filteredItem[0].leafletId,
            selectedPlots[0]?.rotation || filteredItem[0].rotation
          );
          currLatLngs2 = retLatLngs2;
          drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs2];
          recentlyModifiedPlot =  filteredItem[0].plotId;
        } else {
          for (let col = selectedPlotCol; col > 0; col--) {
            if (col != selectedPlotCol) {
              let filteredItem = selectedPlotsConst.filter((plot) => {
                return (
                  plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString()
                );
              });              
              if (filteredItem.length > 0) {
                let plotId = 0;
                let remainder = filteredItem[0].plotId % Number(noOfTreatmentsConst);
                remainder = remainder == 0 & col == Number(noOfTreatmentsConst) ? col : remainder;
                let baseRow = drawnPlotsTemp.find(x => x.plotId == recentlyModifiedPlot).Row;
                baseRow = baseRow < row ? row : baseRow > row ? baseRow : row;
                if (selectedPlotsConst.some(x => x.Row == baseRow & x.Col == remainder)) {
                  plotId = selectedPlotsConst.find(x => x.Row == baseRow & x.Col == remainder).plotId;
                  currLatLngs2 = drawnPlotsTemp.find(x => x.plotId == plotId).plotCoordinates;
                } 
                retLatLngs2 = plotCreation(
                  currLatLngs2,
                  repeatBearingTop,
                  Number(yDistance) + Number(repDistanceAdd),
                  filteredItem[0].plotId,
                  filteredItem[0].leafletId
                );
                movedCol1 = filteredItem[0].Col;
                currLatLngs2 = retLatLngs2;
                drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs2];                
                notFound1 = "true";
                recentlyModifiedPlot = filteredItem[0].plotId;
                break;
              }
            }
          }

          if (notFound1.toString() === "false") {
            for (let col = selectedPlotCol; col <= noOfTreatmentsConst; col++) {
              if (col != selectedPlotCol) {
                let filteredItem = selectedPlotsConst.filter((plot) => {
                  return (
                    plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString()
                  );
                });                    
                if (filteredItem.length > 0) {
                  let plotId = 0;
                  let remainder = filteredItem[0].plotId % Number(noOfTreatmentsConst);
                  remainder = remainder == 0 & col == Number(noOfTreatmentsConst) ? col : remainder;
                  let baseRow = drawnPlotsTemp.find(x => x.plotId == recentlyModifiedPlot).Row;
                  baseRow = baseRow < row ? row : baseRow > row ? baseRow : row;
                  if (selectedPlotsConst.some(x => x.Row == baseRow & x.Col == remainder)) {
                    plotId = selectedPlotsConst.find(x => x.Row == baseRow & x.Col == remainder).plotId;
                    currLatLngs2 = drawnPlotsTemp.find(x => x.plotId == plotId).plotCoordinates;
                  }
                  retLatLngs2 = plotCreation(
                    currLatLngs2,
                    repeatBearingTop,
                    Number(yDistance) + Number(repDistanceAdd),
                    filteredItem[0].plotId,
                    filteredItem[0].leafletId
                  );
                  movedCol1 = filteredItem[0].Col;
                  currLatLngs2 = retLatLngs2;
                  drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs2];                   
                  notFound1 = true;
                  recentlyModifiedPlot = filteredItem[0].plotId;
                  break;
                }
              }
            }
          }
        }

        let currLatLngs = retLatLngs2;
        let retLatLngs = null;

        for (let col = selectedPlotCol; col > 0; col--) {
          if (col != selectedPlotCol && col != movedCol1) {
            let filteredItem = selectedPlotsConst.filter((plot) => {
              return plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString();
            });

            if (filteredItem.length > 0) {
              retLatLngs = plotCreation(
                currLatLngs,
                repeatBearingLeft,
                Number(xDistance) + Number(trmtDistanceAdd),
                filteredItem[0].plotId,
                filteredItem[0].leafletId,
                selectedPlots[0]?.rotation || filteredItem[0].rotation
              );
              currLatLngs = retLatLngs;
              drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs];
              recentlyModifiedPlot =  filteredItem[0].plotId;
            }
          }
        }

        let currLatLngs1 = retLatLngs2;
        let retLatLngs1 = null;

        for (let col = selectedPlotCol; col <= noOfTreatmentsConst; col++) {
          if (col != selectedPlotCol && col != movedCol1) {
            let filteredItem = selectedPlotsConst.filter((plot) => {
              return plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString();
            });

            if (filteredItem.length > 0) {
              retLatLngs1 = plotCreation(
                currLatLngs1,
                repeatBearingRight,
                Number(xDistance) + Number(trmtDistanceAdd),
                filteredItem[0].plotId,
                filteredItem[0].leafletId,
                selectedPlots[0]?.rotation || filteredItem[0].rotation
              );
              currLatLngs1 = retLatLngs1;
              drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs1];
              recentlyModifiedPlot =  filteredItem[0].plotId;
            }
          }
        }
      }
    }

    //Above Rows - Start
    let currLatLngs3 = latLngs;

    let retLatLngs3 = null;

    for (let row = selectedPlotRow; row <= noOfRepsConst; row++) {
      let notFound = "false";
      let movedCol = 0;
      if (row != selectedPlotRow) {
        let filteredItem = selectedPlotsConst.filter((plot) => {
          return (
            plot.Row.toString() == row.toString() &&
            plot.Col.toString() == selectedPlotCol.toString()
          );
        });

        if (filteredItem.length > 0) {
          retLatLngs3 = plotCreation(
            currLatLngs3,
            repeatBearingBottom,
            Number(yDistance) + Number(repDistanceAdd),
            filteredItem[0].plotId,
            filteredItem[0].leafletId,
            selectedPlots[0]?.rotation || filteredItem[0].rotation
          );
          currLatLngs3 = retLatLngs3;
          drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs3];
          recentlyModifiedPlot =  filteredItem[0].plotId;
        } else {
          for (let col = selectedPlotCol; col > 0; col--) {
            if (col != selectedPlotCol) {
              let filteredItem = selectedPlotsConst.filter((plot) => {
                return (
                  plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString()
                );
              });
              if (filteredItem.length > 0) {
                let plotId = 0;
                let remainder = filteredItem[0].plotId % Number(noOfTreatmentsConst);
                remainder = remainder == 0 & col == Number(noOfTreatmentsConst) ? col : remainder;
                let baseRow = drawnPlotsTemp.find(x => x.plotId == recentlyModifiedPlot).Row;
                baseRow = baseRow > row ? row : baseRow < row ? baseRow : row;
                if (selectedPlotsConst.some(x => x.Row == baseRow & x.Col == remainder)) {
                  plotId = selectedPlotsConst.find(x => x.Row == baseRow & x.Col == remainder).plotId;
                  currLatLngs3 = drawnPlotsTemp.find(x => x.plotId == plotId).plotCoordinates;
                }
                retLatLngs3 = plotCreation(
                  currLatLngs3,
                  repeatBearingBottom,
                  Number(yDistance) + Number(repDistanceAdd),
                  filteredItem[0].plotId,
                  filteredItem[0].leafletId
                );
                movedCol = filteredItem[0].Col;
                currLatLngs3 = retLatLngs3;
                drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs3];               
                notFound = "true";
                recentlyModifiedPlot =  filteredItem[0].plotId;
                break;
              }
            }
          }

          if (notFound.toString() === "false") {
            for (let col = selectedPlotCol; col <= noOfTreatmentsConst; col++) {
              if (col != selectedPlotCol) {
                let filteredItem = selectedPlotsConst.filter((plot) => {
                  return (
                    plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString()
                  );
                });
                if (filteredItem.length > 0) {
                  let plotId = 0;
                  let remainder = filteredItem[0].plotId % Number(noOfTreatmentsConst);
                  remainder = remainder == 0 & col == Number(noOfTreatmentsConst) ? col : remainder;
                  let baseRow = drawnPlotsTemp.find(x => x.plotId == recentlyModifiedPlot).Row;
                  baseRow = baseRow > row ? row : baseRow < row ? baseRow : row;
                  if (selectedPlotsConst.some(x => x.Row == baseRow & x.Col == remainder)) {
                    plotId = selectedPlotsConst.find(x => x.Row == baseRow & x.Col == remainder).plotId;
                    currLatLngs3 = drawnPlotsTemp.find(x => x.plotId == plotId).plotCoordinates;
                  }
                  retLatLngs3 = plotCreation(
                    currLatLngs3,
                    repeatBearingBottom,
                    Number(yDistance) + Number(repDistanceAdd),
                    filteredItem[0].plotId,
                    filteredItem[0].leafletId
                  );
                  movedCol = filteredItem[0].Col;
                  currLatLngs3 = retLatLngs3;
                  drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs3];
                  notFound = true;
                  recentlyModifiedPlot = filteredItem[0].plotId;
                  break;
                }
              }
            }
          }
        }
        let currLatLngs = retLatLngs3;

        let retLatLngs = null;

        for (let col = selectedPlotCol; col > 0; col--) {
          if (col != selectedPlotCol && col != movedCol) {
            let filteredItem = selectedPlotsConst.filter((plot) => {
              return plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString();
            });

            if (filteredItem.length > 0) {
              retLatLngs = plotCreation(
                currLatLngs,
                repeatBearingLeft,
                Number(xDistance) + Number(trmtDistanceAdd),
                filteredItem[0].plotId,
                filteredItem[0].leafletId
              );
              currLatLngs = retLatLngs;
              drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs];
              recentlyModifiedPlot =  filteredItem[0].plotId;
            }
          }
        }

        let currLatLngs1 = retLatLngs3;
        let retLatLngs1 = null;

        for (let col = selectedPlotCol; col <= noOfTreatmentsConst; col++) {
          if (col != selectedPlotCol && col != movedCol) {
            let filteredItem = selectedPlotsConst.filter((plot) => {
              return plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString();
            });

            if (filteredItem.length > 0) {
              retLatLngs1 = plotCreation(
                currLatLngs1,
                repeatBearingRight,
                Number(xDistance) + Number(trmtDistanceAdd),
                filteredItem[0].plotId,
                filteredItem[0].leafletId,
                selectedPlots[0]?.rotation || filteredItem[0].rotation
              );
              currLatLngs1 = retLatLngs1;
              drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs1];
              recentlyModifiedPlot =  filteredItem[0].plotId;
            }
          }
        }
      }
    }
    // end
    drawnPlotsCopy = JSON.parse(JSON.stringify(drawnPlotsTemp));
    updateDrawnPlots();
  };

  // on click of undo button, this function is called to get back the previous state between the selcted plots only
  const undoPlots = () => {
    let totalPlots = plotNoConst == 0 ? 1 : noOfRepsConst * noOfTreatmentsConst;
    let totalModifiedItems = drawnPlotsEdit?.length - totalPlots;
    if (plotNoConst == 0) {
      editCurrentIndexConst = editCurrentIndexConst <= 0 ? totalModifiedItems - totalPlots : editCurrentIndexConst - totalPlots;
    } else {
      editCurrentIndexConst = editCurrentIndexConst == 0 ? totalModifiedItems - totalPlots : editCurrentIndexConst - totalPlots;
    }
    if (totalModifiedItems >= totalPlots & editCurrentIndexConst >= 0 & disableUndo == false) {
      setDisableRedo(false);
      var index = editCurrentIndexConst;
      clearAllDrawnLayers();
      transformListConst?.forEach((item) => {
        item.transform?.removeHooks();
      });
      transformListConst = [];
      mapLayout.eachLayer((layer) => {
        if (layer instanceof L.Transformation) {
          mapLayout.removeLayer(layer);
        }
      });
      setTransformList([]);
      setPopupLayers([]);
      mapLayout.eachLayer((layer) => {
        if (layer instanceof L.Popup) {
          mapLayout.removeLayer(layer);
        }
      });

      let undoItems = drawnPlotsEdit.slice(index, index + totalPlots);
      let sortedDrawnPlots = [...undoItems]?.sort((a, b) => {
        if (a["Col"] > b["Col"]) {
          return 1;
        } else if (b["Col"] > a["Col"]) {
          return -1;
        } else {
          return 0;
        }
      });

      // Sort the selected plots by column
      sortedDrawnPlots = [...sortedDrawnPlots]?.sort((a, b) => {
        if (a["Row"] > b["Row"]) {
          return 1;
        } else if (b["Row"] > a["Row"]) {
          return -1;
        } else {
          return 0;
        }
      });
      var selectedPlots = [...selectedPlotsConst];
      setSelectedPlots([]);
      sortedDrawnPlots.forEach((plot) => {
        let polygon = null;
        polygon = L.polygon(plot.plotCoordinates, { fillColor: "transparent" });

        polygon.addTo(drawnItemsConst);

        polygon.on("edit", function (event) {
          updateDrawnPlots();
          plotResizeEvent(event, false);
        });

        const coordinates = polygon.getLatLngs()[0];
        const rotation = polygon.rotation || plot.rotation || 0;
        const leafletId = polygon._leaflet_id;

        setPlotDetails((prevArray) => {
          const updatePlotDetails = [
            ...prevArray,
            {
              plotId: prevArray.length + 1,
              plotCoordinates: coordinates,
              leafletId: leafletId,
              rotation,
              Row: plot.Row,
              Col: plot.Col,
              treatmentNumber: plot.treatmentNumber,
              plotNumber: plot.plotNumber
            }
          ];

          addCheckboxPopupOnPlot(
            polygon,
            selectedPlots.filter(x => x.Row == plot.Row && x.Col == plot.Col).length > 0 ? true : false,
            prevArray.length + 1,
            "Row" + plot.Row,
            "Col" + plot.Col
          );
          return updatePlotDetails;
        });
       
        if (selectedPlots.filter(x => x.Row == plot.Row && x.Col == plot.Col).length > 0) {
          setSelectedPlots((prevArray) => [
            ...prevArray,
            {
              Row: plot.Row,
              Col: plot.Col,
              plotId: plot.plotId,
              plotCoordinates: coordinates,
              leafletId: leafletId
            }
          ]);
        }     
      });  
      setIsSelectPlot(true);
      setTimeout(() => {
        checkBoxes = document.querySelectorAll(".toggleSwitch");
        checkBoxes.forEach((checkBox) => {
          checkBox.removeEventListener("change", (e) => {
            selectPlots(e);
          });
        });
        checkBoxes.forEach((checkBox) => {
          checkBox.addEventListener("change", (e) => {
            selectPlots(e);
          });
        });
      }, 500);

      drawnPlotsCopy = [...undoItems];
      let rotationAngle = sortedDrawnPlots.some(x => x.rotation != 0) ? (sortedDrawnPlots.find(x => x.rotation != 0).rotation * 180 / Math.PI).toFixed(0) : 0;
      if (rotationAngle != undefined) { 
         document.getElementById("inputAngle").value = getProperAngle(rotationAngle);
         setAngleValue(rotationAngle);
      }
      setEditCurrentIndex(index == 0 ? -1 : index);
      if (index == 0) {
        setDisableUndo(true);
        setDisableRedo(false);
      } else {
        setDisableUndo(false);
        setDisableRedo(false);
      }
      setTimeout(() => {
        selectedCheckBoxesEdit();
      }, 100);   
    }
  } 

  // On click of redo button, this function is called to get back the forward state between the selcted plots only
  const redoPlots = () => {
    let totalPlots = plotNoConst == 0 ? 1 : noOfRepsConst * noOfTreatmentsConst;   
    let totalModifiedItems = drawnPlotsEdit?.length;
    if (plotNoConst == 0) {
      editCurrentIndexConst = editCurrentIndexConst > 0 ? editCurrentIndexConst : 0;
    } else {
      editCurrentIndexConst = editCurrentIndexConst == -1 ? 0 : editCurrentIndexConst;
    }
    if (totalModifiedItems >= totalPlots & editCurrentIndexConst >= 0 & disableRedo == false) {      
      var index = totalPlots + editCurrentIndexConst;
      clearAllDrawnLayers();
      transformListConst?.forEach((item) => {
        item.transform?.removeHooks();
      });
      transformListConst = [];
      mapLayout.eachLayer((layer) => {
        if (layer instanceof L.Transformation) {
          mapLayout.removeLayer(layer);
        }
      });
      setTransformList([]);
      setPopupLayers([]);
      mapLayout.eachLayer((layer) => {
        if (layer instanceof L.Popup) {
          mapLayout.removeLayer(layer);
        }
      });

      let redoItems = drawnPlotsEdit.slice(index, index + totalPlots);
      let sortedDrawnPlots = [...redoItems]?.sort((a, b) => {
        if (a["Col"] > b["Col"]) {
          return 1;
        } else if (b["Col"] > a["Col"]) {
          return -1;
        } else {
          return 0;
        }
      });

      // Sort the selected plots by column
      sortedDrawnPlots = [...sortedDrawnPlots]?.sort((a, b) => {
        if (a["Row"] > b["Row"]) {
          return 1;
        } else if (b["Row"] > a["Row"]) {
          return -1;
        } else {
          return 0;
        }
      });

      var selectedPlots = [...selectedPlotsConst];
      setSelectedPlots([]);
      sortedDrawnPlots.forEach((plot) => {
        let polygon = null;
        polygon = L.polygon(plot.plotCoordinates, { fillColor: "transparent" });

        polygon.addTo(drawnItemsConst);

        polygon.on("edit", function (event) {
          updateDrawnPlots();
          plotResizeEvent(event, false);
        });

        const coordinates = polygon.getLatLngs()[0];
        const rotation = polygon.rotation || plot.rotation || 0;
        const leafletId = polygon._leaflet_id;

        setPlotDetails((prevArray) => {
          const updatePlotDetails = [
            ...prevArray,
            {
              plotId: prevArray.length + 1,
              plotCoordinates: coordinates,
              leafletId: leafletId,
              rotation,
              Row: plot.Row,
              Col: plot.Col,
              treatmentNumber: plot.treatmentNumber,
              plotNumber: plot.plotNumber
            }
          ];

          addCheckboxPopupOnPlot(
            polygon,
            selectedPlots.filter(x => x.Row == plot.Row && x.Col == plot.Col).length > 0 ? true : false,
            prevArray.length + 1,
            "Row" + plot.Row,
            "Col" + plot.Col
          );
          return updatePlotDetails;
        });    
        
        if (selectedPlots.filter(x => x.Row == plot.Row && x.Col == plot.Col).length > 0) {
          setSelectedPlots((prevArray) => [
            ...prevArray,
            {
              Row: plot.Row,
              Col: plot.Col,
              plotId: plot.plotId,
              plotCoordinates: coordinates,
              leafletId: leafletId
            }
          ]);
        }
      });

      setIsSelectPlot(true);
      setTimeout(() => {
        checkBoxes = document.querySelectorAll(".toggleSwitch");
        checkBoxes.forEach((checkBox) => {
          checkBox.removeEventListener("change", (e) => {
            selectPlots(e);
          });
        });
        checkBoxes.forEach((checkBox) => {
          checkBox.addEventListener("change", (e) => {
            selectPlots(e);
          });
        });
      }, 500);

      drawnPlotsCopy = [...redoItems];
      let rotationAngle = sortedDrawnPlots.some(x => x.rotation != 0) ? (sortedDrawnPlots.find(x => x.rotation != 0).rotation * 180 / Math.PI).toFixed(0) : 0;
      if (rotationAngle != undefined) { 
         document.getElementById("inputAngle").value = getProperAngle(rotationAngle);
         setAngleValue(rotationAngle);
      }
      setEditCurrentIndex(index == totalModifiedItems - totalPlots ? 0 : index);
      if (index == totalModifiedItems - totalPlots) {
        setDisableUndo(false);
        setDisableRedo(true);   
      }else {
        setDisableUndo(false);
        setDisableRedo(false);
      }
      setTimeout(() => {
        selectedCheckBoxesEdit();
      }, 100);
    }
  }

  // on click off plots apply button this function is called to change manual plot length and width for selcted plots only
  const applyManualDimension = () => {
    // Sort the selected plots by row
    let allSelectedPlots = [...selectedPlotsConst].sort((a, b) => {
      if (a["Row"] > b["Row"]) {
        return 1;
      } else if (b["Row"] > a["Row"]) {
        return -1;
      } else {
        return 0;
      }
    });

    // Sort the selected plots by column
    allSelectedPlots = [...allSelectedPlots].sort((a, b) => {
      if (a["Col"] > b["Col"]) {
        return 1;
      } else if (b["Col"] > a["Col"]) {
        return -1;
      } else {
        return 0;
      }
    });

    // Get the first selected plot
    const selectedPlot = allSelectedPlots[0];
    // Find the base layer from the selected plots
    let baseLayerFromSelectedPlots;
    drawnItemsConst.eachLayer((layer) => {
      if (layer._leaflet_id === selectedPlot?.leafletId) {
        baseLayerFromSelectedPlots = layer;
      }
    });

    // Set the new layer as the base layer
    let newLayer = baseLayerFromSelectedPlots;

    // Get the center point of the new layer
    let center = newLayer.getLatLngs()[0][0];

    // Calculate the points for the manual dimensions
    let point22 = L.GeometryUtil.destination(center, repBearingConst, manualPlotLengthConst);
    let point23 = L.GeometryUtil.destination(center, trmtBearingConst, manualPlotWidthConst);
    let point24 = L.GeometryUtil.destination(point23, repBearingConst, manualPlotLengthConst);

    // Define the latlngs for the new layer
    let latlngs = [center, point22, point24, point23];

    // Create the new layer with the updated latlngs
    newLayer = plotCreation(
      latlngs,
      repeatBearingLeft,
      0,
      selectedPlot?.plotId,
      selectedPlot?.leafletId,
      selectedPlot?.rotation,
      true
    );

    // Get the updated latlngs from the new layer
    let retLatLngs = newLayer.getLatLngs()[0];
    let latLngs = retLatLngs;

    // Find the maximum X and minimum Y values
    let maxX = Infinity;
    let minY = Infinity;
    for (let i = 0; i <= latLngs.length - 1; i++) {
      minY = Math.min(minY, latLngs[i].lat);
    }

    // Find the maximum X value for the minimum Y
    for (let i = 0; i <= latLngs.length - 1; i++) {
      if (minY == latLngs[i].lat) {
        maxX = Math.min(maxX, latLngs[i].lng);
      }
    }

    // Find the index of the minimum X and minimum Y point
    let minIndex = 0;
    for (let i = 0; i <= latLngs.length - 1; i++) {
      if (maxX == latLngs[i].lng && minY == latLngs[i].lat) {
        minIndex = i;
      }
    }

    // Calculate the repeat bearings based on the minimum index
    if (minIndex === 0) {
      repeatBearingRight = calcAngle(
        newLayer.getLatLngs()[0][1],
        newLayer.getLatLngs()[0][2],
        "outbound"
      );
      repeatBearingLeft = calcAngle(
        newLayer.getLatLngs()[0][1],
        newLayer.getLatLngs()[0][2],
        "inbound"
      );
      repeatBearingBottom = calcAngle(
        newLayer.getLatLngs()[0][0],
        newLayer.getLatLngs()[0][1],
        "outbound"
      );
      repeatBearingTop = calcAngle(
        newLayer.getLatLngs()[0][0],
        newLayer.getLatLngs()[0][1],
        "inbound"
      );
    } else {
      repeatBearingRight = calcAngle(
        newLayer.getLatLngs()[0][newLayer.getLatLngs()[0].length - 1],
        newLayer.getLatLngs()[0][0],
        "inbound"
      );
      repeatBearingLeft = calcAngle(
        newLayer.getLatLngs()[0][newLayer.getLatLngs()[0].length - 1],
        newLayer.getLatLngs()[0][0],
        "outbound"
      );
      repeatBearingBottom = calcAngle(
        newLayer.getLatLngs()[0][newLayer.getLatLngs()[0].length - 1],
        newLayer.getLatLngs()[0][newLayer.getLatLngs()[0].length - 2],
        "outbound"
      );
      repeatBearingTop = calcAngle(
        newLayer.getLatLngs()[0][newLayer.getLatLngs()[0].length - 1],
        newLayer.getLatLngs()[0][newLayer.getLatLngs()[0].length - 2],
        "inbound"
      );
    }

    // Get the bounds of the polygon
    let bounds = newLayer.getBounds();
    // Get the southwest and northwest points
    let sw = bounds.getSouthWest();
    let ne = bounds.getNorthEast();

    // Calculate the midpoint
    let polygonCenter = L.latLng((sw.lat + ne.lat) / 2, (sw.lng + ne.lng) / 2);

    // Calculate the destination point 5 meters in the direction of the rotated bearing
    let point = turf.point([polygonCenter.lng, polygonCenter.lat]);
    let options = { units: "meters" };
    let destinationPoint = turf.destination(point, 300, repeatBearingBottom, options);
    let destination = L.latLng(
      destinationPoint.geometry.coordinates[1],
      destinationPoint.geometry.coordinates[0]
    );

    // Define the path for the polyline
    let path = [
      [polygonCenter.lat, polygonCenter.lng],
      [destination.lat, destination.lng]
    ];

    // Create the polyline
    let polyline = L.polyline(path, { color: "yellow" });

    // Calculate the destination point 5 meters in the direction of the rotated bearing
    let pointTop = turf.point([polygonCenter.lng, polygonCenter.lat]);
    let destinationPointTop = turf.destination(pointTop, 300, repeatBearingRight, options);
    let destinationTop = L.latLng(
      destinationPointTop.geometry.coordinates[1],
      destinationPointTop.geometry.coordinates[0]
    );

    // Define the path for the polyline
    let pathTop = [
      [polygonCenter.lat, polygonCenter.lng],
      [destinationTop.lat, destinationTop.lng]
    ];

    // Create the polyline
    let polylineTop = L.polyline(pathTop, { color: "red" });

    // Convert the polygon and polyline to GeoJSON
    let polygonGeoJSON = newLayer.toGeoJSON();
    let polylineGeoJSON = polyline.toGeoJSON();

    // Convert the polygon to a line string
    let polygonLineString = turf.polygonToLineString(polygonGeoJSON);

    // Find the intersection points
    let intersectionPoints = turf.lineIntersect(polygonLineString, polylineGeoJSON);

    // Convert the polygon and polyline to GeoJSON
    let polygonGeoJSONTop = newLayer.toGeoJSON();
    let polylineGeoJSONTop = polylineTop.toGeoJSON();

    // Convert the polygon to a line string
    let polygonLineStringTop = turf.polygonToLineString(polygonGeoJSONTop);

    // Find the intersection points
    let intersectionPointsTop = turf.lineIntersect(polygonLineStringTop, polylineGeoJSONTop);

   let repeatDistance = (
      polygonCenter.distanceTo([
        intersectionPoints.features[0].geometry.coordinates[1],
        intersectionPoints.features[0].geometry.coordinates[0]
      ]) * 2
    ).toFixed(2);
    let treatmentDistance = (
      polygonCenter.distanceTo([
        intersectionPointsTop.features[0].geometry.coordinates[1],
        intersectionPointsTop.features[0].geometry.coordinates[0]
      ]) * 2
    ).toFixed(2);

    repBearingConst = repeatBearingBottom;
    trmtBearingConst = repeatBearingRight;
    trmtDistanceAddConst = treatmentDistance;
    repDistanceAddConst = repeatDistance;

    setTrmtBearing(repeatBearingRight);
    setRepBearing(repeatBearingBottom);
    setTrmtDistanceAdd(treatmentDistance);
    setRepDistanceAdd(repeatDistance);
    setDistanceByManual(distanceByManual);

    let selectedPlotRow = 0;
    let selectedPlotCol = 0;
    selectedPlotRow = latestPlotDetails.current.filter((plot) => {
      return plot.leafletId.toString() == selectedPlot?.leafletId.toString();
    })[0].Row;

    selectedPlotCol = latestPlotDetails.current.filter((plot) => {
      return plot.leafletId.toString() == selectedPlot?.leafletId.toString();
    })[0].Col;

    selectedPlotsConst = [...selectedPlotsConst].sort((a, b) => {
      if (a["Row"] > b["Row"]) {
        return 1;
      } else if (b["Row"] > a["Row"]) {
        return -1;
      } else {
        return 0;
      }
    });

    getModifiedPlotSizes(latLngs, drawnPlotsCopy.find(x => x.Col == selectedPlotCol & x.Row == selectedPlotRow).plotCoordinates);
    drawnPlotsTemp.find(x => x.Col == selectedPlotCol & x.Row == selectedPlotRow).plotCoordinates = [...latLngs];
    let currLatLngs = latLngs;
    let recentlyModifiedPlot = drawnPlotsTemp.find(x => x.Col == selectedPlotCol & x.Row == selectedPlotRow).plotId;

    for (let col = selectedPlotCol; col > 0; col--) {
      if (col != selectedPlotCol) {
        let filteredItem = selectedPlotsConst.filter((plot) => {
          return (
            plot.Row.toString() == selectedPlotRow.toString() &&
            plot.Col.toString() == col.toString()
          );
        });

        if (filteredItem.length > 0) {
          retLatLngs = plotCreation(
            currLatLngs,
            repeatBearingLeft,
            Number(xDistance) + Number(treatmentDistance),
            filteredItem[0].plotId,
            filteredItem[0].leafletId,
            selectedPlots[0]?.rotation || filteredItem[0].rotation
          );
          currLatLngs = retLatLngs;
          drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs];
        }
      }
    }

    let currLatLngs1 = latLngs;
    let retLatLngs1 = null;

    for (let col = selectedPlotCol; col <= noOfTreatmentsConst; col++) {
      if (col != selectedPlotCol) {
        let filteredItem = selectedPlotsConst.filter((plot) => {
          return (
            plot.Row.toString() == selectedPlotRow.toString() &&
            plot.Col.toString() == col.toString()
          );
        });

        if (filteredItem.length > 0) {
          let manualDistance = L.latLng(drawnPlotsCopy.find(x => x.plotId == recentlyModifiedPlot).plotCoordinates[0]).distanceTo(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]) + modifiedPlotWidth;          
          retLatLngs1 = plotCreation(
            currLatLngs1,
            repeatBearingRight,
            manualDistance,
            filteredItem[0].plotId,
            filteredItem[0].leafletId,
            selectedPlots[0]?.rotation || filteredItem[0].rotation
          );
          currLatLngs1 = retLatLngs1;
          drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs1];
          recentlyModifiedPlot = filteredItem[0].plotId;
        }
      }
    }

    //Below Rows - Start
    let currLatLngs2 = latLngs;
    let retLatLngs2 = null;

    for (let row = selectedPlotRow; row > 0; row--) {
      let notFound1 = "false";
      let movedCol1 = 0;
      if (row != selectedPlotRow) {
        let filteredItem = selectedPlotsConst.filter((plot) => {
          return (
            plot.Row.toString() == row.toString() &&
            plot.Col.toString() == selectedPlotCol.toString()
          );
        });

        if (filteredItem.length > 0) {
          retLatLngs2 = plotCreation(
            currLatLngs2,
            repeatBearingTop,
            Number(yDistance) + Number(repeatDistance),
            filteredItem[0].plotId,
            filteredItem[0].leafletId,
            selectedPlots[0]?.rotation || filteredItem[0].rotation
          );
          currLatLngs2 = retLatLngs2;
        } else {
          for (let col = selectedPlotCol; col > 0; col--) {
            if (col != selectedPlotCol) {
              let filteredItem = selectedPlotsConst.filter((plot) => {
                return (
                  plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString()
                );
              });
              if (filteredItem.length > 0) {
                retLatLngs2 = plotCreation(
                  currLatLngs2,
                  repeatBearingTop,
                  Number(yDistance) + Number(repeatDistance),
                  filteredItem[0].plotId,
                  filteredItem[0].leafletId
                );
                movedCol1 = filteredItem[0].Col;
                currLatLngs2 = retLatLngs2;
                notFound1 = "true";
                break;
              }
            }
          }

          if (notFound1.toString() === "false") {
            for (let col = selectedPlotCol; col <= noOfTreatmentsConst; col++) {
              if (col != selectedPlotCol) {
                let filteredItem = selectedPlotsConst.filter((plot) => {
                  return (
                    plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString()
                  );
                });
                if (filteredItem.length > 0) {
                  if (filteredItem[0].plotId < recentlyModifiedPlot) {
                    let remainder = filteredItem[0].plotId % Number(noOfTreatmentsConst);
                    let rowSel = selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row > row ? selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row :
                      selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row < row ? row : selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row;

                    if (remainder === 0) {
                      if (Math.abs(selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row - row) > 1) {
                        recentlyModifiedPlot = selectedPlotsConst.find(x => x.Row == rowSel - 1 & x.Col == Number(noOfTreatmentsConst)).plotId;
                      } else {
                        recentlyModifiedPlot = selectedPlotsConst.find(x => x.Row == rowSel & x.Col == Number(noOfTreatmentsConst)).plotId;
                      }
                    }
                    else if (remainder > 0) {
                      if (Math.abs(selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row > row)) {
                        if (selectedPlotsConst.some(x => x.Row == selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row & x.Col == remainder)) {
                          recentlyModifiedPlot = selectedPlotsConst.find(x => x.Row == selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row & x.Col == remainder).plotId;
                        }
                      }
                      else if (Math.abs(selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row - row) > 1) {
                        if (selectedPlotsConst.some(x => x.Row == selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row & x.Col == remainder)) {
                          recentlyModifiedPlot = selectedPlotsConst.find(x => x.Row == selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row & x.Col == remainder).plotId;
                        }
                      }
                      else {
                        recentlyModifiedPlot = selectedPlotsConst.some(x => x.Row == rowSel - 1 & x.Col == remainder).plotId ? selectedPlotsConst.find(x => x.Row == rowSel - 1 & x.Col == remainder).plotId : recentlyModifiedPlot;
                      }
                    }
                  }
                  let manualDistance = L.latLng(drawnPlotsCopy.find(x => x.plotId == recentlyModifiedPlot).plotCoordinates[0]).distanceTo(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]) + modifiedPlotHeight;
                  currLatLngs2 = currLatLngs2 != retLatLngs2 ? drawnPlotsTemp.find(x => x.plotId == recentlyModifiedPlot).plotCoordinates : currLatLngs2;
                  retLatLngs2 = plotCreation(
                    currLatLngs2,
                    repeatBearingTop,
                    manualDistance, //Number(yDistance) + Number(repeatDistance),
                    filteredItem[0].plotId,
                    filteredItem[0].leafletId
                  );
                  movedCol1 = filteredItem[0].Col;
                  currLatLngs2 = retLatLngs2;
                  drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs2];
                  notFound1 = true;
                  recentlyModifiedPlot = filteredItem[0].plotId;
                  break;
                }
              }
            }
          }
        }
        let currLatLngs = retLatLngs2;
        let retLatLngs = null;

        for (let col = selectedPlotCol; col > 0; col--) {
          if (col != selectedPlotCol && col != movedCol1) {
            let filteredItem = selectedPlotsConst.filter((plot) => {
              return plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString();
            });

            if (filteredItem.length > 0) {
              retLatLngs = plotCreation(
                currLatLngs,
                repeatBearingLeft,
                Number(xDistance) + Number(repeatDistance),
                filteredItem[0].plotId,
                filteredItem[0].leafletId,
                selectedPlots[0]?.rotation || filteredItem[0].rotation
              );
              currLatLngs = retLatLngs;
              drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs];
            }
          }
        }

        let currLatLngs1 = retLatLngs2;
        let retLatLngs1 = null;

        for (let col = selectedPlotCol; col <= noOfTreatmentsConst; col++) {
          if (col != selectedPlotCol && col != movedCol1) {
            let filteredItem = selectedPlotsConst.filter((plot) => {
              return plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString();
            });

            if (filteredItem.length > 0) {
              let manualDistance = L.latLng(drawnPlotsCopy.find(x => x.plotId == recentlyModifiedPlot).plotCoordinates[0]).distanceTo(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]) + modifiedPlotWidth;
              currLatLngs1 = currLatLngs1 != retLatLngs2 ? drawnPlotsTemp.find(x => x.plotId == recentlyModifiedPlot).plotCoordinates : currLatLngs1;
              retLatLngs1 = plotCreation(
                currLatLngs1,
                repeatBearingRight,
                manualDistance, //Number(xDistance) + Number(treatmentDistance),
                filteredItem[0].plotId,
                filteredItem[0].leafletId,
                selectedPlots[0]?.rotation || filteredItem[0].rotation
              );
              currLatLngs1 = retLatLngs1;
              drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs1];
              recentlyModifiedPlot = filteredItem[0].plotId;
            }
          }
        }
      }
    }

    //Above Rows - Start
    let currLatLngs3 = latLngs;

    let retLatLngs3 = null;

    for (let row = selectedPlotRow; row <= noOfRepsConst; row++) {
      let notFound = "false";
      let movedCol = 0;
      if (row != selectedPlotRow) {
        let filteredItem = selectedPlotsConst.filter((plot) => {
          return (
            plot.Row.toString() == row.toString() &&
            plot.Col.toString() == selectedPlotCol.toString()
          );
        });

        if (filteredItem.length > 0) {
          let manualDistance = L.latLng(drawnPlotsCopy.find(x => x.Row == selectedPlotRow & x.Col == selectedPlotCol).plotCoordinates[0]).distanceTo(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0])
            + modifiedPlotHeight * (row > selectedPlotRow ? row - selectedPlotRow : row < selectedPlotRow ? selectedPlotRow - row : 1);
          currLatLngs3 = currLatLngs3 != latLngs & row > selectedPlotRow ? latLngs : currLatLngs3;
          retLatLngs3 = plotCreation(
            currLatLngs3,
            repeatBearingBottom,
            manualDistance,
            filteredItem[0].plotId,
            filteredItem[0].leafletId,
            selectedPlots[0]?.rotation || filteredItem[0].rotation
          );
          currLatLngs3 = retLatLngs3;
          drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs3];
          recentlyModifiedPlot = filteredItem[0].plotId;
        } else {
          for (let col = selectedPlotCol; col > 0; col--) {
            if (col != selectedPlotCol) {
              let filteredItem = selectedPlotsConst.filter((plot) => {
                return (
                  plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString()
                );
              });
              if (filteredItem.length > 0) {
                retLatLngs3 = plotCreation(
                  currLatLngs3,
                  repeatBearingBottom,
                  Number(yDistance) + Number(repeatDistance),
                  filteredItem[0].plotId,
                  filteredItem[0].leafletId
                );
                movedCol = filteredItem[0].Col;
                currLatLngs3 = retLatLngs3;
                drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs3];
                notFound = "true";
                break;
              }
            }
          }

          if (notFound.toString() === "false") {
            for (let col = selectedPlotCol; col <= noOfTreatmentsConst; col++) {
              if (col != selectedPlotCol) {
                let filteredItem = selectedPlotsConst.filter((plot) => {
                  return (
                    plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString()
                  );
                });
                if (filteredItem.length > 0) {
                  let manualDistance = 0;
                  if (filteredItem[0].plotId > recentlyModifiedPlot & Math.abs(selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row - row) > 0) {
                    let remainder = filteredItem[0].plotId % Number(noOfTreatmentsConst);
                    let rowSel = selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row > row ? selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row :
                      selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row < row ? row : selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row;
                    if (remainder === 0) {
                      if (Math.abs(selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row - row) > 1) {
                        recentlyModifiedPlot = selectedPlotsConst.find(x => x.Row == rowSel & x.Col == Number(noOfTreatmentsConst)).plotId;
                      } else {
                        recentlyModifiedPlot = selectedPlotsConst.find(x => x.Row == rowSel & x.Col == Number(noOfTreatmentsConst)).plotId;
                      }
                    }
                    else if (remainder > 0) {
                      if (Math.abs(selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row - row) == 1) {
                        recentlyModifiedPlot = selectedPlotsConst.find(x => x.Row == rowSel - 1 & x.Col == remainder).plotId;
                      }
                      else if (Math.abs(selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row - row) > 1) {
                        recentlyModifiedPlot = selectedPlotsConst.find(x => x.Row == selectedPlotsConst.find(x => x.plotId == recentlyModifiedPlot).Row & x.Col == remainder).plotId;
                      }
                      else {
                        recentlyModifiedPlot = selectedPlotsConst.find(x => x.Row == rowSel - 1 & x.Col == remainder).plotId;
                      }
                    }
                  }
                  manualDistance = L.latLng(drawnPlotsCopy.find(x => x.plotId == recentlyModifiedPlot).plotCoordinates[0]).distanceTo(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]) + modifiedPlotHeight;
                  currLatLngs3 = drawnPlotsTemp.find(x => x.plotId == recentlyModifiedPlot).plotCoordinates;
                  retLatLngs3 = plotCreation(
                    currLatLngs3,
                    repeatBearingBottom,                    
                    manualDistance, //Number(yDistance) + Number(repeatDistance),
                    filteredItem[0].plotId,
                    filteredItem[0].leafletId
                  );
                  movedCol = filteredItem[0].Col;
                  currLatLngs3 = retLatLngs3;
                  drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs3];
                  notFound = true;
                  recentlyModifiedPlot = filteredItem[0].plotId;
                  break;
                }
              }
            }
          }
        }
        let currLatLngs = retLatLngs3;

        let retLatLngs = null;

        for (let col = selectedPlotCol; col > 0; col--) {
          if (col != selectedPlotCol && col != movedCol) {
            let filteredItem = selectedPlotsConst.filter((plot) => {
              return plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString();
            });

            if (filteredItem.length > 0) {
              retLatLngs = plotCreation(
                currLatLngs,
                repeatBearingLeft,
                Number(xDistance) + Number(treatmentDistance),
                filteredItem[0].plotId,
                filteredItem[0].leafletId
              );
              currLatLngs = retLatLngs;
              drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs];
            }
          }
        }

        let currLatLngs1 = retLatLngs3;
        let retLatLngs1 = null;

        for (let col = selectedPlotCol; col <= noOfTreatmentsConst; col++) {
          if (col != selectedPlotCol && col != movedCol) {
            let filteredItem = selectedPlotsConst.filter((plot) => {
              return plot.Row.toString() == row.toString() && plot.Col.toString() == col.toString();
            });

            if (filteredItem.length > 0) {
              let manualDistance = L.latLng(drawnPlotsCopy.find(x => x.plotId == recentlyModifiedPlot).plotCoordinates[0]).distanceTo(drawnPlotsCopy.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates[0]) + modifiedPlotWidth;
              retLatLngs1 = plotCreation(
                currLatLngs1,
                repeatBearingRight,
                manualDistance,
                filteredItem[0].plotId,
                filteredItem[0].leafletId,
                selectedPlots[0]?.rotation || filteredItem[0].rotation
              );
              currLatLngs1 = retLatLngs1;
              drawnPlotsTemp.find(x => x.plotId == filteredItem[0].plotId).plotCoordinates = [...retLatLngs1];
              recentlyModifiedPlot = filteredItem[0].plotId;
            }
          }
        }
      }
    }
    // end
    drawnPlotsCopy = JSON.parse(JSON.stringify(drawnPlotsTemp));
    updateDrawnPlots();
  };

  // generate checkboxes for each row and col of plots
  const selectPlotsList = () => {
    const checkBoxlist = [];
    const uniqueRows = new Set();
    const uniqueCols = new Set();
    plotDetails.forEach((row) => {
      uniqueRows.add(row?.Row);
      uniqueCols.add(row?.Col);
    });
    checkBoxlist.push(
      <Form.Check
        className="custom-checkbox"
        style={{ color: "#0D1A40" }}
        key={0}
        type="checkbox"
        checked={selectAll}
        onChange={selectChange}
        id="selectall"
        label="Select All Plots"
        value="Select All"
      />
    );
    for (let i = 1; i <= Array.from(uniqueRows).length; i++) {
      checkBoxlist.push(
        <Form.Check
          className="custom-checkbox"
          key={"Row" + i.toString()}
          type="checkbox"
          onChange={selectChange}
          id={"Row" + i.toString()}
          label={"Row " + i.toString()}
          value={"Row" + i.toString()}
          checked={selectedCheckboxes.includes("Row" + i.toString()) || selectAll}
        />
      );
    }
    for (let i = 1; i <= Array.from(uniqueCols).length; i++) {
      checkBoxlist.push(
        <Form.Check
          className="custom-checkbox"
          key={"Col" + i.toString()}
          type="checkbox"
          onChange={selectChange}
          id={"Col" + i.toString()}
          label={"Col " + i.toString()}
          value={"Col" + i.toString()}
          checked={selectedCheckboxes.includes("Col" + i.toString()) || selectAll}
        />
      );
    }
    return checkBoxlist;
  };

  //Rotate plots as per slider input
  const rotatePlots = (event) => {
    updateDrawnPlots();
    plotResizeEvent(event, "rotated");
  };

  //Read and store all rotation values with identification as key-value pair
  const getRotationValues = () =>{
    rotationValues.length = 0;
    var objRotation = plotDetailsConst.map(x => ({ key: "Row" + x.Row + "Col" + x.Col, value: x.rotation }));
    for (var i = 0; i < objRotation.length; i++) {
      var obj = { key: objRotation[i].key, value: objRotation[i].value };
      if (rotationValues.some(x => x.key == objRotation[i].key) == false) {
        rotationValues.push(obj);
      } 
    }      
  }
 
  //Update array to read respective degree when selecting plot(s)
  const updateRotations = function (flag, item) { 
    let index = rotationItems.findIndex(x => x.key == item);
    if (flag == "add") {
      if (index == -1) {
        rotationItems.push(rotationValues.find(x => x.key == item));
      }else{
        rotationItems[index].value = rotationValues.find(x => x.key == item).value;
      }
    } else {
      if (index > -1) {
        rotationItems.splice(index, 1)
      }      
    }
  }

  //Setting check box as per selected items
  const selectedCheckBoxesEdit = () => {
    checkBoxes = document.querySelectorAll(".toggleSwitch");
    checkBoxes.forEach((checkBox) => {
      if (checkBox.checked === false && (newArray.includes(checkBox.id?.toString().slice(0,4)|| newArray.includes(checkBox.id?.toString().slice(4))) )) {
        checkBox.checked = true;
      }
    });
  }

  // handle selection of checkboxes for each row and col
  const selectChange = (event) => {    
    checkBoxes = document.querySelectorAll(".toggleSwitch");
    const id = event.target.id;
    if (event.target.checked) {
      newArray.push(id);
      if (id === "selectall") {
        newArray = ["selectall"];
      }
      setSelectedCheckboxes([...newArray]);
    } else {
      newArray = newArray.filter((item) => item !== id);
      if (id == "selectall") {
        setSelectedCheckboxes([]);
        setSelectedPlots([]);
        setSelectAll(false);
      } else {
        setSelectedCheckboxes([...newArray]);
      }
    }

    if (newArray.includes("selectall")) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    checkBoxes.forEach((checkBox) => {
      let rowId = checkBox.id.substring(0, checkBox.id.indexOf("Col"));
      let colId = checkBox.id.substring(checkBox.id.indexOf("Col"), checkBox.id.length);
      if (
        newArray.includes(rowId.toString()) ||
        newArray.includes(colId.toString()) ||
        newArray.includes("selectall")
      ) {
        if (checkBox.checked === false) {
          checkBox.checked = true;
          let event = document.createEvent("HTMLEvents");
          event.initEvent("change", false, true);
          checkBox.dispatchEvent(event);          
        }
        else {
          if (!event.target.checked & checkBox.checked === true & !newArray.includes("selectall") &
          ((!newArray.includes(rowId.toString()) & (event.target.defaultValue.toString().includes(colId))) |
          (!newArray.includes(colId.toString()) & (event.target.defaultValue.toString().includes(rowId))))) {
          selectedPlots.splice(selectedPlots.findIndex(x => x.leafletId == checkBox.defaultValue), 1);
          checkBox.checked = false;
      } 
        }   
        updateRotations("add", rowId+colId);     
      }
       else {
        if (!event.target.checked & checkBox.checked === true &
          (!newArray.includes(rowId.toString()) & (event.target.defaultValue.toString().includes(colId))) |
          (!newArray.includes(colId.toString()) & (event.target.defaultValue.toString().includes(rowId)))) {
          selectedPlots.splice(selectedPlots.findIndex(x => x.leafletId == checkBox.defaultValue), 1);
        }
        checkBox.checked = false;
        updateRotations("remove", rowId+colId);     
      } 
    });
    if (newArray.length === 0) {
      setSelectedPlots([]);
      rotationItems.length = 0;
      setAngleValue(0);
    } else {
      let angle = rotationItems.length == 0 ? 0 : Number((rotationItems[0]?.value * 180 / Math.PI).toFixed(0)); 
      setAngleValue(angle == "NaN" ? 0 : angle);
    }
  };
 
  // Constructing array for undo and redo activities
  const updateDrawnPlots = () => {
    let validatePlots = [];
    if (drawnPlotsEdit?.length == (noOfRepsConst * noOfTreatmentsConst)) {
      validatePlots = [...drawnPlotsEdit];
    } else {
      validatePlots = plotNoConst == 0 ? drawnPlotsEdit.slice(drawnPlotsEdit.length - 1) : drawnPlotsEdit.slice(drawnPlotsEdit.length - (noOfRepsConst * noOfTreatmentsConst));
    }  
    if (JSON.stringify(drawnPlotsCopy) != JSON.stringify(validatePlots)) {
      drawnPlotsCopy.forEach(function (plot) {
        let obj = {};
        obj["leafletId"] = plot.leafletId;
        obj["plotId"] = plot.plotId;
        obj["Row"] = plot.Row;
        obj["Col"] = plot.Col;
        obj["treatmentNumber"] = plot.treatmentNumber || 0;
        obj["plotNumber"] = plot.plotNumber || 0;
        obj["rotation"] = plot.rotation;
        let latlng = [];
        for (let i = 0; i <= plot.plotCoordinates.length - 1; i++) {
          let latlngArray = [];
          latlngArray.push(plot.plotCoordinates[i].lat);
          latlngArray.push(plot.plotCoordinates[i].lng);
          latlng.push(latlngArray);
        }
        obj["plotCoordinates"] = [...latlng.map(x => L.latLng(x))];
        drawnPlotsEdit.push({ ...obj });
      });      
      if (plotNoConst == 0) {
        setEditCurrentIndex(drawnPlotsEdit?.length - 1);
      } else {
        setEditCurrentIndex(drawnPlotsEdit?.length - (noOfRepsConst * noOfTreatmentsConst));
      }
    }
  };
  // on base plot button click create a first plot
  const drawBasePLot = (height, width) => {
    basePlotWidth = width;
    basePlotHeight = height;
    clearAllDrawnLayers();
    transformListConst?.forEach((item) => {
      item.transform?.removeHooks();
    });
    mapLayout.eachLayer((layer) => {
      if (layer instanceof L.Popup) {
        mapLayout.removeLayer(layer);
      }
    });
    setTransformList([]);
    setPopupLayers([]);

    let center = mapLayout.getCenter();

    // Calculate the north, south, east, and west bounds
    let north = L.GeometryUtil.destination(center, 0, height / 2).lat;
    let south = L.GeometryUtil.destination(center, 180, height / 2).lat;
    let east = L.GeometryUtil.destination(center, 90, width / 2).lng;
    let west = L.GeometryUtil.destination(center, -90, width / 2).lng;

    // Calculate the points of the rectangle
    let point1 = [south, west];
    let point2 = [north, west];
    let point3 = [north, east];
    let point4 = [south, east];

    // Create an array of the points
    let latlngs = [[point1, point2, point3, point4]];

    // Create the rectangle and add it to the map
    let rectangle = L.polygon(latlngs, { fillColor: "transparent" });
    drawnItemsConst.addLayer(rectangle);

    rectangle.on("edit", function (event) {
      updateDrawnPlots();
      plotResizeEvent(event, "resized");
    });

    parentLayerId = rectangle._leaflet_id;
    setTrmtDistanceAdd(
      rectangle.getBounds().getNorthWest().distanceTo(rectangle.getBounds().getNorthEast())
    );
    setRepDistanceAdd(
      rectangle.getBounds().getNorthWest().distanceTo(rectangle.getBounds().getSouthWest())
    );
    setLayerRotation(0);

    if (
      rectangle.getBounds().getSouthEast().lat == rectangle.getLatLngs()[0][0].lat ||
      rectangle.getBounds().getSouthEast().lat == rectangle.getLatLngs()[0][1].lat
    ) {
      setTrmtBearing(calcBearing(rectangle.getLatLngs()[0][1], rectangle.getLatLngs()[0][2], false));
      setRepBearing(calcBearing(rectangle.getLatLngs()[0][0], rectangle.getLatLngs()[0][1], false));
    } else {
      setTrmtBearing(calcBearing(rectangle.getLatLngs()[0][0], rectangle.getLatLngs()[0][1], false));
      setRepBearing(calcBearing(rectangle.getLatLngs()[0][1], rectangle.getLatLngs()[0][2], false));
    }
    repeatBearingRight = calcAngle(
      rectangle.getLatLngs()[0][rectangle.getLatLngs()[0].length - 1],
      rectangle.getLatLngs()[0][0],
      "inbound"
    );
    repeatBearingLeft = calcAngle(
      rectangle.getLatLngs()[0][rectangle.getLatLngs()[0].length - 1],
      rectangle.getLatLngs()[0][0],
      "outbound"
    );
    repeatBearingBottom = calcAngle(
      rectangle.getLatLngs()[0][0],
      rectangle.getLatLngs()[0][1],
      "outbound"
    );
    repeatBearingTop = calcAngle(
      rectangle.getLatLngs()[0][0],
      rectangle.getLatLngs()[0][1],
      "inbound"
    );

    layerConst = rectangle;
    setLayerState(rectangle);

    // Get the plot details from the API payload
    const plotDetailsFromAPIPayload = plotDetailsFromAPIConst?.payload || [];

    // Find the data for the plot with col = 1 and blk = 1
    const findData = plotDetailsFromAPIPayload.find((item) => {
      return item.col === 1 && item.blk === 1;
    });
    const plots = [
      {
        plotId: 1,
        plotCoordinates: rectangle._latlngs[0],
        rotation: 0,
        leafletId: rectangle._leaflet_id,
        Row: 1,
        Col: 1,
        plotNumber: findData?.planPlotId,
        treatmentNumber: findData?.trt
      }
    ];
    setPlotDetails(plots);

    if (mapEditing === "true") {
      const transform = new L.Handler.PathTransform(rectangle);
      transform
        .setOptions({
          rotation: false,
          scaling: false
        })
        .enable();

      rectangle.on("rotateend", onStopRotateFeature, this);

      setTransformList((prevArray) => [
        ...prevArray,
        {
          plotId: 1,
          transform: transform
        }
      ]);
    }
    drawnPlotsEdit.length = 0;
    setDisableUndo(false);
    setDisableRedo(true);
  };

  // calc bearing based on lat and lang of given to points
  const calcBearing = (latlng1, latlng2, actual) => {
    let rad = Math.PI / 180,
      lat1 = latlng1.lat * rad,
      lat2 = latlng2.lat * rad,
      lon1 = latlng1.lng * rad,
      lon2 = latlng2.lng * rad,
      y = Math.sin(lon2 - lon1) * Math.cos(lat2),
      x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);
    let bearing = ((Math.atan2(y, x) * 180) / Math.PI + 360) % 360;
    return actual == true ? bearing : bearing >= 180 ? bearing - 360 : bearing;
  };

  // get actual bearing based on lat and lang of given to points
  const getBearing = (latlng1, latlng2) => {
    let bearing = calcBearing(latlng1, latlng2, true);
    return bearing;
  };

  // generate next plots after 2 plots creating a first two plots
  const getNextPolygon = (latLngs, row) => {
    let bearing = trmtBearingConst;

    const points1 = [];
    for (let i = 0; i < latLngs.length; i++) {
      points1.push(
        L.GeometryUtil.destination(
          { lat: latLngs[i].lat, lng: latLngs[i].lng },
          bearing,
          Number(trtmtDistanceConst) + Number(trmtDistanceAddConst)
        )
      );
    }
    let polygon1 = L.polygon(points1, { fillColor: "transparent", color: "#3388ff" });

    plotNoConst = plotNoConst + 1;
    polygon1.addTo(drawnItemsConst);

    if (mapEditing === "true") {
      const transform = new L.Handler.PathTransform(polygon1);
      transform
        .setOptions({
          rotation: false,
          scaling: false
        })
        .enable();

      polygon1.on("rotateend", onStopRotateFeature, this);

      setTransformList((prevArray) => [
        ...prevArray,
        {
          plotId: plotNoConst,
          transform: transform
        }
      ]);
    }

    let latlngs11 = polygon1.getLatLngs()[0];

    polygon1.on("edit", function (event) {
      plotResizeEvent(event, false);
      updateDrawnPlots();
    });

    // Get plot details from API payload
    const plotDetailsFromAPIPayload = plotDetailsFromAPI?.payload || [];
    // Find data based on col and blk values
    const findData = plotDetailsFromAPIPayload.find((item) => {
      return item.col === 2 && item.blk === row;
    });
    setPlotDetails((prevArray) => [
      ...prevArray,
      {
        plotId: prevArray.length + 1,
        plotCoordinates: polygon1._latlngs[0],
        leafletId: polygon1._leaflet_id,
        rotation: 0,
        Row: row,
        Col: 2,
        plotNumber: findData?.planPlotId,
        treatmentNumber: findData?.trt
      }
    ]);
    let col = 2;

    for (let j = 1; j < inputValues.noOfTreatments - 1; j++) {
      col = col + 1;
      const points2 = [];
      for (let k = 0; k < latlngs11.length; k++) {
        points2.push(
          L.GeometryUtil.destination(
            { lat: latlngs11[k].lat, lng: latlngs11[k].lng },
            bearing,
            Number(trtmtDistanceConst) + Number(trmtDistanceAddConst)
          )
        );
      }
      let polygon2 = L.polygon(points2, { fillColor: "transparent", color: "#3388ff" });

      plotNoConst = plotNoConst + 1;
      polygon2.addTo(drawnItemsConst);

      if (isSelectPlot) {
        const transform = new L.Handler.PathTransform(polygon2);
        transform
          .setOptions({
            rotation: false,
            scaling: false
          })
          .enable();

        polygon2.on("rotateend", onStopRotateFeature, this);

        setTransformList((prevArray) => [
          ...prevArray,
          {
            plotId: plotNoConst,
            transform: transform
          }
        ]);
      }

      latlngs11 = polygon2.getLatLngs()[0];

      polygon2.on("edit", function (event) {
        plotResizeEvent(event, false);
        updateDrawnPlots();
      });

      const col1 = col;
      const coordinates = polygon2.getLatLngs()[0];
      const rotation = 0;
      const leafletId = polygon2._leaflet_id;

      // Get plot details from API payload
      const plotDetailsFromAPIPayload = plotDetailsFromAPI?.payload || [];
      // Find data based on col and blk values
      const findData = plotDetailsFromAPIPayload.find((item) => {
        return item.col === col1 && item.blk === row;
      });
      setPlotDetails((prevArray) => [
        ...prevArray,
        {
          plotId: prevArray.length + 1,
          plotCoordinates: coordinates,
          rotation,
          leafletId,
          Row: row,
          Col: col1,
          plotNumber: findData?.planPlotId,
          treatmentNumber: findData?.trt
        }
      ]);
    }
    return latlngs11;
  };

  // on click of generate plots button click generate plots based on given input values
  const generatePlots = () => {
    plotNoConst = 1;
    let latLngs = layerConst.getLatLngs()[0];
    for (let i = 1; i <= inputValues.noOfReps; i++) {
      if (inputValues.noOfTreatments > 1) {
        getNextPolygon(latLngs, i);
      }
      const row = i + 1;
      if (i < inputValues.noOfReps) {
        const points = [];
        for (let j = 0; j < latLngs.length; j++) {
          points.push(
            L.GeometryUtil.destination(
              { lat: latLngs[j].lat, lng: latLngs[j].lng },
              repBearingConst,
              Number(repDistanceAddConst) + Number(repDistanceConst)
            )
          );
        }
        let polygon3 = L.polygon(points, { fillColor: "transparent", color: "#3388ff" });
        plotNoConst = plotNoConst + 1;
        polygon3.addTo(drawnItemsConst);

        if (mapEditing === "true") {
          const transform = new L.Handler.PathTransform(polygon3);
          transform
            .setOptions({
              rotation: false,
              scaling: false
            })
            .enable();

          polygon3.on("rotateend", onStopRotateFeature, this);

          setTransformList((prevArray) => [
            ...prevArray,
            {
              plotId: plotNoConst,
              transform: transform
            }
          ]);
        }

        latLngs = polygon3.getLatLngs()[0];

        polygon3.on("edit", function (event) {
          plotResizeEvent(event, false);
          updateDrawnPlots();
        });

        const coordinates = latLngs;
        const rotation = 0;
        const leafletId = polygon3._leaflet_id;
        setSelectedBoxes(["Plot ID"]);
        // Get plot details from API payload
        const plotDetailsFromAPIPayload = plotDetailsFromAPI?.payload || [];

        // Find data for the specific row and column
        const findData = plotDetailsFromAPIPayload.find((item) => {
          return item.col === 1 && item.blk === row;
        });
        setPlotDetails((prevArray) => {
          const updatePlotDetails = [
            ...prevArray,
            {
              plotId: prevArray.length + 1,
              plotCoordinates: coordinates,
              leafletId: leafletId,
              rotation,
              Row: row,
              Col: 1,
              plotNumber: findData?.planPlotId,
              treatmentNumber: findData?.trt
            }
          ];
          return updatePlotDetails;
        });
        if (findData?.planPlotId || findData?.trt) {
          setSelectedBoxes(["Plot ID", "Plot #", "Treatment #"]);
        } else {
          setSelectedBoxes(["Plot ID"]);
        }
      }
    }
    if (mapEditing === "true") {
      setEnableSelectedPlots(true);
    } 
  };

  // clear all plots
  const clearAllDrawnLayers = () => {
    setPlotDetails([]);
    plotNoConst = drawnPlots?.length > 1 ? drawnPlots?.length : 0;
    drawnItemsConst.eachLayer((layer) => {
      drawnItemsConst.removeLayer(layer); // Remove the layer from the feature group
    });
  };

  // clear all plots except first plot
  const clearAllDrawnLayersExceptFirst = () => {
    setPlotDetails(plotDetails.filter((plot) => plot.plotId.toString() == 1));
    drawnItemsConst.eachLayer((layer) => {
      let filteredData = plotDetailsConst.filter((item) => {
        return item.leafletId.toString() == layer._leaflet_id.toString();
      });
      if (filteredData.length > 0 && filteredData[0].plotId.toString() == "1") {
        layerConst = layer;
        parentLayerId = layer._leaflet_id;
        setLayerState(layer);

        let latLngs = layer.getLatLngs()[0];
        let maxX = Infinity;
        let minY = Infinity;
        for (let i = 0; i <= latLngs.length - 1; i++) {
          minY = Math.min(minY, latLngs[i].lat);
        }

        for (let i = 0; i <= latLngs.length - 1; i++) {
          if (minY == latLngs[i].lat) {
            maxX = Math.min(maxX, latLngs[i].lng);
          }
        }

        let minIndex = 0;
        for (let i = 0; i <= latLngs.length - 1; i++) {
          if (maxX == latLngs[i].lng && minY == latLngs[i].lat) {
            minIndex = i;
          }
        }

        if (minIndex === 0) {
          repeatBearingRight = calcAngle(
            layer.getLatLngs()[0][1],
            layer.getLatLngs()[0][2],
            "outbound"
          );
          repeatBearingLeft = calcAngle(
            layer.getLatLngs()[0][1],
            layer.getLatLngs()[0][2],
            "inbound"
          );
          repeatBearingBottom = calcAngle(
            layer.getLatLngs()[0][0],
            layer.getLatLngs()[0][1],
            "outbound"
          );
          repeatBearingTop = calcAngle(
            layer.getLatLngs()[0][0],
            layer.getLatLngs()[0][1],
            "inbound"
          );
        } else {
          repeatBearingRight = calcAngle(
            layer.getLatLngs()[0][layer.getLatLngs()[0].length - 1],
            layer.getLatLngs()[0][0],
            "inbound"
          );
          repeatBearingLeft = calcAngle(
            layer.getLatLngs()[0][layer.getLatLngs()[0].length - 1],
            layer.getLatLngs()[0][0],
            "outbound"
          );
          repeatBearingBottom = calcAngle(
            layer.getLatLngs()[0][layer.getLatLngs()[0].length - 1],
            layer.getLatLngs()[0][layer.getLatLngs()[0].length - 2],
            "outbound"
          );
          repeatBearingTop = calcAngle(
            layer.getLatLngs()[0][layer.getLatLngs()[0].length - 1],
            layer.getLatLngs()[0][layer.getLatLngs()[0].length - 2],
            "inbound"
          );
        }

        // Get the bounds of the polygon
        let bounds = layer.getBounds();
        // Get the southwest and northwest points
        let sw = bounds.getSouthWest();
        let ne = bounds.getNorthEast();

        // Calculate the midpoint
        let center = L.latLng((sw.lat + ne.lat) / 2, (sw.lng + ne.lng) / 2);

        // Calculate the destination point 5 meters in the direction of the rotated bearing
        let point = turf.point([center.lng, center.lat]);
        let options = { units: "meters" };
        let destinationPoint = turf.destination(point, 300, repeatBearingBottom, options);
        let destination = L.latLng(
          destinationPoint.geometry.coordinates[1],
          destinationPoint.geometry.coordinates[0]
        );

        // Define the path for the polyline
        let path = [
          [center.lat, center.lng],
          [destination.lat, destination.lng]
        ];

        // Create the polyline
        let polyline = L.polyline(path, { color: "yellow" });

        // Calculate the destination point 5 meters in the direction of the rotated bearing
        let pointTop = turf.point([center.lng, center.lat]);
        let destinationPointTop = turf.destination(pointTop, 300, repeatBearingRight, options);
        let destinationTop = L.latLng(
          destinationPointTop.geometry.coordinates[1],
          destinationPointTop.geometry.coordinates[0]
        );

        // Define the path for the polyline
        let pathTop = [
          [center.lat, center.lng],
          [destinationTop.lat, destinationTop.lng]
        ];

        // Create the polyline
        let polylineTop = L.polyline(pathTop, { color: "red" });

        // Convert the polygon and polyline to GeoJSON
        let polygonGeoJSON = layer.toGeoJSON();
        let polylineGeoJSON = polyline.toGeoJSON();

        // Convert the polygon to a line string
        let polygonLineString = turf.polygonToLineString(polygonGeoJSON);

        // Find the intersection points
        let intersectionPoints = turf.lineIntersect(polygonLineString, polylineGeoJSON);

        // Convert the polygon and polyline to GeoJSON
        let polygonGeoJSONTop = layer.toGeoJSON();
        let polylineGeoJSONTop = polylineTop.toGeoJSON();

        // Convert the polygon to a line string
        let polygonLineStringTop = turf.polygonToLineString(polygonGeoJSONTop);

        // Find the intersection points
        let intersectionPointsTop = turf.lineIntersect(polygonLineStringTop, polylineGeoJSONTop);

        let repeatDistance = basePlotHeight;
        let treatmentDistance = basePlotWidth;
        //The below setstate taking time and before thta generate plot method is getting called. So directly assigning the values to the const variables
        repBearingConst = repeatBearingBottom;
        trmtBearingConst = repeatBearingRight;
        trmtDistanceAddConst = treatmentDistance;
        repDistanceAddConst = repeatDistance;
        setTrmtBearing(repeatBearingRight);
        setRepBearing(repeatBearingBottom);
        setTrmtDistanceAdd(treatmentDistance);
        setRepDistanceAdd(repeatDistance);
        setDistanceByManual(distanceByManual);
      } else if (filteredData.length > 0 && filteredData[0].plotId.toString() != "1") {
        drawnItemsConst.removeLayer(layer);
      }
    });

    transformListConst.forEach((option) => {
      if (option.plotId.toString() != "1") {
        option?.transform?.removeHooks();
      }
    });

    popupLayersConst.map((item) => item.popup.removeFrom(mapLayout));
    setPopupLayers([]);
    newArray = [];
    setSelectAll(false);
    setSelectedCheckboxes([]);
    setEnableSelectedPlots(false);
    tooltipGenerated();
    drawnPlotsEdit.length = 0;
    drawnPlotsTemp.length = 0;
  };

  // after plot is rotated this function is called to generate next plots
  const onStopRotateFeature = (e) => {
    layerConst = e.target;
    setLayerState(e.target);
    if (e.target._leaflet_id == parentLayerId) {
      if (e.rotation >= 0) {
        if (
          e.target.getBounds().getSouthEast().lat == e.target.getLatLngs()[0][0].lat ||
          e.target.getBounds().getSouthEast().lat == e.target.getLatLngs()[0][1].lat
        ) {
          setRepBearing(calcBearing(e.target.getLatLngs()[0][1], e.target.getLatLngs()[0][2], false));
          setTrmtBearing(calcBearing(e.target.getLatLngs()[0][0], e.target.getLatLngs()[0][1], false));
        } else {
          setRepBearing(calcBearing(e.target.getLatLngs()[0][0], e.target.getLatLngs()[0][1], false));
          setTrmtBearing(calcBearing(e.target.getLatLngs()[0][1], e.target.getLatLngs()[0][2], false));
        }
      } else if (e.rotation < 0) {
        setRepBearing(calcBearing(e.target.getLatLngs()[0][0], e.target.getLatLngs()[0][1], false));
        setTrmtBearing(calcBearing(e.target.getLatLngs()[0][1], e.target.getLatLngs()[0][2], false));
      }
      updateDrawnPlots();
      plotResizeEvent(e, "rotated");
    } else {
      updateDrawnPlots();
      plotResizeEvent(e, "rotated");
    }
  };

  trialListData?.trial_lists?.forEach((item) => {
    filteredTrialsArray.add(item?.trial);
  });

  const drawRasterLayer = (newLayer) => {
    // remove all other layers and add new one
    Object.keys(rasterLayers).forEach((layerKey) => {
      if (newLayer === layerKey) {
        rasterLayers[layerKey].addTo(mapState);
      } else {
        rasterLayers[layerKey].removeFrom(mapState);
      }
    });
  };

  const handleToggle = () => {
    setYearOpen(!yearOpen);
  };

  const handleSelect = (option) => {
    setSelectedYear(option);
  };

  // handle excel file upload with validation
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setIsUploading(true);

    // Checking if the file type is allowed or not
    const allowedTypes = [
      "application/vnd.ms-excel",
      "application/excel",
      "application/x-msexcel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ];
    if (allowedTypes.includes(selectedFile?.type)) {
      setUploadError(false);
      event.target.value = null;
      let reader = new FileReader();
      reader.onload = function (e) {
        let data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
        if (
          dataParse?.length > 1 &&
          dataParse?.some((t, index) => index > 0 && t[1] && t[1] !== "")
        ) {
          //reading excel file and appending plot # and treatment # to plotDetials state
          const updatedPlotDetails = plotDetails.reduce((acc, plot) => {
            const findData = dataParse.find((row) => row?.[0] === plot.plotId);
            const findTreatment = plotDetailsFromAPI?.payload?.find(
              (x) => x.planPlotId == findData?.[1]
            );
            const trtmtNumber = findData?.[2] || findTreatment?.trt || "";

            acc.push({
              ...plot,
              plotNumber: findData?.[1] || "",
              treatmentNumber: trtmtNumber
            });
            return acc;
          }, []);
          setPlotDetails(updatedPlotDetails);
          setFileName(selectedFile?.name);
          const selectedBoxesValues = ["Plot ID"];
          updatedPlotDetails.some((item) => item.plotNumber) && selectedBoxesValues.push("Plot #");
          updatedPlotDetails.some((item) => item.treatmentNumber) &&
            selectedBoxesValues.push("Treatment #");
          setSelectedBoxes(selectedBoxesValues);
          setSaveDisable(false);
          setSubmitDisable(false);
        } else {
          setUploadError(true);
        }
        setIsUploading(false);
      };
      reader.readAsBinaryString(selectedFile);
    } else {
      setUploadError(true);
      setFileName("");
      setIsUploading(false);
    }
  };

  // delete uploaded file
  const deleteFile = () => {
    setPlotDetails(
      plotDetails?.map((plot) => {
        return { ...plot, plotNumber: "", treatmentNumber: "" };
      })
    );
    setSelectedBoxes(["Plot ID"]);
    setFileName();
  };

  // gerenarte year dropdown code
  const yearDropDown = () => {
    return (
      <Dropdown
        show={yearOpen}
        onToggle={handleToggle}
        style={{ backgroundColor: "transparent", display: "flex", width: "109px" }}>
        <Dropdown.Toggle variant="light" className="year-dropdown-toggle">
          <div className="pt-0">
            <span className="yearButton">Year</span>
          </div>
          <div className="selected-year-dropdown">
            <span className="selected-year ">{selectedYear}</span>
            <span>
              <ChevronDown className="editviewer-down-icon  " />
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu col-xs-12>
          {years.map((option) => (
            <Dropdown.Item
              key={option}
              eventKey={option}
              onClick={() => handleSelect(option)}
              active={selectedYear === option}>
              <div
                className="option"
                style={{
                  color: selectedYear === option ? "#E08330" : "#0D1A40"
                }}>
                {option}
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  // call edc api to get results on search
  const edcApi = (trial) => {
    setSuggestionLoader(true);
    setFilteredTrials([]);
    let payload = {
      filters: [
        {
          comparator: "LIKE",
          field: "id",
          value: `%${trial}%`
        },
        {
          comparator: "EQ",
          field: "trialYear",
          value: selectedYear === "All Years" ? "" : selectedYear
        }
      ]
    };
    loginSyt(
      idToken,
      (res) => {
        if (res === 200) {
          edcGetAccessToken((res) => {
            if (res?.id_token !== "") {
              getAllFilterData(
                payload,
                res?.id_token,
                (data) => {
                  setAllData(data);
                  data?.contents.map((item) => {
                    filteredTrialsArray.add(item?.trialId);
                  });
                  const filteredApiTrials = Array.from(filteredTrialsArray).filter(
                    (item) => item.toLowerCase().indexOf(trial.toLowerCase()) != -1
                  );
                  if (filteredApiTrials.length > 0) {
                    setShowNoTrial(false);
                    setFilteredTrials(filteredApiTrials);
                  } else {
                    setNewTrial(trialSearchText);
                    setShowNoTrial(true);
                  }
                  setDataLoading(false);
                  setSuggestionLoader(false);
                },
                (err) => {
                  console.log("err", err);
                }
              );
            }
          });
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  // handle search value
  const handleTrialChange = (e) => {
    setNewTrial("");
    const value = e.target.value;
    trialSearchText = value;
    setTrial(value);
    if (value.length >= 4 && value.length <= 13) {
      edcApi(value);
    }
  };

  // get input fields value for seleted trials and generate base plot
  const getDefaultValues = (trial, newLayoutValue) => {
    setSuggestionLoader(true);
    getPlotInfo(
      trial,
      (data) => {
        if (newLayoutValue == "new") {
          setInputValues({
            plotWidth: formatToDecimalValues(data.geometry?.plotWidth) || "",
            plotLength: formatToDecimalValues(data.geometry?.plotLength) || "",
            noOfTreatments: data?.structure?.columnsNumber || "",
            noOfReps: data?.structure?.blocksNumber || "",
            trmtDistance: "",
            repDistance: ""
          });
        } else {
          setPlotDetailsFromAPI(data);
          plotDetailsFromAPIConst = data;
          setInputValues({
            ...inputValues,
            plotWidth: formatToDecimalValues(data.geometry?.plotWidth) || "",
            plotLength: formatToDecimalValues(data.geometry?.plotLength) || "",
            noOfTreatments: data?.structure?.columnsNumber || "",
            noOfReps: data?.structure?.blocksNumber || "",
            trmtDistance: "",
            repDistance: ""
          });
        }
        if (data.geometry?.plotWidth && data.geometry?.plotLength) {
          drawBasePLot(data.geometry?.plotLength, data.geometry?.plotWidth);
        } else {
          clearAllDrawnLayers();
        }
        setDataLoader(false);
        setSuggestionLoader(false);
      },
      (err) => {
        console.log("err", err);
        setSuggestionLoader(false);
      }
    );
  };

  // handle trial selection on trias list click
  const handleTrialSelection = (option) => {
    setSelectedLayoutNameForCount(option);
    setDataLoader(true);
    setSelectedBoxes(["Plot ID"]);
    setShowSupplyPlotDetails(false);
    setFileName();
    setPlotDetails([]);
    clearAllDrawnLayers();
    setAddTrial("");
    setTrial(option);
    setSelectedTrial(option);
    setFilteredTrials([]);
    getDefaultValues(option);
    mapLayout?.invalidateSize();
    getLayoutsList(collectionDataConst?.block_id, option, (data) => {
      setLayoutList(data?.layouts_list);
    });
    startOver();
    setNotes("");
    setLayoutType("new");
    setSelectedLayoutDetails({});
    setTimeout(() => {
      mapLayout?.invalidateSize();
    }, 100);
  };

  // validate new trial name
  const handleNewTrial = () => {
    const newItem = inputRef.current.value.trim();
    const pattern = /^[a-zA-Z].*\d{4}$/;
    if (
      newItem &&
      !(newItem.length === 13 && pattern.test(newItem)) &&
      !Array.from(filteredTrialsArray)?.includes(newItem)
    ) {
      setNewTrial(newItem);
      setShowModal(true);
      inputRef.current.value = "";
    } else {
      setError(true);
      setShowModal(false);
    }
  };

  const handleClickOutsideAutoSelect = (e) => {
    if (autoCompleteRef.current && !autoCompleteRef.current.contains(e.target)) {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setTrial("");
      }
      setFilteredTrials([]);
    }
  };

  // reset the input values
  const startOver = () => {
    if (selectedTrial) {
      setInputValues({
        ...inputValues,
        noOfTreatments: plotDetailsFromAPI?.structure?.columnsNumber,
        noOfReps: plotDetailsFromAPI?.structure?.blocksNumber,
        plotWidth: formatToDecimalValues(plotDetailsFromAPI.geometry?.plotWidth),
        plotLength: formatToDecimalValues(plotDetailsFromAPI.geometry?.plotLength),
        trmtDistance: "",
        repDistance: ""
      });
    } else if (addTrial) {
      setInputValues({
        plotLength: "",
        plotWidth: "",
        plotDirection: "",
        noOfTreatments: "",
        noOfReps: "",
        trmtDistance: "",
        repDistance: ""
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // trial search and result code
  const trialAutoCorrect = () => {
    return (
      <Form onSubmit={handleSubmit}>
        <div>
          {trial.length >= 4 &&
            filteredTrials.length > 0 &&
            !showNoTrial &&
            dataLoading === false && (
              <div>
                {filteredTrials?.map((option) => (
                  <div
                    className="p-1 search-radio"
                    key={option}
                    style={{ backgroundColor: "white" }}>
                    <input
                      type="radio"
                      name="options"
                      onChange={() => handleTrialSelection(option)}
                      id={`search-radio-${option}`}
                    />
                    <label
                      className="deep-cove-color"
                      style={{ marginLeft: "10px", fontSize: "12px", cursor: "pointer" }}
                      htmlFor={`search-radio-${option}`}
                    >
                      {option}
                    </label>
                  </div>
                ))}
              </div>
            )}
          {trial.length >= 4 && showNoTrial && !dataLoading && !suggestionLoader && (
            <div>
              <div>
                <div className="ml-2 mt-0 mb-4 ">
                  <span
                    className="light-red"
                    style={{
                      fontSize: "12px",
                      font: "Helvetica",
                      fontWeight: 700
                    }}>
                    <ExclamationTriangleFill
                      className="exclamation-triangle-fill"
                      width={24}
                      height={24}
                    />
                    &nbsp; Trial
                    {` '${trial}' `}
                    not found
                  </span>
                  <div
                    style={{
                      fontSize: "10px",
                      color: "#979797",
                      font: "Helvetica",
                      fontWeight: 400,
                      marginLeft: "10%"
                    }}>
                    (Make sure trial ID is created in BITS)
                  </div>
                </div>
                <hr
                  style={{
                    border: "none",
                    height: "1px",
                    backgroundColor: "#DDDDDD",
                    width: "100%"
                  }}
                />
                <div className="d-block ml-2 mt-1 mb-2">
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#474747",
                      font: "Helvetica",
                      fontWeight: 700
                    }}>
                    <span className="deep-cove-color">Add Area Name</span>
                  </span>
                  <div
                    style={{
                      fontSize: "10px",
                      color: "#979797",
                      font: "Helvetica",
                      fontWeight: 400
                    }}>
                    (This cannot be an ARM trial ID)
                  </div>
                </div>
                <div className="d-flex ml-2">
                  <span>
                    <FormControl
                      style={{
                        width: "176px",
                        height: "30px",
                        borderRadius: "15px",
                        boxShadow: "none",
                        border: "15px soild black",
                        color: "#0D1A40"
                      }}
                      type="text"
                      placeholder="Innovation center"
                      value={newTrial}
                      onChange={(e) => {
                        setNewTrial(e.target.value);
                        setError(false);
                      }}
                      ref={inputRef}
                    />
                  </span>

                  <span>
                    <Button
                      style={{
                        width: "125px",
                        height: "30px",
                        backgroundColor: "#0D1A40",
                        borderRadius: "25px",
                        boxShadow: "none",
                        border: "15px soild #0D1A40",
                        marginLeft: "10px",
                        padding: 0
                      }}
                      onClick={handleNewTrial}
                      disabled={newTrial.length === 0}>
                      <span
                        className="d-flex justify-content-center"
                        style={{ fontSize: "12px", fontWeight: 700 }}>
                        ADD AREA NAME
                      </span>
                    </Button>
                  </span>
                </div>

                <div className="mt-1 ml-3">
                  {error && (
                    <span
                      className="light-red"
                      style={{
                        fontSize: "12px",
                        font: "Helvetica",
                        fontWeight: 400
                      }}>
                      <XCircleFill className="light-red" width={12} height={12} /> &nbsp; Please enter an Area name which is not an ARM Trial ID</span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Form>
    );
  };

  const trialName = selectedTrial || addTrial;
  const collectionArray = allData?.contents?.filter((item) => item?.trialId === trialName);

  // handle click on checkbox of selected plot to enable plot selection
  const handleSelectPlot = (e) => {
    if (e.target.checked) {
      setEnableSelectedPlots(true);
      drawnItemsConst.eachLayer((layer) => {
        const filtered1 = plotDetailsConst.filter((plot) => {
          return plot.leafletId.toString() == layer._leaflet_id;
        });
        if (filtered1.length > 0) {
          const filterPopup = popupLayersConst.filter((plot) => {
            return plot.plotId.toString() == filtered1[0]?.plotId.toString();
          });

          if (filterPopup.length > 0) {
            filterPopup[0].popup.removeFrom(mapLayout);
            setPopupLayers((prevArray) =>
              prevArray.filter(
                (plot) => plot.plotId.toString() !== filterPopup[0].plotId.toString()
              )
            );
          }

          addCheckboxPopupOnPlot(
            layer,
            false,
            filtered1[0]?.plotId,
            "Row" + filtered1[0]?.Row,
            "Col" + filtered1[0]?.Col
          );
        }
      });

      checkBoxes = document.querySelectorAll(".toggleSwitch");
      checkBoxes.forEach((checkBox) => {
        checkBox.removeEventListener("change", (e) => {
          selectPlots(e);
        });
      });

      checkBoxes.forEach((checkBox) => {
        checkBox.addEventListener("change", (e) => {
          selectPlots(e);
        });
      });
    } else {
      newArray = [];
      setSelectedCheckboxes([]);
      setSelectedPlots([]);
      mapLayout.eachLayer((layer) => {
        if (layer instanceof L.Popup) {
          mapLayout.removeLayer(layer);
        }
      });
      setPopupLayers([]);
      setEnableSelectedPlots(false);
      setSelectAll(false);
      rotationItems.length = 0;
    }
  };

  // save submit tooltip
  const saveSubmitTooltip = (
    <Tooltip id="tooltip" className="save-tooltip">
      <div className="col-1">
        <span>
          <img src={infoNewIcon} height={24} width={24} color="black" alt="" />
        </span>
      </div>
      <div className="col-10" style={{ paddingLeft: "0px" }}>
        <div className="tooltip-save-text">
          <strong>Save -</strong> Save will save as draft only.
        </div>
        <div className="tooltip-submit-text">
          <strong>Submit -</strong> It will save and submit as a layout.
        </div>
      </div>
      <div className="col-2">
        <span>
          <img
            src={crossIcon}
            height={24}
            width={24}
            color="black"
            alt=""
            className="cursor-pointer"
            onClick={() => {
              setEditSavetooltip(false);
            }}
          />
        </span>
      </div>
    </Tooltip>
  );

  // treatmet tooltip
  const renderTooltip = (props) => (
    <Tooltip id="tooltip" {...props} className="custom-tooltip background-air-force-color">
      <span className="tooltip-style ">Treatments are populated from left to right direction</span>
    </Tooltip>
  );

  // reps tooltip
  const renderTooltipReps = (props) => (
    <Tooltip id="tooltip" {...props} className="custom-tooltip">
      <span className="tooltip-style">Replicates are populated from bottom to top direction.</span>
    </Tooltip>
  );

  // x distance tooltip
  const renderTooltipXDistance = (props) => (
    <Tooltip id="tooltip" {...props} className="custom-tooltip">
      <span className="tooltip-style">Left/Right alley between the assessed area</span>
    </Tooltip>
  );

  // y distance tooltip
  const renderTooltipYDistance = (props) => (
    <Tooltip id="tooltip" {...props} className="custom-tooltip">
      <span className="tooltip-style">Up/Down alley between the assessed area</span>
    </Tooltip>
  );

  // plot length and width tooltip
  const renderTooltipPlot = (props) => (
    <Tooltip id="tooltip" {...props} className="custom-tooltip">
      <span className="tooltip-style ">
        Use these to specify the assessed area you want drone data results for
      </span>
    </Tooltip>
  );

    // plot length and width tooltip
    const renderTooltipSlider = (props) => (
      <Tooltip id="tooltip" {...props} className="custom-tooltip">
        <span className="tooltip-style ">
          Please enter degree ranges between 0 and 360
        </span>
      </Tooltip>
    );

  // Edit mode plot length and width tooltip
  const renderTooltipManualPlot = (props) => (
    <Tooltip id="tooltip" {...props} className="custom-tooltip ml-3">
      <span className="tooltip-style">
        Use these to specify the assessed area for selected plots
      </span>
    </Tooltip>
  );

  // edit mode x distance tooltip
  const renderTooltipManualXDistance = (props) => (
    <Tooltip id="tooltip" {...props} className="custom-tooltip">
      <span className="tooltip-style">Left/Right alley distance between the selected plots</span>
    </Tooltip>
  );

  // edit mode y distance tooltip
  const renderTooltipManualYDistance = (props) => (
    <Tooltip id="tooltip" {...props} className="custom-tooltip">
      <span className="tooltip-style">Up/Down alley distance between the selected plots</span>
    </Tooltip>
  );

  // modify values to have two decimal points only
  const formatToDecimalValues = (value) => {
    const num = parseFloat(value);
    if (!isNaN(num)) {
      if (Number.isInteger(num)) {
        return num.toString();
      } else {
        return num.toFixed(2);
      }
    }
    return value;
  };

  // handle x distance value validation
  const handleXDistance = (event) => {
    let regExp = /^\d+(\.\d{1,2})?$/;
    if (event.target.value === "" || regExp.test(event.target.value)) {
      setXDistance(event.target.value);
    } else {
      return;
    }
  };

  // handle y distance value validation
  const handleYDistance = (event) => {
    let regExp = /^\d+(\.\d{1,2})?$/;
    if (event.target.value === "" || regExp.test(event.target.value)) {
      setYDistance(event.target.value);
    } else {
      return;
    }
  };

  // handle x distance value validation
  const handlePlotLength = (event) => {
    let regExp = /^\d+(\.\d{1,2})?$/;
    if (event.target.value === "" || regExp.test(event.target.value)) {
      setManualPlotLength(event.target.value);
    } else {
      return;
    }
  };

  // handle y distance value validation
  const handlePlotWidth = (event) => {
    let regExp = /^\d+(\.\d{1,2})?$/;
    if (event.target.value === "" || regExp.test(event.target.value)) {
      setManualPlotWidth(event.target.value);
    } else {
      return;
    }
  };

  //Validate range and rotate plots
  const handleAngleRotate = (e) => {
    if(e.key === "Enter" && e.target.value >= 0 && e.target.value <= 360){      
      rotatePlots(e);
      }
      else{       
        return;
      } 
  };

  // Update value in the input box when blur
  const updateInput = (e) =>{
    e.target.value = getProperAngle(angleValue);
  }

  // Get proper positive angle when negative value appears
  const getProperAngle = (angle) => {
    let input = Number(angle);
    input = input < 0 ? input + 360 : input;
    return input;
  }

  // Handle degrees as only numbers
  const handleAngleInput = (e) => {
    let regExp = /^[0-9]{1,3}$/;  
    if(e.target.value !== "" && regExp.test(e.target.value))  {      
      handleAngleRotate(e); 
    }else{
      e.target.value = "";   
    }
  };

  const checkIfPlotNumberOrTreatmentNumberExist = () => {
    if (
      plotDetails.some((item) => item.treatmentNumber) ||
      plotDetails.some((item) => item.plotNumber)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleFlightDateChange = (value) => {
    setSelectedFlightDate(value);
    setDataLoader(true);
    fetchRasterLayers(value);
    setFlightContainerVisible(false);
    setYear(value?.split("-")[0]);
    setSelectedYear(value?.split("-")[0]);
  };
  const fetchRasterLayers = async (newDate) => {
    const id = flightDates[newDate];
    const queryParams = new URLSearchParams(window.location.search);
    const tri = queryParams.get("trial");
    let path = `/ploteditor?collection_id=${id}&trial=${tri}`;
    navigate(path, {
      search: "?collection_id=${id}"
    });

    return new Promise(() => {
      getRasterLayersAPICall(
        id,
        (res) => {
          setCollectionData({ ...collectionDataConst, ...res });
          setTimeout(() => {
            generateRasterLayers();
            setDataLoader(false);
          }, 100);
        },
        (err) => {
          console.log("err", err);
          setDataLoader(false);
        }
      );
    });
  };

  const flightDropdown = () => {
    setFlightContainerVisible(!flightContainerVisible);
  };

  useEffect(() => {
    const handleClickOutsideFlightDates = (event) => {
      if (
        flightDatesContainerRef.current &&
        !flightDatesContainerRef.current.contains(event.target) &&
        !flightDateRef.current.contains(event.target)
      ) {
        setFlightContainerVisible(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutsideFlightDates);
    return () => {
      window.removeEventListener("mousedown", handleClickOutsideFlightDates);
    };
  }, []);
  const savedAsTag = isLayoutSavedAs ? "Saved as" : "Saved";

  const handleEmptyChange = () => {
    setTrial("");
    setDraftSearchResult("");
    setCloneSearchResult("");
    setDraftedItemsList(draftItems);
    setSumbittedItemsList(submittedItems);
  };

  const filterDraftedLayout = (e) => {
    const value = e.target.value;
    setDraftSearchResult(value);
    const filteredItems = filtereddraftItemsList?.filter((item) =>
      item.layout.toLowerCase().includes(value.toLowerCase()) || item.last_modified_by.toLowerCase().includes(value.toLowerCase())
    );
    const filteredSubmittedItems = filteredsubmittedItemsList?.filter((item) =>
      item.layout.toLowerCase().includes(value.toLowerCase()) || item.last_modified_by.toLowerCase().includes(value.toLowerCase())
    );
    setDraftedItemsList(filteredItems);
    setSumbittedItemsList(filteredSubmittedItems);
  };
  return (
    <>
      {loading ? (
        <div className="center-spinner-full-height">
          <Spinner animation="border" className="custom-spinner" />
        </div>
      ) : (
        <div
          className="root edit-viewer"
          style={{
            height: "100vh"
          }}>
          {!(isMobile || isMobileLandscape || isTablet || isSmallDevice) ? (
            <DesktopTopBar
              protocolName={collectionArray?.[0]?.protocol?.protocolId || ""}
              viewAPIData={viewAPIData}
              cropData={[]}
              pestData={[]}
              selectedOption={""}
              trail={trail}
              selectedFlightDate={selectedFlightDate}
              isEditViewer
            />
          ) : (
            <MobileTopBar
              protocolName={collectionArray?.[0]?.protocol?.protocolId || ""}
              viewAPIData={viewAPIData}
              cropData={[]}
              pestData={[]}
              selectedOption={""}
              trail={trail}
              selectedFlightDate={selectedFlightDate}
              isEditViewer
            />
          )}
          <div style={{ position: "fixed", top: "60px", right: 0, left: 0, bottom: 0 }}>
            {(dataLoader || suggestionLoader) && (
              <div className="center-spinner new-layout-loader">
                <Spinner animation="border" className="date-spinner" />
              </div>
            )}
            <div
              className="row justify-content-between bg-white flex-nowrap align-items-center"
              style={{
                height: "60px",
                zIndex: "99999",
                position: "relative",
                boxShadow: "0 0 4px rgba(0, 0, 0, 0.3)"
              }}>
              <div className="col-1 pr-0 pl-1">{yearDropDown()}</div>
              <div style={{ borderRight: "1px solid #dddddd", height: "100%" }}>
                <div
                  style={{
                    fontWeight: "700",
                    fontSize: "10px",
                    color: "#0D1A40",
                    marginLeft: "12px",
                    marginTop: "7px",
                    marginBottom: "8px"
                  }}>
                  Layouts
                </div>
                <div>
                  <Dropdown
                    show={layoutDetailsOpen}
                    onToggle={handleLayoutToggle}
                    style={{ marginRight: "10px" }}>
                    <Dropdown.Toggle className="edit-viewer-dropdown-toggle ">
                      <div
                        className="d-flex justify-content-between"
                        style={{ position: "relative", top: "-7px", color: "#0D1A40" }}>
                        <span style={{ fontWeight: selectedTrial !== "Trial Id" && 700 }}>
                          {selectedTrial &&
                            (selectedLayoutBtn === "new" || selectedLayoutBtn === "clone")
                            ? selectedTrial
                            : addTrial &&
                              (selectedLayoutBtn === "new" || selectedLayoutBtn === "clone")
                              ? addTrial
                              : selectedLayoutNameForCount && selectedLayoutBtn === "drafted"
                                ? selectedLayoutNameForCount
                                : "Click to Create / Select"}
                        </span>

                        <span className=" deep-cove-color">
                          <ChevronDown width="16px" height="16px" color="#0D1A40" />
                        </span>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className={
                        selectedLayoutBtn === "new"
                          ? "edit-viewer-draft-dropdodwn-menu"
                          : "edit-viewer-dropdodwn-menu"
                      }>
                      <div className="edit-viewer-dropdown-item">
                        <div>
                          <div className="d-flex" style={{ marginLeft: "12px" }}>
                            <div style={{ marginRight: "55px" }}>
                              <input
                                type="radio"
                                id="createNew"
                                onClick={() => {
                                  setSelectedLayoutBtn("new");
                                  handleEmptyChange();
                                  setInputValues({
                                    plotLength: "",
                                    plotWidth: "",
                                    plotDirection: "",
                                    noOfTreatments: "",
                                    noOfReps: "",
                                    trmtDistance: "",
                                    repDistance: ""
                                  });
                                  setSaveDisable(true);
                                  setSubmitDisable(true);
                                  clearAllDrawnLayers();
                                  setSelectedLayoutId("");
                                  setSelectedTrial("");
                                  setAddTrial("");
                                }}
                                checked={selectedLayoutBtn === "new"}
                              />
                              <label
                                className="edit-viewer-newlayouts"
                                htmlFor="createNew"
                                style={{
                                  cursor: "pointer"
                                }}>
                                Create New
                              </label>
                            </div>
                            <div
                              style={{
                                opacity:
                                  draftedItemsList?.length === 0 &&
                                    sumbittedItemsList?.length === 0 &&
                                    draftSearchResult?.length === 0
                                    ? 0.1
                                    : 1
                              }}>
                              <input
                                type="radio"
                                id="Drafted-Submitted"
                                checked={selectedLayoutBtn === "drafted"}
                                onClick={() => {
                                  setSelectedLayoutBtn("drafted");
                                  setSelectedLayoutId("");
                                  setSelectedLayoutNameForCount("");
                                  setTrial("");
                                  setSaveDisable(true);
                                  setSubmitDisable(true);
                                }}
                                disabled={
                                  draftedItemsList?.length === 0 && sumbittedItemsList?.length === 0
                                }
                              />
                              <label
                                className="edit-viewer-newlayouts"
                                htmlFor="Drafted-Submitted"
                                style={{
                                  cursor: "pointer"
                                }}>
                                Drafted / Submitted
                              </label>
                            </div>
                          </div>
                          <div
                            style={{
                              marginLeft: "35px",
                              marginTop: "10px",
                              opacity:
                                ((!selectedTrial || selectedTrial === "") &&
                                  (!addTrial || addTrial === "")) ||
                                  selectedLayoutBtn === "drafted"
                                  ? 0.1
                                  : 1
                            }}>
                            <input
                              type="radio"
                              checked={selectedLayoutBtn === "clone"}
                              id="clone"
                              onClick={() => {
                                setSelectedLayoutBtn("clone");
                              }}
                              disabled={
                                ((!selectedTrial || selectedTrial === "") &&
                                  (!addTrial || addTrial === "")) ||
                                selectedLayoutBtn === "drafted"
                              }
                            />
                            <label
                              className="edit-viewer-newlayouts"
                              htmlFor="clone"
                              style={{
                                cursor: "pointer"
                              }}>
                              Clone
                            </label>
                          </div>
                          <div
                            style={{
                              marginTop: "8px",
                              marginBottom: selectedLayoutBtn === "new" ? "2px" : "4px",
                              position: "relative"
                            }}>
                            <input
                              style={{
                                width: selectedLayoutBtn === "new" ? "325px" : "375px",
                                backgroundColor: "#EDEDED"
                              }}
                              type="text"
                              className="edit-viewer-search-input"
                              placeholder={
                                selectedLayoutBtn === "new"
                                  ? "Search for Trial to create"
                                  : selectedLayoutBtn === "drafted"
                                    ? "Search for Drafted / Submitted / User"
                                    : "Search for Layout to Clone"
                              }
                              value={
                                selectedLayoutBtn === "new"
                                  ? trial
                                  : selectedLayoutBtn === "drafted"
                                    ? draftSearchResult
                                    : selectedLayoutBtn === "clone"
                                      ? cloneSearchResult
                                      : ""
                              }
                              onChange={(e) => {
                                if (selectedLayoutBtn === "drafted") {
                                  filterDraftedLayout(e);
                                }
                                if (selectedLayoutBtn === "clone") {
                                  setCloneSearchResult(e.target.value);
                                  filterDraftedLayout(e);
                                }
                                setTrial(e.target.value);
                                if (selectedLayoutBtn === "new") {
                                  handleTrialChange(e);
                                }
                              }}
                              ref={inputRef}
                            />
                            <span
                              style={{
                                position: "absolute",
                                top: "13px",
                                right: selectedLayoutBtn === "new" ? "3% " : "14%"
                              }}>
                              {trial.length > 0 ||
                                draftSearchResult.length > 0 ||
                                cloneSearchResult?.length > 0 ? (
                                <X
                                  onClick={handleEmptyChange}
                                  width={24}
                                  height={24}
                                  fill="#0D1A40"
                                  className="cursor-pointer"
                                />
                              ) : (
                                <Search onClick={() => { }} width={16} height={16} fill="#0D1A40" />
                              )}
                            </span>
                          </div>
                          <div className="edit-dropdown-item">
                            {(selectedLayoutBtn === "drafted" || selectedLayoutBtn === "clone") && (
                              <div>
                                {draftedItemsList?.length === 0 &&
                                  sumbittedItemsList?.length === 0 && (
                                    <div className="d-flex mt-1 mb-3">
                                      <ExclamationTriangleFill
                                        className="exclamation-triangle-fill"
                                        width={24}
                                        height={24}
                                      />
                                      <span
                                        className="light-red d-flex"
                                        style={{
                                          fontSize: "12px",
                                          font: "Helvetica",
                                          fontWeight: 400,
                                          alignItems: "end"
                                        }}>
                                        &nbsp; Layout not found for
                                        {` '${draftSearchResult}' `}
                                      </span>
                                    </div>
                                  )}
                                <div className="dropdownitem-heading">Draft Layouts</div>
                                <Container>
                                  {draftedItemsList
                                    ?.sort(
                                      (a, b) =>
                                        dateConverter(b.updated_at) - dateConverter(a.updated_at)
                                    )
                                    ?.map(
                                      (item) =>
                                        !item?.is_deleted && (
                                          <Row key={item?.layout_id} className="edit-menu">
                                            <Col
                                              className="col-5 d-flex align-items-center pr-0 pl-0"
                                              style={{ flexWrap: "nowrap" }}>
                                              <input
                                                name="layoutList"
                                                value={item?.layout_id}
                                                onChange={() => {
                                                  selectedLayoutBtn === "clone"
                                                    ? (cloneLayout(item?.layout_id),
                                                      // setSelectedLayoutBtn("new"),
                                                      setCloneSearchResult(""),
                                                      setSelectedLayoutId(item?.layout_id),
                                                      setTrial(""))
                                                    : handleLayoutSelection(
                                                      item?.layout_id,
                                                      "Draft",
                                                      item?.layout
                                                    );
                                                }}
                                                checked={selectedLayoutId == item?.layout_id}
                                                type="radio"
                                                id={`draft-layoutList-${item?.layout_id}`}
                                              />
                                              <label
                                                className="edit-menu-name deep-cove-color"
                                                style={{cursor: "pointer"}}
                                                htmlFor={`draft-layoutList-${item?.layout_id}`}
                                              >
                                                {item?.layout}
                                              </label>
                                            </Col>
                                            <Col className="col-3 edit-data pl-2 pr-2">
                                              {item?.last_modified_by}
                                            </Col>
                                            <Col className="col-3 edit-data pl-1 pr-1">
                                              {formatFlightDateForLayouts(
                                                item?.updated_at?.split(" ")[0]
                                              )}
                                            </Col>{" "}
                                            <>
                                              {item?.notes != "" &&
                                                selectedLayoutBtn !== "clone" ? (
                                                <button
                                                  onClick={() => handleDraftNotes(item?.layout_id)}
                                                  style={{
                                                    border: "none",
                                                    background:
                                                      draftNotes === item?.layout_id
                                                        ? "#FFE8BC"
                                                        : "white",
                                                    borderRadius: "20px",
                                                    marginLeft: "-15px"
                                                  }}>
                                                  <ChatLeftText
                                                    color="#0D1A40"
                                                    width={16}
                                                    height={16}
                                                  />
                                                </button>
                                              ) : (
                                                <div style={{ width: "24px" }}></div>
                                              )}

                                              <span>
                                                {selectedLayoutId == item?.layout_id &&
                                                  selectedLayoutBtn !== "clone" ? (
                                                  <button
                                                    onClick={() => {
                                                      setDeleteTrialDetails({
                                                        id: item?.layout_id,
                                                        name: item?.layout
                                                      });
                                                      setShowDeleteModal(true);
                                                    }}
                                                    style={{
                                                      border: "none",
                                                      background: "transparent",
                                                      marginLeft:
                                                        item?.notes === "" ? "-14px" : "-3px",
                                                      marginBottom: "3px"
                                                    }}>
                                                    <Trash color="#0D1A40" width={16} height={16} />
                                                  </button>
                                                ) : (
                                                  <div style={{ width: "24px" }}></div>
                                                )}
                                              </span>
                                            </>
                                            {draftNotes === item?.layout_id &&
                                              selectedLayoutBtn !== "clone" && (
                                                <div className="padding-notes-container">
                                                  <span className="notes-title">Notes</span>
                                                  <div className="notes-text">
                                                    {item?.notes ? item?.notes : " "}
                                                  </div>
                                                </div>
                                              )}
                                          </Row>
                                        )
                                    )}
                                </Container>
                                <hr />
                                <div className="dropdownitem-heading ">Submitted Layouts</div>
                                <Container>
                                  {sumbittedItemsList
                                    ?.sort((a, b) => {
                                      if (
                                        dateConverter(a.updated_at) < dateConverter(b.updated_at)
                                      ) {
                                        return 1;
                                      }
                                      if (
                                        dateConverter(a.updated_at) > dateConverter(b.updated_at)
                                      ) {
                                        return -1;
                                      }
                                      return 0;
                                    })
                                    ?.map(
                                      (item) =>
                                        !item?.is_deleted && (
                                          <Row key={item?.layout_id} className="edit-menu">
                                            <Col
                                              className="col-5 d-flex align-items-center pr-1 pl-1"
                                              style={{ flexWrap: "nowrap" }}>
                                              <input
                                                name="layoutList"
                                                onChange={() => {
                                                  handleLayoutSelection(
                                                    item?.layout_id,
                                                    "Submitted",
                                                    item?.layout
                                                  );
                                                }}
                                                checked={selectedLayoutId == item?.layout_id}
                                                value={item?.layout_id}
                                                type="radio"
                                                id={`submited-layout-${item?.layout_id}`}
                                              />
                                              <label
                                                className="edit-menu-name deep-cove-color"
                                                style={{cursor: "pointer"}}
                                                htmlFor={`submited-layout-${item?.layout_id}`}
                                              >
                                                {item?.layout}
                                              </label>
                                            </Col>
                                            <Col className="col-3 edit-data pl-2 pr-2">
                                              {item?.last_modified_by}
                                            </Col>

                                            <Col className="col-3 edit-data pl-1 pr-1">
                                              {formatFlightDateForLayouts(
                                                item?.updated_at?.split(" ")[0]
                                              )}
                                            </Col>

                                            {item?.notes != "" && selectedLayoutBtn !== "clone" ? (
                                              <button
                                                onClick={() =>
                                                  handleSubmittedNotes(item?.layout_id)
                                                }
                                                style={{
                                                  border: "none",
                                                  background:
                                                    submittedNotes === item?.layout_id
                                                      ? "#FFE8BC"
                                                      : "white",
                                                  borderRadius: "20px",
                                                  marginLeft: "-9px"
                                                }}>
                                                <ChatLeftText
                                                  color="#0D1A40"
                                                  width={16}
                                                  height={16}
                                                />
                                              </button>
                                            ) : (
                                              <div style={{ width: "24px" }}></div>
                                            )}

                                            {submittedNotes === item?.layout_id &&
                                              selectedLayoutBtn !== "clone" && (
                                                <div className="padding-notes-container">
                                                  <span className="notes-title">Notes</span>

                                                  <div className="notes-text">
                                                    {item?.notes ? item?.notes : " "}
                                                  </div>
                                                </div>
                                              )}
                                          </Row>
                                        )
                                    )}
                                </Container>
                              </div>
                            )}
                            {selectedLayoutBtn === "new" && trialAutoCorrect()}
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  popperConfig={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 0]
                        }
                      }
                    ]
                  }}
                  overlay={renderTooltipPlot}>
                  <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
                    <div>
                      <div
                        className={`editor-input-fields-label  ${enableselecteddraftitems ? "disabled" : ""
                          }`}>
                        <span>Plot Length (m)</span>
                      </div>
                      <input
                        className="edit-viewer-inputs"
                        type="number"
                        name="plotLength"
                        onChange={handleInputChange}
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                        }
                        value={inputValues.plotLength}
                        disabled={enableselecteddraftitems}
                        style={{
                          backgroundColor: enableselecteddraftitems ? "#DDDDDD" : "white",
                          borderColor: enableselecteddraftitems ? "" : "#0D1A40!important",
                          border: enableselecteddraftitems ? "1px solid #0D1A40" : "",
                          opacity: enableselecteddraftitems ? ".1" : "1"
                        }}
                      />
                    </div>
                    <div>
                      <div
                        className={`editor-input-fields-label  ${enableselecteddraftitems ? "disabled" : ""
                          }`}>
                        <span>Plot Width (m)</span>
                      </div>
                      <input
                        className="edit-viewer-inputs "
                        type="number"
                        name="plotWidth"
                        onChange={handleInputChange}
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                        }
                        value={inputValues.plotWidth}
                        disabled={enableselecteddraftitems}
                        style={{
                          backgroundColor: enableselecteddraftitems ? "#DDDDDD" : "white",
                          borderColor: enableselecteddraftitems ? "" : "#0D1A40!important",
                          border: enableselecteddraftitems ? "1px solid #0D1A40" : "",
                          opacity: enableselecteddraftitems ? ".1" : "1"
                        }}
                      />
                    </div>
                  </div>
                </OverlayTrigger>
              </>

              <div>
                <button
                  type="button"
                  className="editor-buttons"
                  disabled={disableButtons || enableselecteddraftitems}
                  style={{
                    backgroundColor:
                      disableButtons || enableselecteddraftitems ? "#DDDDDD" : "white",
                    borderColor:
                      disableButtons || enableselecteddraftitems ? "" : "#0D1A40!important",
                    border:
                      disableButtons || enableselecteddraftitems
                        ? "1px solid #0D1A40"
                        : "1px solid #0D1A40 ",
                    opacity: disableButtons || enableselecteddraftitems ? ".1" : "1"
                  }}
                  onClick={() =>
                    inputValues.plotLength != 0 &&
                    inputValues.plotWidth != 0 &&
                    inputValues.plotLength &&
                    inputValues.plotWidth &&
                    drawBasePLot(inputValues.plotLength, inputValues.plotWidth)
                  }>
                  <span
                    style={{
                      color: disableButtons || enableselecteddraftitems ? "#0D1A40" : "#0D1A40",
                      opacity: disableButtons || enableselecteddraftitems ? ".5" : "1",
                      fontWeight: "700"
                    }}>
                    Base Plot
                  </span>
                </button>
              </div>
              <OverlayTrigger
                placement="bottom"
                popperConfig={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, 0]
                      }
                    }
                  ]
                }}
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}>
                <div>
                  <div
                    className={`editor-input-fields-label  ${enableselecteddraftitems ? "disabled" : ""
                      }`}>
                    <span>No. of Columns</span>
                  </div>
                  <input
                    className="edit-viewer-inputs "
                    type="number"
                    name="noOfTreatments"
                    onChange={handleInputChange}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    value={inputValues.noOfTreatments}
                    disabled={enableselecteddraftitems}
                    style={{
                      backgroundColor: enableselecteddraftitems ? "#DDDDDD" : "white",
                      borderColor: enableselecteddraftitems ? "" : "#0D1A40!important",
                      border: enableselecteddraftitems ? "1px solid #0D1A40" : "",
                      opacity: enableselecteddraftitems ? ".1" : "1"
                    }}
                  />
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                popperConfig={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, 0]
                      }
                    }
                  ]
                }}
                overlay={renderTooltipReps}>
                <div>
                  <div
                    className={`editor-input-fields-label  ${enableselecteddraftitems ? "disabled" : ""
                      }`}>
                    <span>No. of Rows</span>
                  </div>
                  <input
                    className="edit-viewer-inputs "
                    type="number"
                    name="noOfReps"
                    onChange={handleInputChange}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    value={inputValues.noOfReps}
                    disabled={enableselecteddraftitems}
                    style={{
                      backgroundColor: enableselecteddraftitems ? "#DDDDDD" : "white",
                      borderColor: enableselecteddraftitems ? "" : "#0D1A40!important",
                      border: enableselecteddraftitems ? "1px solid #0D1A40" : "",
                      opacity: enableselecteddraftitems ? ".1" : "1"
                    }}
                  />
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                popperConfig={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, 0]
                      }
                    }
                  ]
                }}
                overlay={renderTooltipXDistance}>
                <div>
                  <div
                    className={`editor-input-fields-label  ${enableselecteddraftitems ? "disabled" : ""
                      }`}>
                    <span>X Distance (m)</span>
                  </div>
                  <input
                    className="edit-viewer-inputs "
                    type="number"
                    name="trmtDistance"
                    onChange={handleInputChange}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    value={inputValues.trmtDistance}
                    disabled={enableselecteddraftitems}
                    style={{
                      width: "70px",
                      backgroundColor: enableselecteddraftitems ? "#DDDDDD" : "white",
                      borderColor: enableselecteddraftitems ? "" : "#0D1A40!important",
                      border: enableselecteddraftitems ? "1px solid #0D1A40" : "",
                      opacity: enableselecteddraftitems ? ".1" : "1"
                    }}
                  />
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                popperConfig={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, 0]
                      }
                    }
                  ]
                }}
                overlay={renderTooltipYDistance}>
                <div>
                  <div
                    className={`editor-input-fields-label  ${enableselecteddraftitems ? "disabled" : ""
                      }`}>
                    <span>Y Distance (m)</span>
                  </div>
                  <input
                    className="edit-viewer-inputs "
                    type="number"
                    name="repDistance"
                    onChange={handleInputChange}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    value={inputValues.repDistance}
                    disabled={enableselecteddraftitems}
                    style={{
                      width: "70px",
                      backgroundColor: enableselecteddraftitems ? "#DDDDDD" : "white",
                      borderColor: enableselecteddraftitems ? "" : "#0D1A40!important",
                      border: enableselecteddraftitems ? "1px solid #0D1A40" : "",
                      opacity: enableselecteddraftitems ? ".1" : "1"
                    }}
                  />
                </div>
              </OverlayTrigger>

              <div>
                <button
                  type="button"
                  disabled={enableselecteddraftitems}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    marginTop: "18px",
                    opacity: enableselecteddraftitems ? ".1" : "1"
                  }}
                  onClick={() => {
                    if (selectedTrial) {
                      setInputValues({
                        ...inputValues,
                        noOfTreatments: plotDetailsFromAPI?.structure?.columnsNumber,
                        noOfReps: plotDetailsFromAPI?.structure?.blocksNumber,
                        plotWidth: formatToDecimalValues(plotDetailsFromAPI.geometry?.plotWidth),
                        plotLength: formatToDecimalValues(plotDetailsFromAPI.geometry?.plotLength),
                        trmtDistance: "",
                        repDistance: ""
                      });
                    } else if (addTrial) {
                      setInputValues({
                        plotLength: "",
                        plotWidth: "",
                        plotDirection: "",
                        noOfTreatments: "",
                        noOfReps: "",
                        trmtDistance: "",
                        repDistance: ""
                      });
                    }
                  }}>
                  <img src={startNewIcon} height={18} width={18} alt="" />
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="editor-buttons"
                  disabled={disableButtons || enableselecteddraftitems || layerState.length === 0}
                  style={{
                    backgroundColor:
                      disableButtons || enableselecteddraftitems || layerState.length === 0
                        ? "#DDDDDD"
                        : "white",
                    borderColor:
                      disableButtons || enableselecteddraftitems || layerState.length === 0
                        ? ""
                        : "#0D1A40!important",
                    border:
                      disableButtons || enableselecteddraftitems || layerState.length === 0
                        ? "1px solid #0D1A40"
                        : "1px solid #0D1A40",
                    opacity:
                      disableButtons || enableselecteddraftitems || layerState.length === 0
                        ? ".1"
                        : "1"
                  }}
                  onClick={() => {
                    setSelectedCheckboxes([]);
                    clearAllDrawnLayersExceptFirst();
                    generatePlots();
                    setDisableButtons(false);
                    setSaveDisable(false);
                    setSubmitDisable(false);
                    setEditSavetooltip(true);
                  }}>
                  <span
                    style={{
                      color: "#0D1A40",
                      opacity:
                        disableButtons || enableselecteddraftitems || layerState.length === 0
                          ? ".5"
                          : "1",
                      fontWeight: "700"
                    }}>
                    Generate Plot
                  </span>
                </button>
              </div>
              <div>
                <OverlayTrigger
                  placement="top"
                  overlay={saveSubmitTooltip}
                  show={(!saveDisable || !submitDisable) && editSavetooltip}
                  trigger={["hover", "focus"]}>
                  <button
                    type="button"
                    className="editor-buttons "
                    disabled={saveDisable}
                    style={{
                      backgroundColor: saveDisable ? "#DDDDDD" : "white",
                      borderColor: saveDisable ? " " : "#0D1A40!important",
                      border: "1px solid #0D1A40 ",
                      opacity: saveDisable ? ".1" : "1 "
                    }}
                    onClick={() => {
                      setSubmitType("save");
                      setShowSaveModal(true);
                    }}>
                    <span
                      style={{
                        color: "#0D1A40",
                        opacity: saveDisable ? ".5" : "1",
                        fontWeight: "700"
                      }}>
                      Save
                    </span>
                  </button>
                </OverlayTrigger>
              </div>
              <div style={{ marginRight: "10px" }}>
                <button
                  type="button"
                  className="new-editor-buttons"
                  disabled={submitDisable}
                  style={{
                    backgroundColor: "#E08330",
                    opacity: submitDisable ? ".1" : "1",
                    fontWeight: "700"
                  }}
                  onClick={() => {
                    setSubmitType("submit");
                    setShowSaveModal(true);
                  }}>
                  SUBMIT
                </button>
              </div>
            </div>
            <DesktopFlightDropdown
              flightDropdown={flightDropdown}
              flightContainerVisible={flightContainerVisible}
              flightDatesContainerRef={flightDatesContainerRef}
              selectedFlightDate={selectedFlightDate}
              flightDates={flightDates}
              flightDateRef={flightDateRef}
              handleFlightOption={handleFlightDateChange}
              isEditViewer={true}
              isDisabled={disableButtons}
            />
            <Row style={{ position: "relative", height: "100%" }}>
              <Col
                className="pr-0"
                xs={showSupplyPlotDetails ? 7 : 12}
                style={{
                  width: "100%",
                  position: "none",
                  height: "100%",
                  borderLeft: "1px solid rgb(237, 237, 237)"
                }}>
                <div
                  id="map"
                  className={`leaflet-container leaflet-retina editor-map ${showSupplyPlotDetails ? "editor-map-to-left" : ""
                    }`}>
                  {plotDetails.length > 1 && (
                    <div
                      style={{
                        display: "flex",
                        position: "absolute",
                        alignItems: "center",
                        maxWidth: "270px",
                        borderRadius: "5px",
                        height: "32px",
                        left: "48px",
                        top: "15px",
                        background: "#FFFFFF",
                        zIndex: 999
                      }}>
                      <div style={{ display: "flex" }}>
                        <Form.Check
                          className="plot-checkbox"
                          key="Plot ID"
                          type="checkbox"
                          label="Plot ID"
                          id="Plot ID"
                          checked={selectedBoxes.includes("Plot ID")}
                          onChange={() => handleCheckBoxes("Plot ID")}
                        />
                        {plotDetails.some((item) => item.plotNumber) && (
                          <Form.Check
                            className="plot-checkbox"
                            key="Plot #"
                            type="checkbox"
                            label="Plot #"
                            id="Plot #"
                            checked={selectedBoxes.includes("Plot #")}
                            onChange={() => handleCheckBoxes("Plot #")}
                          />
                        )}

                        {plotDetails.some((item) => item.treatmentNumber) && (
                          <Form.Check
                            className="plot-checkbox"
                            key="Treatment #"
                            type="checkbox"
                            label="Treatment #"
                            id="Treatment #"
                            checked={selectedBoxes.includes("Treatment #")}
                            onChange={() => handleCheckBoxes("Treatment #")}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
                  <Button title="Measurement"
                    className={`d-flex p-1 justify-content-center align-items-center edit-measure-icon measurement-icon ${measurementToolVisible ? "isGrey" : "isWhite"
                      } ${showSupplyPlotDetails ? "edit-measure-icon-left" : ""}`}
                    onClick={() => switchMeasurementTool(!measurementToolVisible)}>
                    <img src={measurementIcon} width={20} />
                  </Button>
                <div className="custom-dropdown">
                  {isSelectPlot ? (
                    <div
                      className="select-plot-button position-absolute"
                      style={{
                        right: showSupplyPlotDetails ? "30px" : "180px"
                      }}>
                      <input
                        type="checkbox"
                        style={{
                          bottom: "1px",
                          left: "11px",
                          width: "16px",
                          height: "16px",
                          zIndex: 11
                        }}
                        onClick={handleSelectPlot}
                      />
                      <button
                        className="selecet-plot-inside-button btn bg-white isWhite"
                        id="multiSelectDropdown"
                        ref={plotsRef}
                        disabled={enableSelectedPlots === false}
                        onClick={dropDownShow}>
                        <span style={{ color: enableSelectedPlots === true ? "#0D1A40" : "" }}>
                          {" "}
                          Select Plots{" "}
                        </span>
                        <span style={{ color: "#293306", position: "absolute", right: "8px" }}>
                          <ChevronDown className="deep-cove-color" width="16px" height="16px" />
                        </span>
                      </button>
                    </div>
                  ) : null}
                  {selectedPlots?.length > 0 && (
                    <>
                      <div
                        className={`plots-distance-button position-absolute p-1 d-flex justify-content-center align-items-center ${showSupplyPlotDetails ? "plots-distance-button-left" : ""
                          }`}>
                        <strong className="pl-1">Distance (m)</strong>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          popperConfig={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, 0]
                                }
                              }
                            ]
                          }}
                          overlay={renderTooltipManualXDistance}>
                          <div className="d-flex align-items-center">
                            <span className="pl-2 pr-1 distance-label">X</span>
                            <input
                              className="edit-viewer-inputs distance"
                              type="number"
                              name="repDistance"
                              onChange={handleXDistance}
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                              }
                              value={xDistance}
                            />
                          </div>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          popperConfig={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, 0]
                                }
                              }
                            ]
                          }}
                          overlay={renderTooltipManualYDistance}>
                          <div className="d-flex align-items-center pr-1">
                            <span className="pl-2 pr-1 distance-label ">Y</span>
                            <input
                              className="edit-viewer-inputs distance"
                              type="number"
                              name="repDistance"
                              onChange={handleYDistance}
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                              }
                              value={yDistance}
                            />
                          </div>
                        </OverlayTrigger>
                        <button className="apply-button " onClick={applyManualDistance}>
                          Apply
                        </button>
                      </div>
                      <div
                        className={`plots-dimension-button position-absolute p-1 d-flex justify-content-center align-items-center ${showSupplyPlotDetails ? "plots-dimension-button-left" : ""
                          }`}>
                        <strong className="pl-1">Plot</strong>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          popperConfig={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, 0]
                                }
                              }
                            ]
                          }}
                          overlay={renderTooltipManualPlot}>
                          <div className="d-flex align-items-center px-2">
                            <span className="pl-2 distance-label pr-1 ">Length</span>
                            <input
                              className="edit-viewer-inputs distance"
                              type="number"
                              name="manualPlotLength"
                              onChange={handlePlotLength}
                              value={manualPlotLength}
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                              }
                            />
                            <span className="pl-2 distance-label pr-1 ">Width</span>
                            <input
                              className="edit-viewer-inputs distance"
                              type="number"
                              name="manualPlotWidth"
                              onChange={handlePlotWidth}
                              value={manualPlotWidth}
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                              }
                            />
                          </div>
                        </OverlayTrigger>
                        <button className="apply-button" onClick={applyManualDimension}>
                          Apply
                        </button>                       
                        </div>
                        <div className="plots-rotate-bar position-absolute p-2">
                          <div className="d-flex w-100 p-1">
                            <input id="sliderRange" type="range" min="-180" max="180" defaultValue={0} 
                            onChange={(e) => rotatePlots(e)} value={angleValue}></input>
                          </div>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            popperConfig={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, 0]
                                  }
                                }
                              ]
                            }}
                            overlay={renderTooltipSlider}>
                            <div className="d-flex">
                              <input
                                className="edit-viewer-inputs angle float-end"
                                type="text"
                                id="inputAngle"
                                maxLength="3"
                                autoComplete="off"
                                onKeyDown={handleAngleRotate}
                                onChange={handleAngleInput}                        
                                onBlur={updateInput}
                                onFocus={((e) => e.target.select())}
                              />                             
                              <label className="h6 sliderDegree" htmlFor="inputAngle">°</label>                              
                            </div>
                          </OverlayTrigger>
                        </div>
                        <button title="Undo" className="d-flex undo-redo-buttons undo-button-locate position-absolute"
                          disabled={disableUndo} onClick={undoPlots} style={{ opacity: disableUndo ? "0.5" : "1" }}>
                          <img src={undoIcon} height={28} width={28} />
                        </button>
                        <button title="Redo" className="d-flex undo-redo-buttons redo-button-locate position-absolute"
                          disabled={disableRedo} onClick={redoPlots} style={{ opacity: disableRedo ? "0.5" : "1" }}>
                          <img src={redoIcon} height={28} width={28} />
                        </button>
                      </>
                  )}
                  {isOpen ? (
                    <div
                      ref={selectPlotsRef}
                      className="selectplots-class"
                      style={{
                        right: showSupplyPlotDetails ? "30px" : "180px"
                      }}
                      aria-labelledby="multiSelectDropdown">
                      {selectPlotsList()}
                    </div>
                  ) : null}
                </div>
              </Col>

              {!showSupplyPlotDetails ? (
                plotDetails.length > 1 ? (
                  <button
                    className="supply-plot-details-button btn bg-white isWhite position-absolute "
                    onClick={() => {
                      setShowSupplyPlotDetails(true);
                      setTimeout(() => {
                        mapLayout?.invalidateSize();
                      }, 100);
                    }}>
                    Supply Plot Details
                  </button>
                ) : null
              ) : (
                <Col
                  className="pl-0"
                  xs={5}
                  style={{
                    width: "100%",
                    position: "none",
                    height: "100%"
                  }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      zIndex: 99,
                      backgroundColor: "white",
                      boxShadow: "0 0 4px rgba(0, 0, 0, 0.3)"
                    }}>
                    <div className="p-3 supply-plot-details-lable cursor-pointer">
                      <span className="text-left ">Supply Plot Details</span>
                      <span
                        className="text-right supply-plot-details-close-button "
                        onClick={() => {
                          setShowSupplyPlotDetails(false);
                          setTimeout(() => {
                            mapLayout?.invalidateSize();
                          }, 100);
                        }}>
                        CLOSE
                      </span>
                    </div>
                    <div className="row action-row p-3">
                      <div className="col-6 d-flex align-items-start pr-0">
                        <div className="steps-button mt-1 ">Step 1</div>
                        <div className="ml-2">
                          <img
                            src={downloadIcon}
                            className="mr-2 cursor-pointer"
                            alt=""
                            onClick={() => downloadUpdatedExcel(plotDetails)}
                            height={16}
                          />
                          <span
                            className="cursor-pointer action-label "
                            onClick={() => downloadUpdatedExcel(plotDetails)}>
                            Download EXCEL
                          </span>
                          <div className="download-info mt-1  ">
                            Please update <strong>column B “Plot # </strong>
                            {addTrial && (
                              <span>
                                and <strong> column C “Treatment #</strong>
                              </span>
                            )}
                            in EXCEL
                          </div>
                        </div>
                      </div>
                      <div className="col-6 d-flex align-items-start pr-1">
                        <div className="steps-button mt-1  ">Step 2</div>
                        <div className="ml-2">
                          <label className="cursor-pointer">
                            <img src={uploadIcon} className="mx-1 mr-2" alt="" height={16} />
                            <span className="action-label ">Upload & Review EXCEL</span>
                            <input
                              type="file"
                              onChange={handleFileChange}
                              className="d-none"
                              accept=".xls, .xlsx"
                            />
                          </label>
                          {uploadError && (
                            <div className="d-flex mt-1">
                              <ExclamationTriangleFill className="exclamation-triangle-fill" />{" "}
                              <span className="no-data-label ml-2 light-red ">
                                Data not found in EXCEL
                              </span>
                            </div>
                          )}
                          {fileName && (
                            <div className="d-flex mt-1">
                              <Paperclip
                                className="cursor-pointer"
                                onClick={() => downloadUpdatedExcel(plotDetails)}
                              />
                              <span
                                className="attachment-label px-2 cursor-pointer"
                                onClick={() => downloadUpdatedExcel(plotDetails)}>
                                {fileName}
                              </span>
                              <TrashFill onClick={deleteFile} className="cursor-pointer" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {isUploading && (
                      <div className="uploading-wrappper align-items-center d-flex justify-content-center">
                        <div className="spinner-border text-warning" role="status"></div>
                        <span className="uploading-text ml-2">Uploading</span>
                      </div>
                    )}
                    {!isUploading && checkIfPlotNumberOrTreatmentNumberExist() && plotDetails && (
                      <div className="uploading-wrappper">
                        <div className="row w-100 plot-details-row plot-details-header">
                          <div className="col-2 text-center">Plot ID</div>
                          <div className="col-3 text-center">Plot #</div>
                          <div className="col-3 text-center ">Treatment #</div>
                        </div>
                        {plotDetails
                          .sort((a, b) => a.plotId - b.plotId)
                          .map((plot) => (
                            <div key={plot.plotId} className="row w-100 plot-details-row">
                              <div className="col-2 text-center ">{plot.plotId}</div>
                              <div className="col-3 text-center">{plot.plotNumber}</div>
                              <div className="col-3 text-center ">{plot.treatmentNumber}</div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </Col>
              )}
            </Row>
          </div>
          <Modal
            className="delete-modal"
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            centered>
            <Modal.Body
              className="d-flex justify-content-center align-items-center"
              style={{ width: "514px", maxWidth: "514px" }}>
              <div style={{ padding: "20px", fontSize: "12px", fontWeight: "700" }}>
                <InfoCircleFill fill="#F2BB05" height={20} width={20} /> &nbsp;{" "}
                <span className="deep-cove-color">
                  {`Are you sure you want to delete Trial Layout "${deleteTrialDetails?.name}" ?`}
                </span>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <button
                onClick={() => setShowDeleteModal(false)}
                style={{
                  border: "none",
                  backgroundColor: "white",
                  color: "#293306",
                  fontWeight: "600",
                  fontSize: "16px"
                }}>
                <span className="deep-cove-color">CANCEL</span>
              </button>
              <button
                onClick={() => {
                  deleteLayoutAPI({ layout_id: deleteTrialDetails?.id }, newTrial, () => {
                    setShowSupplyPlotDetails(false);
                    setFileName();
                    setPlotDetails([]);
                    setPlotDetailsFromAPI({});
                    plotDetailsFromAPIConst = {};
                    clearAllDrawnLayers();
                    setSelectedTrial("");
                    setSelectedLayoutNameForCount("");
                    setShowDeleteModal(false);
                    setTrial("");
                    setFilteredTrials([]);
                    setLayoutList([]);
                    mapLayout?.invalidateSize();
                    setSelectedLayoutDetails({});
                    setNotes("");
                    setLayerState([]);
                    setInputValues({
                      plotDirection: "",
                      noOfTreatments: "",
                      noOfReps: "",
                      trmtDistance: "",
                      repDistance: "",
                      plotLength: "",
                      plotWidth: ""
                    });
                    setSelectedLayoutId("");
                    getLayoutsList(collectionDataConst?.block_id, newTrial, (data) => {
                      setLayoutList(data?.layouts_list);
                      setDraftedItemsList(
                        data?.layouts_list?.filter(
                          (item) => item?.status === "Draft" && item?.is_deleted === false
                        )
                      );
                      setSumbittedItemsList(
                        data?.layouts_list?.filter(
                          (item) => item.status === "Submitted" && item?.is_deleted === false
                        )
                      );
                      setFiltereddraftItemsList(
                        data?.layouts_list?.filter(
                          (item) => item?.status === "Draft" && item?.is_deleted === false
                        )
                      );
                      setFilteredsubmittedItemsList(
                        data?.layouts_list?.filter(
                          (item) => item.status === "Submitted" && item?.is_deleted === false
                        )
                      );
                      if (
                        data?.layouts_list?.filter((item) => item?.is_deleted === false)?.length ===
                        0
                      ) {
                        setSelectedLayoutBtn("new");
                        setSaveDisable(true);
                        setSubmitDisable(true);
                      }
                    });
                  });
                }}
                style={{
                  border: "none",
                  backgroundColor: "#0D1A40",
                  borderRadius: "20px",
                  fontWeight: "600",
                  fontSize: "16px",
                  color: "#FFFFFF",
                  height: "40px",
                  width: "79px"
                }}>
                <span>YES</span>
              </button>
            </Modal.Footer>
          </Modal>
          <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Body style={{ maxwidth: "518px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <br></br>
              <div style={{ padding: "20px", fontSize: "15px", fontWeight: "bold" }}>
                <InfoCircleFill fill="#F2BB05" height={20} width={20} /> &nbsp;{" "}
                <span className="deep-cove-color">
                  Are you sure you want to add Area Name “{newTrial}” ?
                </span>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <button
                onClick={() => setShowModal(false)}
                style={{
                  border: "none",
                  backgroundColor: "white",
                  color: "#293306",
                  fontWeight: "600",
                  fontSize: "16px"
                }}>
                <span className="deep-cove-color">CANCEL</span>
              </button>
              <button
                onClick={() => {
                  setShowSupplyPlotDetails(false);
                  setFileName();
                  setPlotDetails([]);
                  setPlotDetailsFromAPI({});
                  plotDetailsFromAPIConst = {};
                  clearAllDrawnLayers();
                  setSelectedTrial("");
                  setAddTrial(newTrial);
                  setShowModal(false);
                  setTrial("");
                  setFilteredTrials([]);
                  setLayoutList([]);
                  mapLayout?.invalidateSize();
                  setSelectedLayoutDetails({});
                  setNotes("");
                  setLayoutType("new");
                  setLayerState([]);
                  setSaveDisable(true);
                  setSubmitDisable(true);
                  setInputValues({
                    plotDirection: "",
                    noOfTreatments: "",
                    noOfReps: "",
                    trmtDistance: "",
                    repDistance: "",
                    plotLength: "",
                    plotWidth: ""
                  });
                  setSelectedLayoutNameForCount(newTrial);
                  getLayoutsList(collectionDataConst?.block_id, newTrial, (data) => {
                    setLayoutList(data?.layouts_list);
                    setDraftedItemsList(
                      data?.layouts_list?.filter(
                        (item) => item?.status === "Draft" && item?.is_deleted === false
                      )
                    );
                    setSumbittedItemsList(
                      data?.layouts_list?.filter(
                        (item) => item.status === "Submitted" && item?.is_deleted === false
                      )
                    );
                  });
                }}
                style={{
                  border: "none",
                  backgroundColor: "#0D1A40",
                  borderRadius: "20px",
                  fontWeight: "600",
                  fontSize: "16px",
                  color: "#FFFFFF",
                  width: "185px",
                  height: "40px"
                }}>
                <span>ADD AREA NAME</span>
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showSaveModal}
            onHide={() => {
              setShowSaveModal(false);
              setSaveError(false);
            }}
            centered>
            <Modal.Body className="mx-3" style={{ maxwidth: "514px" }}>
              <br></br>
              <div className="save-label">{submitType === "save" ? "Save" : "Submit"}</div>
              <Form onSubmit={() => { }}>
                <Form.Group className="mt-2" controlId="message">
                  <Form.Label
                    className="mb-1 "
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#0D1A40"
                    }}>
                    Notes
                  </Form.Label>
                  <Form.Control
                    className="deep-cove-color"
                    as="textarea"
                    value={notes}
                    rows={3}
                    onChange={(e) => setNotes(e.target.value)}
                    style={{ borderRadius: "15px" }}
                    placeholder="Please add if you have any notes"
                    required
                  />
                </Form.Group>
                {saveError && (
                  <div className="mt-3">
                    <img src={banNewIcon} alt="" />
                    <span className="error-message-label ml-3 light-red">
                      {/* Unable to {submitType === "save" ? " save" : " submit"}. Please try after
                      sometime */}
                       Server error. Please try again later.
                    </span>
                  </div>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <button
                onClick={() => setShowSaveModal(false)}
                style={{
                  border: "none",
                  backgroundColor: "white",
                  color: "#0D1A40",
                  fontWeight: "600",
                  fontSize: "16px"
                }}>
                CANCEL
              </button>
              {selectedLayoutDetails?.status === "Draft" && submitType === "save"  &&
              selectedLayoutBtn !== "clone" ? (
                <>
                  <button
                    onClick={() => saveLayout(submitType, false)}
                    disabled={saveLoading}
                    style={{
                      border: "1px solid #0D1A40",
                      backgroundColor: "transparent",
                      borderRadius: "20px",
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "#0D1A40",
                      width: "96px",
                      height: "40px"
                    }}>
                    SAVE
                  </button>
                  <button
                    onClick={() => saveLayout(submitType, true)}
                    disabled={saveLoading}
                    style={{
                      border: "none",
                      backgroundColor: "#0D1A40",
                      borderRadius: "20px",
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "#FFFFFF",
                      marginLeft: "10px",
                      width: "140px",
                      height: "40px"
                    }}>
                    <span>SAVE AS NEW</span>
                  </button>
                </>
              ) : (
                <button
                  onClick={() => saveLayout(submitType, false)}
                  disabled={saveLoading}
                  style={{
                    border: "none",
                    backgroundColor: submitType === "save" ? "#0D1A40" : "#E08330",
                    borderRadius: "20px",
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#FFFFFF",
                    width: "96px",
                    height: "40px"
                  }}>
                  <span>{submitType === "save" ? "SAVE" : "SUBMIT"}</span>
                </button>
              )}
            </Modal.Footer>
          </Modal>
          <Modal
            className="success-modal"
            show={showSuccessModal}
            onHide={() => setShowSuccessModal(false)}
            centered>
            <Modal.Body style={{ width: "540px" }}>
              <div className="d-flex flex-column justify-content-center align-items-center py-5">
                <CheckCircleFill color="#2EB800" height={48} width={48} />
                <div className="pt-3">
                  <span className="font-24 deep-cove-color">
                    Successfully {submitType === "save" ? savedAsTag : "Submitted"}{" "}
                    <strong>{savedLayoutName}</strong>
                  </span>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <button
                onClick={() => setShowSuccessModal(false)}
                style={{
                  border: "none",
                  backgroundColor: submitType === "save" ? "#0D1A40" : "#E08330",
                  borderRadius: "20px",
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#FFFFFF",
                  width: "96px",
                  height: "40px"
                }}>
                <span>OK</span>
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
}

L.Tooltip.include({
  // custom _setPosition to allow 'fast' label placement
  _setPosition: function (pos) {
    let map = this._map,
      container = this._container,
      tooltipPoint = map.layerPointToContainerPoint(pos),
      centerPoint = map.latLngToContainerPoint(map.getCenter()),
      direction = this.options.direction,
      tooltipWidth = null,
      tooltipHeight = null,
      offset = L.point(this.options.offset),
      anchor = this._getAnchor();

    if (direction === "fast") {
      tooltipWidth = 44;
      tooltipHeight = 54;
      direction = "right";
    } else {
      tooltipWidth = container.offsetWidth;
      tooltipHeight = container.offsetHeight;
    }

    if (direction === "top") {
      pos = pos.add(
        L.point(-tooltipWidth / 2 + offset.x, -tooltipHeight + offset.y + anchor.y, true)
      );
    } else if (direction === "bottom") {
      pos = pos.subtract(L.point(tooltipWidth / 2 - offset.x, -offset.y, true));
    } else if (direction === "center") {
      pos = pos.subtract(
        L.point(tooltipWidth / 2 + offset.x, tooltipHeight / 2 - anchor.y + offset.y, true)
      );
    } else if (direction === "right" || (direction === "auto" && tooltipPoint.x < centerPoint.x)) {
      direction = "right";
      pos = pos.add(L.point(offset.x + anchor.x, anchor.y - tooltipHeight / 2 + offset.y, true));
    } else {
      direction = "left";
      pos = pos.subtract(
        L.point(tooltipWidth + anchor.x - offset.x, tooltipHeight / 2 - anchor.y - offset.y, true)
      );
    }

    L.DomUtil.removeClass(container, "leaflet-tooltip-right");
    L.DomUtil.removeClass(container, "leaflet-tooltip-left");
    L.DomUtil.removeClass(container, "leaflet-tooltip-top");
    L.DomUtil.removeClass(container, "leaflet-tooltip-bottom");
    L.DomUtil.addClass(container, "leaflet-tooltip-" + direction);
    L.DomUtil.setPosition(container, pos);
  }
});