import "bootstrap/dist/css/bootstrap.min.css";
import propTypes from "prop-types";
import NewViewerBarChart from "./NewViewerBarChart";
import NewViewerFlightChart from "./NewViewerFlightChart";
import NewViewerCompareChart from "./NewViewerCompareChart";

import "../assests/Styles/charts.css";
import "../assests/Styles/color.css";

export default function DifferentCharts({
  modelBool,
  isMobile,
  chartsValue,
  compareChartsValue,
  showTreatment,
  showTime,
  barData,
  barData2,
  flightData,
  noAssesment,
  noAssesment2,
  noMean,
  noMean2,
  error,
  isMobileLandscape,
  isTablet,
  showCompare,
  handleChartRadio,
  handleCompareChartRadio,
  assessmentDateType,
  treatmentDataLoaded,
  flightDataLoaded,
  compareData1Loaded,
  compareData2Loaded,
  collectionDate1,
  collectionDate2,
  navbarButtons,
  barExpand,
  highlightPlots
}) {
  // const iphoneSELandScape = useMediaQuery({ maxHeight: 375 });
  const CompareHeight =  "normal-different-chart-compare";
  return (
    <>
      {showTreatment === true && (
        <div className="different-charts-treatment">
          <div className="different-charts-treatment-child">
            <div className={isMobile ? "col-6" : "col-7"} style={{ margin: "15px" }}>
              <input
                type="radio"
                value="Bar"
                checked={chartsValue === "Bar"}
                onChange={handleChartRadio}
              />
              <span className="ml-2 mr-2 input-label deep-cove-color">Bar</span>
              <input
                type="radio"
                value="Box"
                checked={chartsValue === "Box"}
                onChange={handleChartRadio}
              />
              <span className="ml-2 input-label deep-cove-color">Box</span>
            </div>
          </div>

          <NewViewerBarChart
            isModel={modelBool}
            chartsValue={chartsValue}
            treatmentDataLoaded={treatmentDataLoaded}
            barData={barData}
            noAssesment={noAssesment}
            noMean={noMean}
            error={error}
            isMobileLandscape={isMobileLandscape}
            isTablet={isTablet}
            isMobile={isMobile}
            navbarButtons={navbarButtons}
            barExpand={barExpand}
            highlightPlots={highlightPlots}
          />
        </div>
      )}
      {showTime === true && (
          <NewViewerFlightChart
            flightData={flightData}
            noAssesment={noAssesment}
            flightDataLoaded={flightDataLoaded}
            error={error}
            isModel={modelBool}
            isMobileLandscape={isMobileLandscape}
            isTablet={isTablet}
            isMobile={isMobile}
            navbarButtons={navbarButtons}
            barExpand={barExpand}
          />
      )}

      {showCompare === true && (
        <div className={`different-chart-compare ${CompareHeight}`}>
          {assessmentDateType[0]?.type === undefined ||
          assessmentDateType[1]?.type === undefined ? (
                <span className="flight-one-date-error">
                  <strong>Please select two assessments!</strong>
                </span>
            ) : (
              <>
                <div className="different-charts-treatment-child">
                  <div className="col-10" style={{ margin: "15px" }}>
                    <input
                      type="radio"
                      value="Compare"
                      checked={compareChartsValue === "Compare"}
                      onChange={handleCompareChartRadio}
                    />
                    <span className="ml-2 mr-2 input-label deep-cove-color">Compare</span>
                    <input
                      type="radio"
                      value="Correlation"
                      checked={compareChartsValue === "Correlation"}
                      onChange={handleCompareChartRadio}
                    />
                    <span className="ml-2 input-label deep-cove-color">Correlation</span>
                  </div>
                </div>
                <NewViewerCompareChart
                  navbarButtons={navbarButtons}
                  isModel={modelBool}
                  chartsValue={compareChartsValue}
                  barData1={barData}
                  barData2={barData2}
                  noAssesment1={noAssesment}
                  noAssesment2={noAssesment2}
                  compareData1Loaded={compareData1Loaded}
                  compareData2Loaded={compareData2Loaded}
                  noMean={noMean}
                  noMean2={noMean2}
                  error={error}
                  isMobileLandscape={isMobileLandscape}
                  isTablet={isTablet}
                  isMobile={isMobile}
                  collectionDate1={collectionDate1}
                  collectionDate2={collectionDate2}
                  barExpand={barExpand}
                />
              </>
            )}
        </div>
      )}
    </>
  );
}
DifferentCharts.propTypes = {
  modelBool: propTypes.any,
  isMobile: propTypes.any,
  chartsValue: propTypes.any,
  compareChartsValue: propTypes.any,
  showTreatment: propTypes.any,
  showTime: propTypes.any,
  barData: propTypes.any,
  barData2: propTypes.any,
  flightData: propTypes.any,
  noAssesment: propTypes.any,
  noAssesment2: propTypes.any,
  noMean: propTypes.any,
  noMean2: propTypes.any,
  error: propTypes.any,
  isMobileLandscape: propTypes.any,
  isTablet: propTypes.any,
  showCompare: propTypes.any,
  handleChartRadio: propTypes.any,
  handleCompareChartRadio: propTypes.any,
  assessmentDateType: propTypes.any,
  treatmentDataLoaded: propTypes.any,
  flightDataLoaded: propTypes.any,
  compareData1Loaded: propTypes.any,
  compareData2Loaded: propTypes.any,
  collectionDate1: propTypes.any,
  collectionDate2: propTypes.any,
  navbarButtons: propTypes.any,
  barExpand: propTypes.any,
  highlightPlots: propTypes.any,
};
