import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import propTypes from "prop-types";
import syngentaLogo from "../assests/Styles/assets/logo.png";
import mobileLogo from "../assests/Styles/assets/mobileLogo.png";
import { InfoCircle, CaretDownFill } from "react-bootstrap-icons";
import { Modal, Button, Row, Col, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import "../assests/Styles/mobileTopBar.css";
import { useMediaQuery } from "react-responsive";
import logout from "../assests/Styles/assets/logout1.svg";
import orangeDrone from "../assests/Styles/assets/orangeDrone.svg";
import whiteDrone from "../assests/Styles/assets/whiteDrone.svg";
import ToggleSwitch from "./ToggleSwitch";
import whiteTrailSummary from "../assests/Styles/assets/whiteTrailSummary.svg";
import closeIcon from "../assests/Styles/assets/closeIcon.svg";
import { customTabletDimensions } from "../services/Common";
import { useMobileOrientation } from "react-device-detect";
import shapeNewIcon from "../assests/Styles/assets/ShapeNewIcon.svg";
import FeedbackForm from "./FeedbackForm";
import vectorNewIcon from "../assests/Styles/assets/vectorNewIcon.svg";
import { X } from "react-bootstrap-icons";

export default function MobileTopBar({
  protocolName,
  viewAPIData,
  cropData,
  selectedOption,
  handleTrialSummary,
  trail,
  isEditViewer = false,
  protocolTrials,
  collectionId,
  trialType,
  customMobileLandscape,
  customMobilePortrait,
  selectedFlightDate,
  accounts,
  instance,
  state,
  submitForm,
}) {
  const name = sessionStorage.getItem("userName");
  const pro = name?.split(".");
  const [profileClick, setProfileClick] = useState(false);
  const dropdownRef = useRef(null);
  const profileIconRef = useRef(null);
  const [show, setShow] = useState(false);
  let navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const isMobileLandscape = useMediaQuery({ maxHeight: 429 });
  const isSmallDevice = useMediaQuery({ minWidth: 355, maxWidth: 380 });
  const [showTrials, setShowTrials] = useState(false);
  const protocolRef = useRef(null);
  const protocolContainerRef = useRef(null);
  const [menuIcon, setMenuIcon] = useState(false);
  const menuIconRef = useRef(null);
  const menuIconContainerRef = useRef(null);
  const [closeTooltip, setCloseTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const tooltipContainerRef = useRef(null);
  const { isPortrait, isLandscape } = useMobileOrientation();

  const handleToogleTooltip = () => setCloseTooltip(!closeTooltip);
  const handleCloseTooltip = () => setCloseTooltip(false);
  const [isShowFeedback, setIsShowFeedback] = useState(false);

  const handleOpenFeedback = () => {
    setIsShowFeedback(true);
  };

  const handleCloseFeedback = () => {
    setIsShowFeedback(false);
  };
  const handleprofileIconClose = () => setProfileClick(false);

  useEffect(() => {
    const handleClickOutsideTooltip = (event) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target) &&
        !tooltipContainerRef?.current?.contains(event.target)
      ) {
        handleCloseTooltip(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutsideTooltip);
    return () => {
      window.removeEventListener("mousedown", handleClickOutsideTooltip);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !profileIconRef?.current?.contains(event.target)
      ) {
        setProfileClick(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleButtonClick = () => {
    setProfileClick(!profileClick);
  };
  const handleClose = () => setShow(false);
  const handleClick = () => {
    setShowTrials(!showTrials);
  };
  const routeChange = () => {
    let path = "/home/logout";
    navigate(path);
  };
  const handleTrials = (trialDetails) => {
    window.open(`/app/viewer?trial=${trialDetails?.trial_name}`, "_blank");
  };
  const handleMenuIconClick = () => {
    setMenuIcon(!menuIcon);
  };
  useEffect(() => {
    const handleClickMenuOutside = (event) => {
      if (
        menuIconRef.current &&
        !menuIconRef.current.contains(event.target) &&
        !menuIconContainerRef?.current?.contains(event.target)
      ) {
        setMenuIcon(false);
      }
    };
    window.addEventListener("mousedown", handleClickMenuOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickMenuOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickProtocolOutside = (event) => {
      if (
        protocolRef.current &&
        !protocolRef.current.contains(event.target) &&
        !protocolContainerRef?.current?.contains(event.target)
      ) {
        setShowTrials(false);
      }
    };
    window.addEventListener("mousedown", handleClickProtocolOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickProtocolOutside);
    };
  }, []);

  // let tab = tabletMaxWidth && tabletMinWidth;

  let isCheckSelectedOption =
    selectedOption !== "All trials" && selectedOption !== "No trials" ? selectedOption : " ";
  let isCheckTrial = trail !== "All trials" && trail !== "No trials" ? trail : " ";

  const truncateCombinedText = (text1, text2, maxLength) => {
    const combinedText = `${text1} - ${text2}`.trim();
    if (combinedText.length > maxLength) {
      return combinedText.substring(0, maxLength) + "...";
    }
    return combinedText;
  };

  const siteName = viewAPIData?.site_name || "";
  const blockName = viewAPIData?.block_name || "";
  const combinedText = `${siteName} <br/> ${blockName}`.trim();
  const showTooltip = combinedText.length > 32;
  const truncatedText = truncateCombinedText(siteName, blockName, 32);

  const TrialButtons = ({ protocolTrials }) => {
    const rowSize = isMobileLandscape ? 3 : 2;
    const rows = [];
    for (let i = 0; i < protocolTrials.length; i += rowSize) {
      const chunk = protocolTrials.slice(i, i + rowSize);
      const row = (
        <Row key={i}>
          {chunk.map((item) => (
            <Col
              key={item?.trial_name}
              xs={4}
              className={isMobile ? "mr-5 mb-2 mt-2" : isMobileLandscape && "mt-2"}
              style={{ padding: 0 }}>
              <Button
                disabled={item?.trial_name === trail}
                onClick={() => handleTrials(item)}
                className="trialButtons"
                style={{
                  backgroundColor: item?.trial_name === trail ? "#E08330" : "#3d4359",
                  opacity: item?.trial_name === trail && 1
                }}>
                {item?.trial_name}
                {item?.has_drone_assessments ? (
                  <img
                    src={item?.trial_name === trail ? whiteDrone : orangeDrone}
                    alt=""
                    style={{ marginLeft: "10px" }}
                  />
                ) : null}
              </Button>
            </Col>
          ))}
        </Row>
      );
      rows.push(row);
    }
    return <Container>{rows}</Container>;
  };
  const checkedTrialClass = isMobile
    ? "col-6"
    : isMobileLandscape
    ? "col-7"
    : customTabletDimensions() && isPortrait
    ? "col-md-4 col-sm-4"
    : "col-md-3";
  const marginTopClass = isEditViewer
    ? "12px"
    : !isMobile && (isCheckSelectedOption === " " || isCheckTrial === " ")
    ? "24px"
    : isMobile && (isCheckSelectedOption === " " || isCheckTrial === " ") && "22px";
  const prtocolAndCropData = cropData.length > 0 || protocolName;

  return (
    <div
      className="row"
      style={{
        flexWrap: "unset",
        height: "60px",
        backgroundColor: "#0D1A40",
        zIndex: "999",
        position: "fixed",
        left: 0,
        right: 0,
        top: 0
      }}>
      <div
        className="col-md-1 col-sm-2 d-flex align-items-center justify-content-center"
        style={{
          borderRight: customTabletDimensions() ? "1px solid white" : " ",
          padding: isMobile ? "5px 15px" : "10px"
        }}>
        <div className="logo" id="viewer-link">
          <a className="logo" href="/app/" id="viewer-link">
            <div className="align-items-center" style={{ textAlign: customTabletDimensions() && "center" }}>
            <img
              src={customTabletDimensions() || isMobileLandscape ? mobileLogo : syngentaLogo}
              href="#"
              alt="syngenta"
              style={{
                marginLeft: "10px",
                cursor: "pointer"
              }}
            />
            </div>
          </a>
        </div>
      </div>
      <div className={checkedTrialClass} style={{ margin: "6px 0px 0px 20px" }}>
        {!isEditViewer && (
          <span
            style={{
              color: "white",
              fontWeight: "600",
              fontSize: isSmallDevice ? "10px" : "16px",
              marginTop: !isMobile && "11px"
            }}>
            {isCheckSelectedOption ? isCheckSelectedOption : isCheckTrial}
          </span>
        )}
        {isCheckSelectedOption === " " || isCheckTrial === " " ? null : (
          <span className="ml-3 cursor-pointer" ref={menuIconContainerRef}>
            <CaretDownFill color="white" onClick={handleMenuIconClick} />
          </span>
        )}
        <div>
          <div
            className={isMobile ? "d-block" : "d-flex flex-row align-items-center"}
            style={{ marginTop: marginTopClass }}
            ref={tooltipRef}>
            {trialType !== "manual" && (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                ref={menuIconContainerRef}
                overlay={
                  <Tooltip
                    id="tooltip-site-name"
                    className="mobile-topbar-tooltip"
                    style={{
                      position: "relative",
                      marginLeft: isMobile
                        ? "-88px"
                        : isMobileLandscape
                        ? "-103px"
                        : customTabletDimensions()
                        ? "-132px"
                        : "-148px"
                    }}>
                    <div
                      style={{
                        textAlign: "left",
                        paddingBottom: "8px",
                        marginTop: "5px",
                        paddingLeft: "20px",
                        flex: 1
                      }}>
                      {siteName}
                    </div>
                    <div style={{ paddingLeft: "20px", flex: 1 }}>{blockName}</div>
                    <div className="d-flex justify-content-end">
                      <img
                        src={closeIcon}
                        alt="closeTooltip"
                        onClick={handleCloseTooltip}
                        style={{
                          width: "22px",
                          height: "22px",
                          fontWeight: "700",
                          marginLeft: "30px",
                          left: "10%",
                          marginTop: "-30px",
                          cursor: "pointer"
                        }}
                      />
                    </div>
                  </Tooltip>
                }
                hide={showTooltip}
                onToggle={handleToogleTooltip}>
                <div
                  style={{
                    fontSize: !(isMobile || isMobileLandscape) ? "12px" : "8px",
                    color: "white",
                    fontWeight: "500",
                    cursor: showTooltip ? "pointer" : "default",
                    marginTop: "3px"
                  }}
                  onClick={handleToogleTooltip}>
                  {truncatedText}
                  {combinedText.length > 32 && <InfoCircle height={14} width={14} color="white" />}
                </div>
              </OverlayTrigger>
            )}
          </div>
        </div>
      </div>
      {!(isMobile || isMobileLandscape) && (
        <div
          className="col-md-2 col-sm-3"
          style={{ paddingLeft: 0, paddingRight: 0, marginTop: "9px" }}>
          {selectedOption !== "All trials" && selectedOption !== "No trials" && protocolName && (
            <div>
              <div
                style={{
                  color: "white",
                  fontWeight: "700",
                  fontSize: "12px",
                  marginRight: "2%",
                  marginBottom: "4px"
                  // marginTop: "-3px",
                  // paddingBottom: "3px"
                }}>
                {protocolName}
              </div>
              <div
                onClick={handleClick}
                ref={protocolContainerRef}
                style={{
                  fontFamily: "Helvetica",
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#FFFFFF",
                  textDecoration: "underline",
                  cursor: "pointer"
                }}>
                Protocol Trials
                <span style={{ marginLeft: "8px" }}>
                  <CaretDownFill color="white" />
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={
          customTabletDimensions() && isLandscape ? "col-md-4 col-sm-1" : "col-md-3 col-sm-1"
        }
        style={{ paddingLeft: 0, paddingRight: 0 }}>
        <>
          {isMobile || isMobileLandscape || isSmallDevice ? (
            prtocolAndCropData ? (
              <>
                {selectedOption !== "All trials" && selectedOption !== "No trials" && (
                  <div
                    onClick={() => setShow(true)}
                    style={{
                      margin: "15px 0px 0px 0px"
                    }}>
                    <InfoCircle height={24} width={24} color="white" />
                  </div>
                )}

                <div className="modalContent">
                  <Modal
                    show={show}
                    onHide={handleClose}
                    size={isMobile ? "sm" : "md"}
                    className={isMobile ? "mobile" : "tab"}>
                    <Modal.Body>
                      <div className="close-button mb-3" onClick={handleClose}>
                        &times;
                      </div>
                      <div className="d-flex">
                        <div className="modal-left" style={{ paddingLeft: 0 }}>
                          <span
                            style={{
                              fontSize: "14px",
                              fontFamily: "Helvetica",
                              color: "#FFFFFF"
                            }}>
                            CROPS
                          </span>
                          <ul className="list-unstyled" style={{ paddingLeft: 0 }}>
                            {cropData.map((item) => (
                              <li
                                key={item}
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Helvetica",
                                  color: "#FFFFFF",
                                  fontWeight: 700
                                }}>
                                {item}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="d-flex">
                        {protocolName && (
                          <div
                            style={{
                              color: "#FFFFFF",
                              fontWeight: "700",
                              fontSize: "16px",
                              textAlign: "start"
                            }}>
                            {protocolName}
                          </div>
                        )}
                      </div>
                      {location.pathname.indexOf("ploteditor") === -1 && (
                        <div className="d-flex mt-2">
                          <div
                            style={{
                              color: "#FFFFFF",
                              fontWeight: "400",
                              fontSize: "14px",
                              textAlign: "start"
                            }}>
                            PROTOCOL TRIALS
                          </div>
                        </div>
                      )}
                      {protocolTrials && location.pathname.indexOf("ploteditor") === -1 && (
                        <TrialButtons protocolTrials={protocolTrials} />
                      )}
                    </Modal.Body>
                  </Modal>
                </div>
              </>
            ) : null
          ) : cropData?.length > 0 ? (
            <div
              className="d-flex align-items-center"
              style={{
                fontSize: !(isMobile || isMobileLandscape) ? "12px" : "8px",
                color: "#FFFFFF",
                marginTop: "14px"
              }}>
              <div style={{ marginTop: "20px" }}>
                <span style={{ fontWeight: 400 }}>Crops: </span>
                <span style={{ fontWeight: 700 }}>{cropData.join(", ")}</span>
              </div>
            </div>
          ) : null}
        </>
      </div>
      <div className="col-md-1 col-sm-2 d-flex justify-content-center align-items-center">
        <div
          style={{
            padding: "10px",
            paddingLeft: "-4px",
            marginLeft:
              isMobile || isMobileLandscape ? "0px" : customTabletDimensions() ? "80px" : "120px"
          }}>
          <button
            className="ml-auto"
            style={{
              border: "none",
              backgroundColor: "#006D82",
              height: "24px",
              width: "24px",
              borderRadius: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontSize: "12px"
            }}
            ref={profileIconRef}
            onClick={handleButtonClick}>
            {pro && pro.length > 1
              ? pro[0].charAt(0).toUpperCase() + pro[1].charAt(0).toUpperCase()
              : pro[0].charAt(0).toUpperCase()}
          </button>
          {profileClick && customTabletDimensions() && (
            <div
              ref={dropdownRef}
              style={{
                position: "fixed",
                right: "0",
                top: "60px",
                backgroundColor: "#0D1A40",
                zIndex: "9999",
                minWidth: isMobile ? "101vw" : "388px"
              }}>
              <div style={{ position: "absolute", zIndex: 9, right: "8px", marginTop: "4px" }}>
                <X color="white" height={24} width={24} onClick={handleprofileIconClose} />
              </div>
              <ul className="list-group">               
                <li style={{height:"85px"}} className="list-group-item">
                  <div style={{ display: "flex", gap: "10px", marginTop:"31px",alignItems:"center" }}>
                    <span>
                      <img
                        src={shapeNewIcon}
                        alt="shapeNewIcon"
                        width={24}
                        height={24}
                      />
                    </span>
                    <span style={{
                      fontSize: ".875rem"
                    }}> {name}</span>
                  </div>
                </li>
                <li className="list-group-item">
                  <button
                    className="MapD-feedback"
                    onClick={handleOpenFeedback}
                  >
                    <div className="mobileTab-vector">
                      <span>
                        <img
                          src={vectorNewIcon}
                          alt="vectorNewIcon"
                          style={{ width: "20px", height: "20px",margin:"2px" }}
                        />
                      </span>
                      <span style={{fontWeight:400}}> Feedback</span>
                    </div>
                  </button>
                </li>
                <li
                  className="list-group-item"
                  style={{
                    backgroundColor: "#0D1A40",
                    paddingLeft: "33px"
                  }}>
                  <ToggleSwitch
                    trial={selectedOption}
                    collectionId={collectionId}
                    trialType={trialType}
                    customMobileLandscape={customMobileLandscape}
                    customMobilePortrait={customMobilePortrait}
                  />
                </li>
                <li
                  className="list-group-item"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#0D1A40"
                  }}>
                  <img
                    src={logout}
                    alt="logout"
                    style={{ width: "18px", height: "18px", margin: "3px", fontWeight: "700" }}
                  />
                  <button className="logout-Mobile" onClick={routeChange}>
                    <span style={{ fontWeight: "700" }}>&nbsp;LOGOUT</span>
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      {showTrials && !isMobile && !isMobileLandscape && (
        <div
          ref={protocolRef}
          className="protocolTrial-dropdown"
          style={{ left: customTabletDimensions() && isPortrait ? "41%" : "33%" }}>
          <div className="selectText">Select a Trial</div>
          {protocolTrials.length > 0 &&
            protocolTrials?.map((item) => (
              <>
                <Button
                  key={item?.trial}
                  disabled={item?.trial_name === trail}
                  onClick={() => handleTrials(item)}
                  style={{
                    backgroundColor: item?.trial_name === trail ? "#E08330" : "#3d4359",
                    opacity: item?.trial_name === trail && 1
                  }}
                  className="trialButtons">
                  {item?.trial_name}
                  {item?.has_drone_assessments ? (
                    <img
                      src={item?.trial_name === trail ? whiteDrone : orangeDrone}
                      alt=""
                      style={{ marginLeft: "10px" }}
                    />
                  ) : null}
                </Button>
              </>
            ))}
        </div>
      )}
      {menuIcon ? (
        <div
          ref={menuIconRef}
          className="cursor-pointer"
          style={{
            position: " absolute",
            top: "32px",
            left: isMobile
              ? "22%"
              : isMobileLandscape
              ? "11%"
              : customTabletDimensions()
              ? "12%"
              : "11%",
            width: "166px",
            height: trialType !== "manual" ? "47px" : "47px",
            backgroundColor: "#0D1A40",
            zIndex: 999,
            padding: "10px 10px 0px 10px"
          }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            onClick={handleTrialSummary}>
            <span style={{ marginRight: "11px" }}>
              <img src={whiteTrailSummary} alt="" />
            </span>
            <span
              style={{
                color: "white",
                fontFamily: "Helvetica",
                fontSize: "14px",
                fontWeight: 400
              }}>
              Trial Summary
            </span>
          </div>
        </div>
      ) : null}
      <div>
        <FeedbackForm
          handleFeedbackClose={handleCloseFeedback}
          isShowFeedback={isShowFeedback}
          handleCloseFeedback={handleCloseFeedback}
          isDesktop={true}
          accounts={accounts}
          instance={instance}
          state={state}
          submitForm={submitForm}
          collectionId={collectionId}
          selectedTrail={selectedOption}
          selectedFlightDate={selectedFlightDate}
        />
      </div>
    </div>
  );
}

MobileTopBar.propTypes = {
  protocolName: propTypes.any,
  viewAPIData: propTypes.any,
  cropData: propTypes.any,
  pestData: propTypes.any,
  selectedOption: propTypes.any,
  trail: propTypes.any,
  selectedFlightDate: propTypes.any,
  isEditViewer: propTypes.any,
  protocolTrials: propTypes.any,
  collectionId: propTypes.any,
  trialType: propTypes.any,
  handleTrialSummary: propTypes.any,
  customMobileLandscape: propTypes.any,
  customMobilePortrait: propTypes.any,
  accounts : propTypes.any,
  instance : propTypes.any,
  state : propTypes.any,
  submitForm : propTypes.any,
};
