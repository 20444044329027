import { useMobileOrientation } from "react-device-detect";
import { ThreeDots, InfoCircleFill, X } from "react-bootstrap-icons";
import { useState, useRef, useEffect } from "react";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import propTypes from "prop-types";
import { customTabletDimensions } from "../services/Common";

function TabletButtons({
  items,
  selectedOption,
  isTreatmentUser,
  isImgAvailable,
  tabletNavbarButton,
  setTabletNavbarButton,
  assessmentDateType,
  setShowTabletTooltip,
  showTabletTooltip,
  targetTabletRef,
  mapLayout,
  setGeometry
}) {
  const [buttonsMenu, setButtonsMenu] = useState(false);
  const [buttonText, setButtonText] = useState("Charts");
  const [menuItems, setMenuItems] = useState(
    isTreatmentUser ? ["Treatment Labels", "Environmental"] : ["Environmental"]
  );
  const { isLandscape } = useMobileOrientation();
  const tabletbuttonMenuIcon = useRef(null);
  const tabletbuttonmenuIconContainerRef = useRef(null);
  const disableButtonLogic =
    (buttonText === "Charts" && assessmentDateType.length === 0) ||
      (buttonText === "Treatment Labels" &&
        (!selectedOption || selectedOption === "All trials" || selectedOption === "No trials")) ||
      (buttonText === "Environmental" &&
        (!selectedOption || selectedOption === "All trials" || selectedOption === "No trials") && !isImgAvailable)
      ? true
      : false;

  // const toggleTooltip = () => {
  //   setShowTabletTooltip(!showTabletTooltip);
  // }

  const handleMenuClick = (index) => {
    const newButtonText = menuItems[index];
    const newMenuItems = [...menuItems];
    newMenuItems[index] = buttonText;
    setButtonText(newButtonText);
    setMenuItems(newMenuItems);
    setButtonsMenu(false);
    setTabletNavbarButton(newButtonText);
    if((!selectedOption || selectedOption === "All trials" || selectedOption === "No trials") && newButtonText === "Environmental" && isImgAvailable) {
      setGeometry(mapLayout?.getCenter());
    }
  };

  const handleButtonClick = (button) => {
    setTabletNavbarButton(button);
  };


  useEffect(() => {
    const handleClickMenuOutside = (event) => {
      if (
        tabletbuttonMenuIcon.current &&
        !tabletbuttonMenuIcon.current.contains(event.target) &&
        !tabletbuttonmenuIconContainerRef?.current?.contains(event.target)
      ) {
        setButtonsMenu(false);
      }
    };
    window.addEventListener("mousedown", handleClickMenuOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickMenuOutside);
    };
  }, []);

  const renderTabletTooltip = (name, props) => {
    return (
      <Tooltip id="tooltip" {...props} className="newviewer-tablet-buttons-tooltip">
        <div className="d-flex align-items-center justify-content-between ">
          <span style={{ marginLeft: "8px", marginRight: "15px" }}>
            <InfoCircleFill height={20} width={20} />
          </span>
          <span style={{ marginLeft: name === "Treatment Labels" ? "10px" : name === "Environmental" ? "15px" : "" }} className="mr-4 font-size-small font-weight-700">
            {name === "Environmental"
              ? "Please select a trial to enable Environmental"
              : name === "Charts"
                ? "Please select at least one assessment to enable Charts"
                : name === "Treatment Labels"
                  ? "Please select a trial to enable Treatment Labels"
                  : ""}
          </span>
          <span >
            <X width={24} height={24} onClick={() => setShowTabletTooltip(false)} />
          </span>
        </div>
      </Tooltip>
    );
  };

  return (
    <div>
      <div>
        {customTabletDimensions() && !isLandscape ? (
          <div className={`${isTreatmentUser ? "col-6" : "col-5"} pl-1 d-flex align-items-center`}>
            <span>
              <Button
                style={{
                  backgroundColor: tabletNavbarButton === "Drone" ? "#E08330" : "#F1F2F3",
                  color: tabletNavbarButton === "Drone" ? "#ffffff" : "#0D1A40",
                  marginRight: "10px"
                }}
                className="viewer-bar-buttons ml-1"
                onClick={() => {
                  handleButtonClick("Drone");
                }}>
                {isImgAvailable ? "Drone / Layout" : "Layout"}
              </Button>
            </span>
            <span ref={targetTabletRef}>
              <OverlayTrigger
                // show={disableButtonLogic && showTabletTooltip}
                placement="bottom"
                onToggle={() => setShowTabletTooltip(true)}
                onHide={() => setShowTabletTooltip(false)}
                overlay={disableButtonLogic && showTabletTooltip ? renderTabletTooltip(buttonText) : <></>}>
                <Button
                  style={{
                    backgroundColor:
                      tabletNavbarButton === buttonText && !disableButtonLogic
                        ? "#E08330"
                        : "#F1F2F3",
                    color:
                      tabletNavbarButton === buttonText && !disableButtonLogic
                        ? "#ffffff"
                        : "#0D1A40",
                    marginRight: "10px",
                    pointerEvents: "auto"
                  }}
                  className="viewer-bar-buttons ml-1"
                  onClick={() => {
                    handleButtonClick(buttonText);
                  }}
                  disabled={disableButtonLogic}
                >
                  {buttonText}
                </Button>
              </OverlayTrigger>
            </span>
            <span>
              <Button
                style={{
                  backgroundColor: "#ffffff",
                  border: "none",
                  color: "#0D1A40",
                  boxShadow: "none",
                  marginRight: "10px"
                }}
                ref={tabletbuttonmenuIconContainerRef}
                onClick={() => setButtonsMenu(!buttonsMenu)}>
                <ThreeDots width={24} height={24} />
              </Button>
            </span>

            {buttonsMenu && (
              <div
                style={{
                  position: "absolute",
                  top: "49px",
                  left: "220px",
                  backgroundColor: "#ffffff",
                  width: "172px",
                  height: isTreatmentUser ? "100px" : "50px"
                }}
                ref={tabletbuttonMenuIcon}>
                {menuItems?.map((item, index) => (
                  <div
                    key=""
                    className="d-flex align-items-center justify-content-center"
                    style={{ marginTop: "10px" }}>
                    <OverlayTrigger
                      placement="left"

                      overlay={
                        !selectedOption ||
                          (["All trials", "No trials"].includes(selectedOption) &&
                            item === "Treatment Labels") ? (
                          renderTabletTooltip("Treatment Labels")
                        ) : !selectedOption ||
                          (["All trials", "No trials"].includes(selectedOption) && !isImgAvailable &&
                            item === "Environmental") ? (
                          renderTabletTooltip("Environmental")
                        ) : !selectedOption ||
                          (["All trials", "No trials"].includes(selectedOption) &&
                            item === "Charts") ||
                          (selectedOption &&
                            assessmentDateType.length === 0 &&
                            item === "Charts") ? (
                          renderTabletTooltip("Charts")
                        ) : (
                          <></>
                        )
                      }>

                      <Button
                        disabled={
                          (item === "Charts" && assessmentDateType.length === 0) ||
                            (item === "Treatment Labels" &&
                              (!selectedOption ||
                                selectedOption === "All trials" ||
                                selectedOption === "No trials")) ||
                            (item === "Environmental" &&
                              (!selectedOption ||
                                selectedOption === "All trials" ||
                                selectedOption === "No trials") && !isImgAvailable)
                            ? true
                            : false
                        }
                        style={{
                          backgroundColor: tabletNavbarButton === item ? "#E08330" : "#F1F2F3",
                          color: tabletNavbarButton === item ? "#E08330" : "#0D1A40",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: 400,
                          fontFamily: "Helvetica"
                        }}
                        className="viewer-bar-buttons"
                        onClick={() => {
                          handleMenuClick(index);
                        }}>
                        {item}
                      </Button>
                    </OverlayTrigger>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div>
            {isLandscape && (customTabletDimensions()) && (
              <div
                className={`${isTreatmentUser ? "col-6" : "col-5"} pl-1 d-flex align-items-center`}>
                <span>
                  <Button
                    style={{
                      backgroundColor: tabletNavbarButton === "Drone" ? "#E08330" : "#F1F2F3",
                      color: tabletNavbarButton === "Drone" ? "#ffffff" : "#0D1A40",
                      marginRight: "10px"
                    }}
                    className="viewer-bar-buttons ml-1"
                    onClick={() => {
                      handleButtonClick("Drone");
                    }}>
                    {isImgAvailable ? "Drone / Layout" : "Layout"}
                  </Button>
                </span>

                {items?.map((item) => (
                  <span key="" style={{ marginRight: "10px" }}>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        !selectedOption ||
                          (["All trials", "No trials"].includes(selectedOption) &&
                            item === "Treatment Labels") ? (
                          renderTabletTooltip("Treatment Labels")
                        ) : !selectedOption ||
                          (["All trials", "No trials"].includes(selectedOption) && !isImgAvailable &&
                            item === "Environmental") ? (
                          renderTabletTooltip("Environmental")
                        ) : !selectedOption ||
                          (["All trials", "No trials"].includes(selectedOption) &&
                            item === "Charts") ||
                          (selectedOption &&
                            assessmentDateType.length === 0 &&
                            item === "Charts") ? (
                          renderTabletTooltip("Charts")
                        ) : (
                          <></>
                        )
                      }>
                      <Button
                        style={{
                          backgroundColor: tabletNavbarButton === item ? "#E08330" : "#F1F2F3",
                          color: tabletNavbarButton === item ? "#ffffff" : "#0D1A40"
                        }}
                        className="viewer-bar-buttons ml-1"
                        onClick={() => handleButtonClick(item)}
                        disabled={
                          (item === "Charts" && assessmentDateType.length === 0) ||
                            (item === "Treatment Labels" &&
                              (!selectedOption ||
                                selectedOption === "All trials" ||
                                selectedOption === "No trials")) ||
                            (item === "Environmental" &&
                              (!selectedOption ||
                                selectedOption === "All trials" ||
                                selectedOption === "No trials") && !isImgAvailable)
                            ? true
                            : false
                        }>
                        <span
                        >
                          {item}
                        </span>
                      </Button>
                    </OverlayTrigger>
                  </span>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

TabletButtons.propTypes = {
  items: propTypes.any,
  selectedOption: propTypes.any,
  isTreatmentUser: propTypes.any,
  isImgAvailable: propTypes.any,
  assessmentDateType: propTypes.any,
  setTabletNavbarButton: propTypes.any,
  tabletNavbarButton: propTypes.any,
  setShowTabletTooltip: propTypes.any,
  showTabletTooltip: propTypes.any,
  targetTabletRef: propTypes.any,
  mapLayout: propTypes.any,
  setGeometry: propTypes.any,
};
export default TabletButtons;
