import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import propTypes from "prop-types";
import syngentaLogo from "../assests/Styles/assets/logo.png";
import mobileLogo from "../assests/Styles/assets/mobileLogo.png";
import { InfoCircle } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import "../assests/Styles/mobileTopBar.css";
import "../assests/Styles/color.css";
import "../assests/Styles/common.css";
import logout from "../assests/Styles/assets/logout1.svg";
import orangeDrone from "../assests/Styles/assets/orangeDrone.svg";
import whiteDrone from "../assests/Styles/assets/whiteDrone.svg";
import ToggleSwitch from "./ToggleSwitch";
import mobileTrailSummaryIcon from "../assests/Styles/assets/mobileTrailSummaryIcon.svg";
import { X } from "react-bootstrap-icons";
import vectorNewIcon from "../assests/Styles/assets/vectorNewIcon.svg";
import shapeNewIcon from "../assests/Styles/assets/ShapeNewIcon.svg";
import FeedbackForm from "./FeedbackForm";
import { useMediaQuery } from "react-responsive";


export default function MobileTopBar({
  protocolName,
  viewAPIData,
  cropData,
  selectedOption,
  handleTrialSummary,
  trail,
  isEditViewer = false,
  protocolTrials,
  collectionId,
  trialType,
  customMobilePortrait,
  customMobileLandscape,
  selectedFlightDate,
  accounts,
  instance,
  state,
  submitForm,
}) {
  const name = sessionStorage.getItem("userName");
  const pro = name?.split(".");
  const [profileClick, setProfileClick] = useState(false);
  const dropdownRef = useRef(null);
  const profileIconRef = useRef(null);
  const [infoIcon, setInfoIcon] = useState(false);
  let navigate = useNavigate();
  const infoIconRef = useRef(null);
  const infoIconContainerRef = useRef(null);
  const handleprofileIconClose = () => setProfileClick(false);
  const handleClose = () => setInfoIcon(false);
  const isMobile = useMediaQuery({ maxWidth: 576 });
 
  const routeChange = () => {
    let path = "/home/logout";
    navigate(path);
  };
  const handleTrials = (trialDetails) => {
    window.open(`/app/viewer?trial=${trialDetails?.trial_name}`, "_blank");
  };

  let isCheckSelectedOption =
    selectedOption !== "All trials" && selectedOption !== "No trials" ? selectedOption : " ";
  let isCheckTrial = trail !== "All trials" && trail !== "No trials" ? trail : " ";

  const truncateCombinedText = (text2, maxLength) => {
    const combinedText = ` ${text2}`.trim();
    if (combinedText.length > maxLength) {
      return combinedText.substring(0, maxLength);
    }
    return combinedText;
  };

  const siteName = viewAPIData?.site_name || "";
  const blockName = viewAPIData?.block_name || "";
  const truncatedText = truncateCombinedText(blockName, 28);
  const checkedTrialClass = customMobilePortrait ? "col-7" : "col-8 ";
  const prtocolAndCropData = cropData.length > 0 || protocolName;

  const [isShowFeedback, setIsShowFeedback] = useState(false);

  const handleOpenFeedback = () => {
    setIsShowFeedback(true);
  };

  const handleCloseFeedback = () => {
    setIsShowFeedback(false);
  };

  useEffect(() => {
    const handleClickOutsideInfoIcon = (event) => {
      if (
        infoIconRef.current &&
        !infoIconRef.current.contains(event.target) &&
        !infoIconContainerRef?.current?.contains(event.target)
      ) {
        setInfoIcon(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutsideInfoIcon);
    return () => {
      window.removeEventListener("mousedown", handleClickOutsideInfoIcon);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !profileIconRef?.current?.contains(event.target)
      ) {
        setProfileClick(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleButtonClick = () => {
    setProfileClick(!profileClick);
  };

  return (
    <div
      className="row"
      style={{
        flexWrap: "unset",
        height: "60px",
        backgroundColor: "#0D1A40",
        zIndex: "999",
        position: "fixed",
        left: 0,
        right: 0,
        top: 0
      }}>
      <div
        className={`${customMobilePortrait
          ? "col-2  d-flex align-items-center justify-content-center"
          : "col-1  d-flex align-items-center  pr-0"
          }`}>
        <div className="logo" id="viewer-link">
          <a className="logo" href="/app/" id="viewer-link">
            <div className="align-items-center" style={{ textAlign: "center" }}>
              <img
                src={customMobileLandscape ? mobileLogo : syngentaLogo}
                href="#"
                alt="syngenta"
                style={{
                  marginLeft: customMobilePortrait ? "20px" : "8px",
                  cursor: "pointer"
                }}
              />
            </div>
          </a>
        </div>
      </div>
      <div className={checkedTrialClass} style={{ marginLeft: customMobileLandscape && "8px" }}>
        <div>
          {!isEditViewer && (
            <div
              className="white-color font-weight-700 font-size-medium font-family-Helvetica"
              style={{
                marginTop: customMobilePortrait ? "0px" : "2px"
              }}>
              {isCheckSelectedOption ? isCheckSelectedOption : isCheckTrial}
            </div>
          )}
        </div>

        <div className="white-color font-weight-400 font-size-small font-family-Helvetica">
          {siteName}
        </div>
        <div className="white-color font-weight-400 font-size-small font-family-Helvetica">
          {truncatedText}
        </div>
      </div>

      <div className="col-1  d-flex align-items-center justify-content-center">
        <>
          {prtocolAndCropData ? (
            <>
              {selectedOption !== "All trials" && selectedOption !== "No trials" && (
                <div onClick={() => setInfoIcon(!infoIcon)} ref={infoIconRef}>
                  <InfoCircle height={24} width={24} color="white" />
                </div>
              )}
            </>
          ) : null}
        </>
      </div>
      <div className="col-2  d-flex align-items-center justify-content-center">
        <div
          style={{
            marginRight: customMobilePortrait ? "6px" : ""
          }}>
          <button
            className="ml-auto"
            style={{
              border: "none",
              backgroundColor: "#006D82",
              height: "24px",
              width: "24px",
              borderRadius: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontSize: "12px"
            }}
            ref={profileIconRef}
            onClick={handleButtonClick}>
            {pro && pro.length > 1
              ? pro[0].charAt(0).toUpperCase() + pro[1].charAt(0).toUpperCase()
              : pro[0].charAt(0).toUpperCase()}
          </button>
          {profileClick && (
            <div
              ref={dropdownRef}
              style={{
                position: "fixed",
                right: "0",
                top: "60px",
                backgroundColor: "#0D1A40",
                zIndex: "9999",
                minWidth: isMobile ? "101vw" : "388px"
              }}>
              <div style={{ position: "absolute", zIndex: 9, right: "8px", marginTop: "4px" }}>
                <X color="white" height={24} width={24} onClick={handleprofileIconClose} />
              </div>
              <ul className="list-group">
                <li style={{height:"85px"}} className="list-group-item">
                  <div style={{ display: "flex", gap: "10px",marginTop:"31px",alignItems:"center" }}>
                    <span>
                      <img
                        src={shapeNewIcon}
                        alt="shapeNewIcon"
                        height={24}
                        width={24}
                       
                      />
                    </span>
                    <span style={{
                      fontSize: ".875rem"
                    }}> {name}</span>
                  </div>
                </li>
                <li className="list-group-item">
                  <button
                    className="mobileTop-feedback"
                    onClick={handleOpenFeedback}
                  >
                    <div className="mobileTab-vector">
                      <span>
                        <img
                          src={vectorNewIcon}
                          alt="vectorNewIcon"
                          style={{ width: "20px", height: "20px", margin: "2px" }}
                        />
                      </span>
                      <span style={{ fontWeight: 400,fontSize:"14px"}}> Feedback</span>
                    </div>
                  </button>
                </li>
                <li
                  className="list-group-item"
                  style={{
                    borderBottom: "1px solid #343E5C",
                    backgroundColor: "#0D1A40",
                    paddingLeft: "33px"
                  }}>
                  <ToggleSwitch
                    trial={selectedOption}
                    collectionId={collectionId}
                    trialType={trialType}
                    customMobilePortrait={customMobilePortrait}
                    customMobileLandscape={customMobileLandscape}
                  />
                </li>
                <li
                  className="list-group-item"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#0D1A40"
                  }}>
                  <img
                    src={logout}
                    alt="logout"
                    style={{ width: "18px", height: "18px", margin: "3px", fontWeight: "700" }}
                  />
                  <button className="logout-Mobile" onClick={routeChange}>
                    <span style={{ fontWeight: "700" }}>&nbsp;LOGOUT</span>
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {infoIcon && (
        <div
          className="infoIconContainer"
          ref={infoIconContainerRef}
          // className="row"
          style={{
            position: "absolute",
            backgroundColor: "#0D1A40",
            top: "60px",
            left: customMobilePortrait && "15px",
            right: customMobilePortrait ? "0px" : "15px",
            width: customMobilePortrait ? "100%" : "430px",
            height: customMobilePortrait ? "auto" : "calc(100vh - 60px)",
            // maxHeight: customMobilePortrait ? "" : "-webkit-fill-available",
            overflow: "auto",
            paddingBottom: "10px",
          }}>
          <div>
            <div className="d-flex" style={{ padding: "15px", borderBottom: "1px solid #343E5C" }}>
              <div>
                <span className="white-color font-weight-700 font-size-small font-family-Helvetica">
                  CROPS
                </span>
                <span style={{ position: "absolute", right: customMobilePortrait ? "50px" : "20px", marginTop: "6px" }}>
                  <X color="white" height={24} width={24} onClick={handleClose} />
                </span>
                <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  {cropData.map((item) => (
                    <li
                      key={item}
                      className="white-color font-weight-400 font-size-small font-family-Helvetica">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div
              style={{ borderBottom: "1px solid #343E5C", padding: "15px" }}
              onClick={handleTrialSummary}>
              <span>
                <img src={mobileTrailSummaryIcon} />
              </span>
              <span
                style={{
                  color: "#E08330",
                  fontSize: "12px",
                  fontFamily: "Helvetica",
                  fontWeight: 400,
                  paddingLeft: "10px"
                }}>
                Trial Summary
              </span>
            </div>
            {trialType !== "manual" && (<><div style={{ borderBottom: "1px solid #343E5C", padding: "15px" }}>
              <div className="white-color font-weight-400 font-size-small font-family-Helvetica">
                {siteName}
              </div>
              <div className="white-color font-weight-400 font-size-small font-family-Helvetica">
                {blockName}
              </div>
            </div>
            </>)}
            <div className="d-flex" style={{ borderBottom: "1px solid #343E5C", padding: "15px" }}>
              {protocolName && (
                <div className="white-color font-weight-700 font-size-small font-family-Helvetica">
                  {protocolName}
                </div>
              )}
            </div>

            {location.pathname.indexOf("ploteditor") === -1 && (
              <div className="d-flex mt-2">
                <div
                  className="white-color font-weight-700 font-size-small font-family-Helvetica"
                  style={{
                    paddingLeft: "15px"
                  }}>
                  PROTOCOL TRIALS
                </div>
              </div>
            )}
            {protocolTrials && location.pathname.indexOf("ploteditor") === -1 && (
              <>
                {protocolTrials?.map((item) => (
                  <Button
                    key={item?.trial_name} // Add key prop
                    disabled={item?.trial_name === trail}
                    onClick={() => handleTrials(item)}
                    className="trialButtons"
                    style={{
                      backgroundColor: item?.trial_name === trail ? "#E08330" : "#3d4359",
                      opacity: item?.trial_name === trail && 1,
                      marginLeft: "15px"
                    }}>
                    {item?.trial_name}
                    {item?.has_drone_assessments ? (
                      <img
                        key={item?.trial_name} // Add key prop
                        src={item?.trial_name === trail ? whiteDrone : orangeDrone}
                        alt=""
                        style={{ marginLeft: "10px" }}
                      />
                    ) : null}
                  </Button>
                ))}
              </>
            )}
          </div>
        </div>
      )}
      <div>
        <FeedbackForm
          handleFeedbackClose={handleCloseFeedback}
          isShowFeedback={isShowFeedback}
          handleCloseFeedback={handleCloseFeedback}
          isDesktop={true}
          accounts={accounts}
          instance={instance}
          state={state}
          submitForm={submitForm}
          collectionId={collectionId}
          selectedTrail={selectedOption}
          selectedFlightDate={selectedFlightDate}
        />
      </div>
    </div>
  );
}

MobileTopBar.propTypes = {
  protocolName: propTypes.any,
  viewAPIData: propTypes.any,
  cropData: propTypes.any,
  pestData: propTypes.any,
  selectedOption: propTypes.any,
  trail: propTypes.any,
  selectedFlightDate: propTypes.any,
  isEditViewer: propTypes.any,
  protocolTrials: propTypes.any,
  collectionId: propTypes.any,
  trialType: propTypes.any,
  handleTrialSummary: propTypes.any,
  customMobilePortrait: propTypes.any,
  customMobileLandscape: propTypes.any,
  accounts: propTypes.any,
  instance: propTypes.any,
  state: propTypes.any,
  submitForm: propTypes.any,
};
